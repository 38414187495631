<template>
  <div style="width:100%;height:100%">
    <div v-if='(size === 1)'>
      <!-- 1920宽的尺寸 -->
      <div :style="{ width: screenWidth+ 'px' , height: screeHeight+ 'px' }" id=template>
      <!-- <div style="width:100%,height:100%"> -->
        <dataChild v-on:childData="childData" :mainName=mainName></dataChild>
        <img src="../../assets/404.jpg" alt="" style="width:100%;height:auto">
      </div>
    </div>
    <div v-if='(size === 0)' id=modD_1>
      <!-- rem的自适应尺寸 -->
      <!-- <div id=modD_1>{{this.forumData.name}}</div>
      <div id=modD_2>{{this.forumData.label}}</div>
      <div id=modD_3>{{this.forumData.title}}</div>
      <div id=modD_4>{{this.forumData.create_time}}</div>
      <div id=modD_5>{{this.forumData.Text}}</div>
      <div id=modD_6>{{this.forumData.picture}}</div>
      <div id=modD_7>{{this.forumData.file}}</div> -->
      <div id=modD_2></div>
      <div id=modD_3>
        <div id=modD_3_1></div>
        <div id=modD_3_2>
          <div id=modD_3_2_1></div>
          <div id=modD_3_2_2><img src="../../assets/个人中心素材/临时头像.png" alt=""></div>
          <div id=modD_3_2_3>
            <div id=modD_3_2_4>{{this.forumData.name}}</div>
            <div id=modD_3_2_5>{{this.forumData.create_time}}</div>
          </div>
        </div>
        <div id=modD_3_3>
          <div id=modD_3_3_1>
            <div id=modD_3_3_2>
              <div id=modD_3_3_4>{{this.forumData.title}}</div>
              <div id=modD_3_3_5>{{this.forumData.Text}}</div>
            </div>
            <div id=modD_3_3_7>
              <el-image v-for="i in this.srcList" :key="i"  :src="i"  :zoom-rate="1.2"  :preview-src-list="srcList"  :initial-index="0"  fit="cover"/>
            </div>
            <!-- <div class=modD_3_3_6></div> -->
            <div id=modD_3_3_8>
              <!-- <div>文件：</div> -->
              <a v-for="j in this.fileList" :key="j.ID" :href='j.url' target="view_window">{{j.name}}</a>
            </div>
            <div class=modD_3_3_9></div>
            <div id=modD_3_3_3>
              <img src="../../assets/论坛素材/评论按钮.jpg" alt="">
              <div>{{this.forumData.comment}}</div>
              <div style="width: 31px; float: left; height: 100%;"></div>
              <img src="../../assets/论坛素材/点赞按钮.jpg" alt="" @click="dz()">
              <div>{{this.forumData.thumbs_up}}</div>
            </div>
            <div class=modD_3_3_6></div>
          </div>
        </div>
        <div id=modD_3_4>
          <div>
            <el-input
              v-model="TextPL"
              type="text"
              resize="none"
            >
            </el-input>
            <div>
              <el-upload
                class="upload-demo"
                multiple
                action="1"
                :on-change="handleAvatarSuccess1"
                :http-request="httpRequest1"
                :show-file-list=false
              >
                <el-button type="primary"><img src="../../assets/个人中心素材/发帖图片按钮.jpg" alt=""></el-button>
              </el-upload>
              <el-upload
                class="upload-demo"
                multiple
                action="1"
                :on-change="handleAvatarSuccess2"
                :http-request="httpRequest2"
                :show-file-list=false
              >
                <el-button type="primary" v-if=false><img src="../../assets/个人中心素材/发帖附件按钮.jpg" alt=""></el-button>
              </el-upload>
              <el-button type="primary" @click="forum2()" id=modD_3_4_button>评论</el-button>
              <div v-if="(this.pictureDis !== '')" id=modD_3_4_Dis>已选择图片：{{this.pictureDis}}</div>
              <div v-if="(this.fileDis !== '')" id=modD_3_4_Dis>已选择文件：{{this.fileDis}}</div>
            </div>
          </div>
        </div>
        <div id=modD_3_5>
          <div v-for="k in this.comment" :key="k.ID" class=modD_3_5_1>
            <div id=modD_3_5_2><img src="../../assets/个人中心素材/临时头像.png" alt=""></div>
            <div id=modD_3_5_3>
              <div id=modD_3_5_4><b>{{k.name}}：</b>{{k.Text}}</div>
            </div>
            <div id=modD_3_5_6>
              <el-image v-for="i in k.picture" :key="i.id" :src="commentPictureUrl + i.name" :preview-src-list="k.list" :zoom-rate="1.2"  :initial-index="0"  fit="cover"/>
            </div>
            <div id=modD_3_5_5>{{k.create_time}}</div>
          </div>
        </div>
        <div id=modD_3_6></div>
      </div>
      <div id=modD_4></div>
    </div>
  </div>
</template>

<script>
// $emit----v-on子传父
// 非父子组件进行传值公共js
import DataChild from '../Mate/data.vue'
import Data from '../Mate/data.js'
export default {
  // 引用this.getAppUrl()-----切换内外网
  // 引用this.reload()-----刷新
  // window.location.reload() -------刷新整个页面
  inject: ['reload', 'getAppUrl'],
  // 页面标签设置(在模板里面修改文件名【package.json文件修改后重启可设置主标签】)
  metaInfo: {
    title: '佰茵论坛',
    titleTemplate: '佰茵云 - %s',
    htmlAttrs: {
      lang: 'ch',
      amp: true
    }
  },
  data() {
    return {
      screenWidth: document.body.clientWidth,
      screeHeight: document.body.clientHeight,
      mainName: 'Template',
      size: 0,
      forumID: '',
      forumData: {},
      srcList: [],
      pictureURL: '',
      Url: '',
      fileList: [],
      TextPL: '填写评论',
      imageUrl1: 0,
      fileUrl1: 0,
      // 列表
      pictures: [],
      files: [],
      // 已上传显示
      pictureDis: '',
      fileDis: '',
      // 预上传
      filePrepare: [],
      picturePrepare: [],
      // 评论信息
      comment: '',
      commentPictureUrl: ''
    }
  },
  created() {
    this.sizeData()
    this.getForum2()
    this.getComment2()
    setTimeout(async() => { 
      
    }, 1000)
  },
  mounted: function () {
    // mounted一直监控？可改位置----目前此方法还不能正确运用
    // 用$on事件来接收参数
    var that = this
    Data.$on('demo', (data) => {
      console.log(data)
    })
  },
  methods: {
    childData: function (data) {
      // 接收子组件的值
      // console.log(data)
    },
    demoData: function () {
      // 接收公共js的值
      Data.$emit('demo', this.demo)
    },
    sizeData() {
      // 默认设置
      this.size = 0
      // 大小设定
      // if (this.screenWidth >= 1920) this.size = 1
    },
    async getForum2() {
      this.getURL()
      this.forumID = this.$route.query.forumID
      var k = { forumID: this.forumID }
      const { data: res } = await this.$http.post('application/getForum2', k)
      // 异常处理
      if (res.code === '102') {
        window.close()
      } else {
        this.forumData = res.result[0]
        this.ini()
      }
    },
    async getComment2() {
      this.forumID = this.$route.query.forumID
      var k = { forumID: this.forumID }
      const { data: res } = await this.$http.post('application/getComment1', k)
      this.commentPictureUrl = this.Url + '/application/get_img/Activate/forum_img/' + this.forumData.username + '/'
      if (res.code === '200') {
        this.comment = res.result
        for (var i in this.comment) {
          if (this.comment[i].picture === '暂无') {
            this.comment[i].picture = []
          } else {
            this.comment[i].picture = JSON.parse(this.comment[i].picture)
          }
          if (this.comment[i].file === '暂无') {
            this.comment[i].file= []
          } else {
            this.comment[i].file = JSON.parse(this.comment[i].file)
          }
          var a = this.comment[i].picture
          this.comment[i].list = []
          for (var j in a) {
            this.comment[i].list[j] = this.Url + '/application/get_img/Activate/forum_img/' + this.forumData.username + '/' + a[j]['name']
          }
        }
      }
    },
    async ini() {
      if (this.forumData.picture === '暂无') {
        this.forumData.picture = JSON.stringify([])
      }
      if (this.forumData.file === '暂无') {
        this.forumData.file = JSON.stringify([])
      }
      var a = JSON.parse(this.forumData.picture)
      for (var i in a) {
        this.srcList[i] = this.Url + '/application/get_img/Activate/forum_img/' + this.forumData.username + '/' + a[i]['name']
      }
      var b = JSON.parse(this.forumData.file)
      for (var j in b) {
        var c = b[j]['name'].substring(0,15)
        this.fileList[j] = {'ID': j, 'name': b[j]['name'].split(c)[1], 'url': this.Url + '/application/DownloadFileA/' + b[j]['name'] + '/Server/Activate/forum_file/Alice'}
      }
    },
    async getURL() {
      this.Url = DataChild.httpUrl_3
      var c = localStorage.getItem('Https')
      if (c === 'No.1') {
        this.Url = DataChild.httpUrl_1
      } else if (c === 'No.2'){
        this.Url = DataChild.httpUrl_2
      } else if (c === 'No.3'){
        this.Url = DataChild.httpUrl_3
      } else if (c === 'No.4'){
        this.Url = DataChild.httpUrl_4
      } else if (c === 'No.5'){
        this.Url = DataChild.httpUrl_3
      } else {
        console.clear()
      }
      this.getUrl = this.Url
      // console.log(c, c === 'No.1', this.getUrl)
      return c
    },
    handleAvatarSuccess1(file, fileList) {
      const fileType = file.raw.type
      const isImage = fileType.indexOf("image") != -1
      if (!isImage) {
        this.$message.closeAll()
        this.$message.error("只能上传图片");
        return 0;
      }
      this.imageUrl1 = 1
    },
    handleAvatarSuccess2(file, fileList) {
      this.fileUrl1 = 1
    },
    httpRequest1(params) {
      if (this.imageUrl1 === 1) {
        const form = new FormData()
        form.append("file", params.file)
        this.picturePrepare[this.picturePrepare.length] = form
        this.imageUrl1 = 0
        this.pictures[this.pictures.length] = { 'id': this.pictures.length, 'name': params.file.name }
        this.pictureDis = ''
        for (var a in this.pictures) {
          this.pictureDis = this.pictureDis + this.pictures[a]['name'] + '\u3000'
        }
      }
    },
    httpRequest2(params) {
      if (this.fileUrl1 === 1) {
        const form = new FormData()
        form.append("file", params.file)
        this.filePrepare[this.filePrepare.length] = form
        this.fileUrl1 = 0
        this.files[this.files.length] = { 'id': this.files.length, 'name': params.file.name }
        this.fileDis = ''
        for (var a in this.files) {
          this.fileDis = this.fileDis + this.files[a]['name'] + '\u3000'
        }
      }
    },
    // 发表评论
    async forum2 () {
      var p1 = []
      var q1 = []
      for (var p in this.filePrepare) {
        const { data: res } = await this.$http.post(this.Url + '/application/upload5/' + localStorage.getItem('username'), this.filePrepare[p], { emulateJSON: true })
        // 文件
        if (res.code === 200) {
          p1[p1.length] = { 'id': p1.length, 'name': res.filename }
        }
      }
      for (var q in this.picturePrepare) {
        const { data: res } = await this.$http.post(this.Url + '/application/upload4/' + localStorage.getItem('username'), this.picturePrepare[q], { emulateJSON: true })
        // 图片
        if (res.code === 200) {
          q1[q1.length] = { 'id': q1.length, 'name': res.filename }
        }
      }
      var a = {'username': localStorage.getItem('username'), 'name': localStorage.getItem('name'), 'text': this.TextPL, 'picture': JSON.stringify(q1), 'file': JSON.stringify(p1), 'forum': this.$route.query.forumID }
      const { data: res } = await this.$http.post('application/forum2', a)
      if (res.code === '200') {
        this.$message.closeAll()
        this.$message.success("评论成功");
        this.getComment2()
        setTimeout(async() => {
          window.location.reload()
        }, 3000)
      }
    },
    async dz() {
      var a = {'username': localStorage.getItem('username'), 'name': localStorage.getItem('name'), 'forumID': this.$route.query.forumID }
      const { data: res } = await this.$http.post('application/thumbsUp', a)
      if (res.code === '200') {
        this.$message.success("点赞成功");
        this.reload()
      } else if (res.code === '102') {
        this.$message.success("已点赞");
      }
    },
  },
  props: {},
  components: {
    DataChild
  },
}
</script>

<style lang="less" scoped>
#modD_1 {
  background-color: #f5f6fb;
  width: 100%;
  min-height: 100%;
}
#modD_2 {
  width: 100%;
  height: 60px;
}
#modD_4 {
  width: 100%;
  height: 74px;
}
#modD_3 {
  background-color: #fff;
  width: 780px;
  margin: auto;
  min-height: 300px;
  #modD_3_1 {
    height: 31px;
    width: 100%;
  }
  #modD_3_2 {
    height: 62px;
    width: 100%;
    #modD_3_2_1, #modD_3_2_2, #modD_3_2_3 {
      float: left;
      height: 100%;
    }
    #modD_3_2_1 {
      width: 30px;
    }
    #modD_3_2_2 {
      width: 62px;
      > img {
        width: 100%;
        height: 100%;
        border-radius: 30px;
        border: 1px solid #ccc;
      }
    }
    #modD_3_2_3 {
      margin-left: 21px;
      min-width: 100px;
    }
    #modD_3_2_4 {
      height: 31px;
      line-height: 31px;
      font-size: 18px;
      font-weight: 600;
    }
    #modD_3_2_5 {
      height: 31px;
      line-height: 31px;
      font-size: 14px;
      color: #ccc;
    }
  }
  #modD_3_3 {
    width: 100%;
    height: auto;
    #modD_3_3_1 {
      margin-left: 113px;
      width: 632px;
      min-height: 500px;
    }
    #modD_3_3_2 {
      min-height: 500px;
    }
    #modD_3_3_3 {
      height: 16px;
      font-size: 16px;
      line-height: 16px;
      text-align: left;
      > img {
        width: 16px;
        height: 16px;
        margin: 0 8px;
        float: left;
      }
      > div {
        font-size: 8px;
        width: 32px;
        height: 16px;
        margin: 0 8px;
        float: left;
      }
    }
    #modD_3_3_4 {
      margin-top: 30px;
      padding-bottom: 20px;
      min-height: 35px;
      line-height: 35px;
      font-size: 25px;
      border-bottom: 1px solid #f2f2f2;
      white-space: pre-wrap;
    }
    #modD_3_3_5 {
      margin-top: 30px;
      font-size: 16px;
      line-height: 20px;
      white-space: pre-wrap;
    }
    .modD_3_3_6 {
      height: 30px;
    }
    .modD_3_3_9 {
      height: 50px;
    }
    #modD_3_3_7 {
      margin-top: 10px;
      > div {
        font-size: 24px;
      }
      .el-image {
        width: 200px;
        height: 200px;
        margin: 10px 10px 0 0;
      }
    }
    #modD_3_3_8 {
      min-height: 20px;
      > div {
        font-size: 24px;
      }
      > a {
        height: 20px;
        line-height: 20px;
        font-size: 16px;
        margin-right: 20px;
        word-break:break-all
      }
    }
  }
  #modD_3_4 {
    border-top: 1px solid #f2f2f2;
    min-height: 108px;
    > div {
      margin-left: 113px;
      width: 632px;
      > div {
        height: 48px;
      }
    }
    .el-button {
      background-color: rgba(0,0,0,0);
      border: 0;
    }
    .upload-demo {
      width: 24px;
      height: 24px;
      margin: 12px 12px 0px 0px;
      float: left;
    }
    /deep/ .el-upload {
      width: 24px;
      height: 24px;
      display: block;
    }
    .el-button {
      width: 24px;
      height: 24px;
      padding: 0;
      float: left;
    }
    /deep/ .el-input__inner {
      font-size: 16px;
      color: #b3b3b3;
    }
    #modD_3_4_button {
      color: #fff;
      background-color: #2fa8e1;
      width: 110px;
      height: 40px;
      float: right;
      margin-top: 8px;
    }
    #modD_3_4_Dis {
      float: left;
      font-size: 18px;
    }
  }
  #modD_3_5 {
    margin-top: 44px;
    .modD_3_5_1 {
      margin-left: 30px;
      width: calc(100% - 30px);
      height: 150px;
    }
    #modD_3_5_2 {
      height: 42px;
      width: 62px;
      float: left;
      > img {
        height: 62px;
        width: 100%;
        border-radius: 30px;
        border: 1px solid #ccc;
      }
    }
    #modD_3_5_3 {
      float: left;
      font-size: 16px;
      margin-left: 21px;
      margin-top: 12px;
      font-weight: 600;
      width: calc(100% - 83px);
    }
    #modD_3_5_4 {
      margin-top: 12px;
      float: left;
      font-size: 16px;
      font-weight: 600;
      white-space: pre-wrap;
      word-wrap: break-word;
	    word-break: normal;
      width: calc(100% - 83px);
      min-height: 32px;
      line-height: 16px;
      >b {
        color: #2fa8e1;
        float: left;
      }
    }
    #modD_3_5_5 {
      float: left;
      font-size: 14px;
      margin-left: 83px;
      width: calc(100% - 83px);
      color: #b2b2b2;
    }
    #modD_3_5_6 {
      margin-left: 83px;
      width: calc(100% - 83px);
      // height: 45px;
      float: left;
      .el-image {
        width: 40px;
        height: 40px;
        margin: 5px 5px 0 0;
      }
    }
  }
  #modD_3_6 {
    height: 120px;
  }
}
</style>
