<template>
  <div style="width:100%;height:100%">
    <div v-if='(size === 1)'>
      <!-- 1920宽的尺寸 -->
      <div :style="{ width: screenWidth+ 'px' , height: screeHeight+ 'px' }" id=template>
      <!-- <div style="width:100%,height:100%"> -->
        <dataChild v-on:childData="childData" :mainName=mainName></dataChild>
        <img src="../../assets/404.jpg" alt="" style="width:100%;height:auto">
      </div>
    </div>
    <div v-if='(size === 0)' style="width:100%;height:100%;">
      <!-- rem的自适应尺寸 -->
      <!-- 个人中心
      {{this.userInfor.username}}
      {{this.userInfor.name}}
      {{this.userInfor.phone}}
      {{this.userInfor.email}} -->
      <div id="modA">
        <div id="modA_1"></div>
        <div id="modA_2">
          <div  id="modA_2_1"></div>
          <div  id="modA_2_2">
            <img src="../../assets/404.jpg" alt="">
          </div>
          <!-- <div id="modA_2_4">点击认证</div> -->
          <div id="modA_2_4"></div>
          <el-form class="modA_2_3_2">
            <el-form-item prop="username" class="modA_2_3_1">
              <div class="modA_2_3_3">账号：</div>
              <el-input v-model="userForm.username" class="modA_2_3_4" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item prop="name" class="modA_2_3_1">
              <div class="modA_2_3_3">名称：</div>
              <el-input v-model="userForm.name" class="modA_2_3_4" :disabled="this.buttonState.infoDisabled"></el-input>
            </el-form-item>
            <el-form-item prop="username" class="modA_2_3_1">
              <div class="modA_2_3_3">密码：</div>
              <el-input v-model="userForm.password" class="modA_2_3_4" :disabled="this.buttonState.passwordDisabled"></el-input>
            </el-form-item>
            <el-form-item prop="username" class="modA_2_3_1">
              <div class="modA_2_3_3">邮箱：</div>
              <el-input v-model="userForm.email" class="modA_2_3_4" :disabled="this.buttonState.infoDisabled"></el-input>
            </el-form-item>
            <el-form-item prop="username" class="modA_2_3_1">
              <div class="modA_2_3_3">电话：</div>
              <el-input v-model="userForm.phone" class="modA_2_3_4" :disabled="this.buttonState.infoDisabled"></el-input>
            </el-form-item>
          </el-form>
          <div id="modA_2_6"></div>
          <div id="modA_2_5">
            <el-button type="primary" v-if="(this.buttonState.info === 0)" @click="submit(1)">修改信息</el-button>
            <el-button type="primary" v-if="(this.buttonState.info === 1)" @click="submit(2)">提交</el-button>
            <el-button type="success" v-if="(this.buttonState.password === 0)" @click="submit(3)">修改密码</el-button>
            <el-button type="success" v-if="(this.buttonState.password === 1)" @click="submit(4)">提交</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// $emit----v-on子传父
// 非父子组件进行传值公共js
import DataChild from '../Mate/data.vue'
import Data from '../Mate/data.js'
export default {
  // 引用this.getAppUrl()-----切换内外网
  // 引用this.reload()-----刷新
  // window.location.reload() -------刷新整个页面
  inject: ['reload', 'getAppUrl'],
  // 页面标签设置(在模板里面修改文件名【package.json文件修改后重启可设置主标签】)
  metaInfo: {
    title: '个人中心',
    titleTemplate: 'BioinforCloud - %s',
    htmlAttrs: {
      lang: 'ch',
      amp: true
    }
  },
  data() {
    return {
      screenWidth: document.body.clientWidth,
      screeHeight: document.body.clientHeight,
      mainName: 'Template',
      size: 0,
      userInfor: { 'username': '', 'name': '', 'phone': '', 'email': '' },
      userForm: { name: '', username: '', password: '', email: '', phone: '' },
      buttonState: { 'password': 0, 'info': 0 , 'infoDisabled': true, 'passwordDisabled': true},
    }
  },
  created() {
    this.sizeData()
    setTimeout(async() => { 
      this.personalInfor()
    }, 1000)
  },
  mounted: function () {
    // mounted一直监控？可改位置----目前此方法还不能正确运用
    // 用$on事件来接收参数
    var that = this
    Data.$on('demo', (data) => {
      console.log(data)
    })
  },
  methods: {
    childData: function (data) {
      // 接收子组件的值
      // console.log(data)
    },
    demoData: function () {
      // 接收公共js的值
      Data.$emit('demo', this.demo)
    },
    sizeData() {
      // 默认设置
      this.size = 0
      // 大小设定
      // if (this.screenWidth >= 1920) this.size = 1
    },
    // 获取个人信息
    async personalInfor() {
      var userList = JSON.parse(localStorage.getItem('userInfor'))
      if (userList === null) {
        userList = {'code': 101}
      }
      if (userList.code === 200) {
        this.userInfor = userList
      } else {
        var k = { 'username': localStorage.getItem('username') }
        const { data: res } = await this.$http.post('user/personalInfor', k)
        if (res.code === 200) {
          this.userInfor = res
          localStorage.setItem('userInfor', JSON.stringify(this.userInfor))
        }
      }
      this.userForm.name = this.userInfor.name
      this.userForm.username = this.userInfor.username
      this.userForm.email = this.userInfor.email
      this.userForm.phone = this.userInfor.phone
      this.userForm.password = '********'
    },
    submit(data) {
      var k = ''
      if (data === 1){
        this.buttonState = { 'password': 0, 'info': 1 , 'infoDisabled': false, 'passwordDisabled': true}
      } else if (data === 2){
        // 个人信息更改提交
        this.$confirm('确定修改?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.buttonState = { 'password': 0, 'info': 0 , 'infoDisabled': true, 'passwordDisabled': true}
          this.$message.closeAll()
          const set = this.$message({
            type: 'success',
            message: '正在提交请稍后!',
            duration: 0,
          });
          k = { name: this.userForm.name, username: this.userForm.username, password: '未修改', email: this.userForm.email, phone: this.userForm.phone }
          const { data: res } = await this.$http.post('user/userInfo', k)
          if (res.code === '200') {
            this.$message.closeAll()
            const set = this.$message({
              type: 'success',
              message: '修改完成',
              duration: 5000,
            });
            localStorage.setItem('userInfor', JSON.stringify({'code': 101}))
            localStorage.setItem('name', this.userForm.name)
            setTimeout(async() => { 
              window.location.reload()
            }, 1000)
          }
        }).catch(() => {
          return this.$message.closeAll()
        });
      } else if (data === 3){
        this.buttonState = { 'password': 1, 'info': 0 , 'infoDisabled': false, 'passwordDisabled': false}
      } else if (data === 4){
        // 密码提交
        if (this.userForm.password === '********') {
          return this.$message({
            type: 'success',
            message: '密码未修改'
          });
        }
        this.$confirm('确定修改?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.buttonState = { 'password': 0, 'info': 0 , 'infoDisabled': true, 'passwordDisabled': true}
          this.$message.closeAll()
          const set = this.$message({
            type: 'success',
            message: '正在提交请稍后!',
            duration: 0,
          });
          k = this.userForm
          const { data: res } = await this.$http.post('user/userInfo', k)
          if (res.code === '200') {
            this.$message.closeAll()
            localStorage.clear()
            this.$router.push({ path: '/BioinforCloud' })
            const set = this.$message({
              type: 'success',
              message: '修改完成请重新登录',
              duration: 5000,
            });
            setTimeout(async() => { 
              window.location.reload()
            }, 2000)
          }
        }).catch(() => {
          return this.$message.closeAll()
        });
      }
    }
  },
  props: {},
  components: {
    DataChild
  },
}
</script>

<style lang="less" scoped>
#modA {
  height: 100%;
  width: 100%;
  #modA_1{
    height: calc(50% - 250px);
  }
  #modA_2{
    margin: auto;
    background-color: #fff;
    width: 1000px;
    height: 500px;
    #modA_2_1{
      height: 43px;
    }
    #modA_2_2{
      margin: auto;
      height: 158px;
      > img {
        height: 158px;
        width: 158px;
        border-radius: 79px;
        border: 1px solid #000;
      }
    }
    .modA_2_3_4{
      float: left;
      font-size: 16px;
    }
  }
  #modA_2_4{
    margin: 16px auto 42px auto;
    font-size: 16px;
    color: #2fa8e1;
  }
  .modA_2_3_1{
    height: 35px;
    width: 255px;
    float: left;
    margin-right: 25px;
    margin-bottom: 35px;
  }
  .modA_2_3_2{
    margin-left: 90px;
    height: calc((35px + 35px) * 2);
  }
  .modA_2_3_3{
    float: left;
    font-size: 16px;
  }
  .el-input {
    float: left;
    height: 100%;
    width: calc(100% - 48px);
  }
  .el-input__inner {
    height: 100%;
    width: calc(100% - 48px);
  }
  #modA_2_5{
    margin: auto;
    .el-button{
      width: 138px;
      height: 49px;
      margin: 0 9.5px;
    }
  }
  #modA_2_6{
    height: 7px;
  }
  /deep/ .el-input.is-disabled .el-input__inner {
    color: #aaa;
    background-color: rgba(200, 200, 200, 0.1);
  }
}
</style>
