import { render, staticRenderFns } from "./个人文件夹.vue?vue&type=template&id=34cc23b7&scoped=true&"
import script from "./个人文件夹.vue?vue&type=script&lang=js&"
export * from "./个人文件夹.vue?vue&type=script&lang=js&"
import style0 from "./个人文件夹.vue?vue&type=style&index=0&id=34cc23b7&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34cc23b7",
  null
  
)

export default component.exports