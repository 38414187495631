<template>
  <div style="width:100%;height:100%">
    <div v-if='(size === 1)'>
      <!-- 1920宽的尺寸 -->
      <div :style="{ width: screenWidth+ 'px' , height: screeHeight+ 'px' }" id=template>
      <!-- <div style="width:100%,height:100%"> -->
        <dataChild v-on:childData="childData" :mainName=mainName></dataChild>
        <img src="../../assets/404.jpg" alt="" style="width:100%;height:auto">
      </div>
    </div>
    <div v-if='(size === 0)' style="width:100%;height:100%">
      <!-- 头部信息 -->
      <div id=mod1>
        <img src="../../assets/logo.jpg" alt />
        <el-menu el-menuid="right_1" mode="horizontal" :router=false @select="handleSelect">
          <el-menu-item index="/BioinforCloud">首页</el-menu-item>
          <el-menu-item>应用广场</el-menu-item>
          <el-menu-item>生信流程</el-menu-item>
          <el-menu-item>视频课程</el-menu-item>
          <el-menu-item>学术成果</el-menu-item>
          <el-menu-item>佰茵笔记</el-menu-item>
          <el-menu-item>佰茵论坛</el-menu-item>
          <el-menu-item>联系我们</el-menu-item>
        </el-menu>
        <div id=mod1_1>
          <div>你好！{{this.username}}</div>
          <img src="../../assets/404.jpg" alt="">
        </div>
      </div>
      <!-- 下方栏 -->
      <div id="mod2">
        123
      </div>
    </div>
  </div>
</template>

<script>
// $emit----v-on子传父
// 非父子组件进行传值公共js
import DataChild from '../Mate/data.vue'
import Data from '../Mate/data.js'
export default {
  // 引用this.getAppUrl()-----切换内外网
  // 引用this.reload()-----刷新
  // window.location.reload() -------刷新整个页面
  inject: ['reload', 'getAppUrl'],
  // 页面标签设置(在模板里面修改文件名【package.json文件修改后重启可设置主标签】)
  metaInfo: {
    title: '主页',
    titleTemplate: 'BioinforCloud - %s',
    htmlAttrs: {
      lang: 'ch',
      amp: true
    }
  },
  data() {
    return {
      screenWidth: document.body.clientWidth,
      screeHeight: document.body.clientHeight,
      mainName: 'Home',
      username: '',
    }
  },
  created() {
    this.sizeData()
    this.ini1()
    setTimeout(async() => { 
    }, 1000)
  },
  mounted: function () {
    // mounted一直监控？可改位置----目前此方法还不能正确运用
    // 用$on事件来接收参数
    var that = this
    Data.$on('demo', (data) => {
      console.log(data)
    })
  },
  methods: {
    childData: function (data) {
      // 接收子组件的值
      // console.log(data)
    },
    demoData: function () {
      // 接收公共js的值
      Data.$emit('demo', this.demo)
    },
    sizeData() {
      // 默认设置
      this.size = 0
      // 大小设定
      // if (this.screenWidth >= 1920) this.size = 1
    },
    // 不需要延迟的方法
    ini1() {
      this.username = localStorage.getItem('name')
    },
    handleSelect(key, keyPath) {
      if (key === null){
        return this.$router.push({ path: '/BioinforCloud' }).catch(error => error)
      } else if (key === '/BioinforCloud') {
        return this.$router.push({ path: key }).catch(error => error)
      }
    },
  },
  props: {},
  components: {
    DataChild,
  },
}
</script>

<style lang="less" scoped>
#mod1 {
  font-size: 32px;
  height: 80px;
  line-height: 50px;
  width: 100%;
  background-color: #2fa8e1;
  > img {
    float: left;
    width: 180px;
  }
  #mod1_1 {
    font-size: 18px;
    height: 80px;
    line-height: 80px;
    color: #fff;
    width: 250px;
    float: right;
    > div {
      position: absolute;
      right: 80px;
      top: 0px;
    }
    > img {
      position: absolute;
      right: 0px;
      top: 0px;
      height: 40px;
      width: 40px;
      border-radius: 20px;
      margin: 20px;
      float: left;
    }
  }
  .el-menu {
    background-color: rgba(0,0,0,0);
    border: 0;
    height: 80px;
    margin: 0 0 0 calc((100% - 110px * 8 - 250px - 180px)/2);
  }
  .el-menu-item {
    background-color: rgba(0,0,0,0);
    color: #fff;
    height: 80px;
    line-height: 80px;
    font-size: 18px;
    width: 110px;
    padding: 0;
    text-align: center;
  }
  .el-menu--horizontal>.el-menu-item {
    border: 0;
  }
  > ul {
    width: max-content;
    // width: calc(100% - 250px - 180px);
    float: left;
  }
}
#mod2{
  font-size: 16px;
  text-align: center;
  height: calc(100% - 80px);
  width: 100%;
  float: left;
  background-color: #eee;
}
</style>
