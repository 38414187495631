<template>
  <!-- <div :style="{ width: screenWidth+ 'px' , height: screeHeight+ 'px' }" id=AppSquare> -->
  <el-scrollbar style="width:100%;height:100%" id=AppSquare>
  </el-scrollbar>
</template>

<script>
// $emit----v-on子传父
// 非父子组件进行传值公共js
import DataChild from '../Mate/data.vue'
import Data from '../Mate/data.js'
export default {
  inject: ['reload'],
  // 引用this.reload()-----刷新
  data() {
    return {
      screenWidth: 0,
      screeHeight: 0,
    }
  },
  created() {
    // this.ini()
  },
  mounted: function () {
    // mounted一直监控？可改位置----目前此方法还不能正确运用
    // 用$on事件来接收参数
    var that = this
    Data.$on('demo', (data) => {
      console.log(data)
    }) 
  },
  methods: {
    // 接收子组件的值
    childData: function (data) {
    // console.log(data)
    },
    // 接收公共js的值
    demoData: function () {
      Data.$emit('demo', this.demo)
    },
    async ini () {
      // 获取屏幕宽高，浏览器还有电脑系统信息
      this.screenWidth = document.body.clientWidth,
      this.screeHeight = document.body.clientHeight,
      this.userAgent = navigator.userAgent
      // 火狐浏览器的宽度修改[浏览器单独修改]
      // console.log(this.screenWidth)
      // if (this.userAgent.indexOf("Firefox") !== -1) {
      //   this.screenWidth = this.screenWidth
      // }
      var k = { username: localStorage.getItem('username'), token: localStorage.getItem('token') }
      const { data: res } = await this.$http.post('application/published_tools', k, { emulateJSON: true })
      if (res.code === 'tokenError') {
        localStorage.removeItem('username');
        localStorage.removeItem('name');
        localStorage.removeItem('token');
        // localStorage.clear()
        this.$message({
          type: 'error',
          message: '登录已失效请重新登录。'
        });
        this.$router.push({ path: '/Home' })
        return '登录已失效请重新登录。'
      }
      var a = 0
      var b = []
      this.Url = DataChild.httpUrl_2
      this.getURL()
      this.AppData = res.tool_lists
      for(var i in res.tool_lists) {
        // console.log(res.tool_lists[i]['cnName'], res.tool_lists[i]['cnName'].indexOf(this.rule.keyword))
        if (this.rule.type === '全部' && this.rule.keyword === '') {
          b[a] = res.tool_lists[i]
          a = a + 1
          continue
        }
      }
      this.test = b
      // console.log(this.test)
    },
    async getURL() {
      var a = localStorage.getItem('Https')
      // console.log(a, a === 'N', a === null)
      if (a === 'N') {
        this.getUrl = DataChild.httpUrl_1
        this.Url = DataChild.httpUrl_1
      } else {
        console.clear()
      }
      return a
    },
    async openSquare (data) {
      var k = { username: localStorage.getItem('username'), tool_id: data, token: localStorage.getItem('token') }
      const set = this.$message({
        message: '正在创建请稍等',
        type: 'success',
        duration: 0,
      });
      const { data: res } = await this.$http.post('application/setRunSpace', k, { emulateJSON: true })
      set.close()
      if (res.code === 'tokenError') {
        localStorage.removeItem('username');
        localStorage.removeItem('token');
        // localStorage.clear()
        this.$message({
          type: 'error',
          message: '登录已失效请重新登录。'
        });
        this.$router.push({ path: '/Home' })
        return '登录已失效请重新登录。'
      }
      if (res.code === 200 || res.msg === '创建成功') {
        const a = this.$router.resolve({ path: '/appRun', query: { dir: res.dirname } })
        window.open(a.href,'_blank')
      } else if (res.code === 400) {
        this.$message.error('创建失败请重新尝试')
        this.reload()
      }
    },
    async runSearch() {
      if (this.value === '') {
        this.$message.error('请选择要搜索的项目')
        return 0
      }
      this.rule =  { type: this.value, keyword: this.searchText }
      var k = { username: localStorage.getItem('username'), token: localStorage.getItem('token') }
      const { data: res } = await this.$http.post('application/published_tools', k, { emulateJSON: true })
      var a = 0
      var b = []
      for(var i in res.tool_lists) {
        // console.log(res.tool_lists[i])
        if (this.rule.type === '全部') {
          if (res.tool_lists[i]['cnName'].indexOf(this.rule.keyword) !== -1) {
            b[a] = res.tool_lists[i]
            a = a + 1
            continue
          } else if (res.tool_lists[i]['enName'].indexOf(this.rule.keyword) !== -1) {
            b[a] = res.tool_lists[i]
            a = a + 1
            continue
          } else if (res.tool_lists[i]['introduce'].indexOf(this.rule.keyword) !== -1) {
            b[a] = res.tool_lists[i]
            a = a + 1
            continue
          }
        } else if ((this.rule.type === '应用名') && (res.tool_lists[i]['cnName'].indexOf(this.rule.keyword) !== -1) ) {
          b[a] = res.tool_lists[i]
          a = a + 1
          continue
        } else if ((this.rule.type === '应用环境') && (res.tool_lists[i]['enName'].indexOf(this.rule.keyword) !== -1) ) {
          b[a] = res.tool_lists[i]
          a = a + 1
          continue
        } else if ((this.rule.type === '应用说明') && (res.tool_lists[i]['introduce'].indexOf(this.rule.keyword) !== -1) ) {
          b[a] = res.tool_lists[i]
          a = a + 1
          continue
        }
      }
      this.test = b
      // console.log(this.test)
    },
    clearDir(data) {
      var k = { username: localStorage.getItem('username'), token: localStorage.getItem('token'), data: data }
      this.$confirm('该操作不可还原, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const set = this.$message({
          type: 'success',
          message: '正在清理请稍后!',
          duration: 0,
        });
        // console.log(k)
        const { data: res } = await this.$http.post('application/clearDir', k, { emulateJSON: true })
        // console.log(res)
        if (res.code === 200) {
          set.close()
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }
      }).catch(() => {
        // k = { name: '4124', code: '1' }
        // this.$http.post('user/examine', k, { emulateJSON: true })
        this.$message({
          type: 'info',
          message: '已取消操作'
        })
      });
    }
  },
  props: {},
  components: {
    DataChild
  },
}
</script>

<style lang="less" scoped>
// 滑动条属性
#AppSquare {
  /deep/ .el-scrollbar{
    height: 100%;
  }
  /deep/ .el-scrollbar__bar{
    right: 0rem;
    z-index: 99;
  }
  /deep/ .el-scrollbar__wrap{
    overflow: scroll;
    overflow-x:auto;
    overflow-y:auto;
    position: relative;
    z-index: 25;
  }
  /deep/ .el-scrollbar__bar.is-vertical {
    width: 0.5rem;
    border-radius: 0.25rem;
  }
  /deep/ .el-scrollbar__thumb{
    background-color: rgba(0, 0, 0, 0.15);
  }
  /deep/ .el-scrollbar__thumb:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
#AppSquare {
  height: 100%;
  width: 100%;
  #mod1 {
    width:100%;
    height:100%;
    opacity: 0.25;
    position: absolute;
    z-index: 0;
  }
  #mod2 {
    position: relative;
    box-sizing: border-box;
    border-radius: 0.5rem;
    z-index: 10;
    width: 20%;
    height: 3rem;
    background-color: #fff;
    margin-bottom: 1rem;
    float:left;
  }
  /deep/ .el-card__body {
    padding: 0;
    height: 100%;
    width: 100%;
  }
  .el-card.is-hover-shadow:focus, .el-card.is-hover-shadow:hover {
    box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.2);
  }
  #mod2_1 {
    width: 100%;
    height: 100%;
    font-size: 0.45rem;
    > img {
      width: 100%;
      height: 56.25%;
      border-radius: 0.5rem 0.5rem 0 0;
    }
    > div {
      height: 1rem;
      line-height: 1rem;
      margin-left: 0.2rem;
    }
  }
  #mod_search {
    .el-select {
      float: left;
    }
    /deep/.el-select .el-input {
      width: 5rem;
      font-size: 0.5rem;
      .el-input__inner {
        height: 1rem;
      }
    }
    .el-input {
      float: left;
      margin: 0 0.5rem;
      width: 50%;
      font-size: 0.5rem;
      /deep/ .el-input__inner {
        height: 1rem;
      }
    }
    /deep/ .el-input__icon {
      line-height: 1rem;
      font-size: 0.5rem;
    }
    #mod_search_3 {
      float: left;
      width: 2rem;
      font-size: 0.5rem;
      height: 1rem;
      line-height: 1rem;
    }
    #mod_search_4 {
      float: right;
      width: 4rem;
      font-size: 0.5rem;
      height: 1rem;
      line-height: 1rem;
    }
    /deep/ .el-button {
      position: relative;
      padding: 0;
      z-index: 20;
    }
  }
}
</style>
