import Vue from 'vue'
import ElementUI from 'element-ui';

Vue.use(ElementUI);


// import {
//     Button,
//     // Carousel,
//     // CarouselItem,
//     Input,
//     Menu,
//     MenuItem,
//     Form,
//     FormItem,
//     Message,
//     MessageBox,
//     Scrollbar,
//     Collapse,
//     CollapseItem,
//     Radio,
//     RadioGroup,
//     RadioButton,
//     Checkbox,
//     CheckboxGroup,
//     // CheckboxButton,
//     Upload,
//     Col,
//     Card,
//     Tree,
//     // Submenu,
//     Select,
//     Option,
//     Pagination,
// } from 'element-ui'

// Vue.use(Button)
// // Vue.use(Carousel)
// // Vue.use(CarouselItem)
// Vue.use(Input)
// Vue.use(Menu)
// Vue.use(MenuItem)
// Vue.use(Form)
// Vue.use(FormItem)
// Vue.use(Scrollbar)
// Vue.use(Collapse)
// Vue.use(CollapseItem)
// Vue.use(Radio)
// Vue.use(RadioGroup)
// Vue.use(RadioButton)
// Vue.use(Checkbox)
// Vue.use(CheckboxGroup)
// // Vue.use(CheckboxButton)
// Vue.use(Upload)
// Vue.use(Col)
// Vue.use(Card)
// Vue.use(Tree)
// // Vue.use(Submenu)
// Vue.use(Select)
// Vue.use(Option)
// Vue.prototype.$message = Message
// Vue.prototype.$prompt = MessageBox.prompt
// Vue.prototype.$confirm = MessageBox.confirm
// Vue.use(Pagination)


