<template>
  <!-- <div :style="{ width: screenWidth+ 'px' , height: screeHeight+ 'px' }" id=devMain> -->
  <div style="width:100%,height:100%" id=devMain>
    <DataChild v-on:childData="childData" :mainName=mainName></DataChild>
    <div id=mod5 v-if="(this.addDisplay === 1)">
      <div id=mod5_1>
        <div style="height:0.5rem"></div>
        <div id=mod5_2>添加应用</div>
        <div id=mod5_3>
          <div class=mod5_4>
            <span>应用中文名：</span>
            <el-input v-model="upDemo.cnName" placeholder="中文名为应用显示的名称"></el-input>
          </div>
          <div class=mod5_4>
            <span>应用英文名：</span>
            <el-input v-model="upDemo.enName" placeholder="英文名与运行环境挂钩"></el-input>
          </div>
          <div class=mod5_4>
            <span>复制初始环境：</span>
            <el-input v-model="upDemo.copy" placeholder="不需要复制环境请留空"></el-input>
          </div>
          <div class=mod5_4 style="height:2.5rem">
            <span>应用简介：</span>
            <el-input
              type="textarea"
              placeholder="请输入应用简介"
              v-model="upDemo.introduce">
            </el-input>
          </div>
          <div class=mod5_4>
            <span>使用语言：</span>
            <el-radio-group v-model="upDemo.file_type">
            <!-- <el-radio-group v-model="test" size="small"> -->
              <el-radio-button label="R3.6"></el-radio-button>
              <el-radio-button label="R4.2"></el-radio-button>
              <el-radio-button label="python" style="width:2.5rem"></el-radio-button>
              <el-radio-button label="Bash"></el-radio-button>
            </el-radio-group>
          </div>
          <div class=mod5_4 id=mod5_6>
            <span>展示页图片：</span>
            <el-upload
              class="avatar-uploader"
              action="1"
              :multiple="false"
              :auto-upload="false"
              :show-file-list="false"
              :on-change="handleAvatarSuccess"
              ref="upload1"
              :http-request="httpRequest">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </div>
        <div id=mod5_5 v-if="(addgaddisplay === 1)">
          <el-button @click="addgad1()">创建</el-button>
          <el-button @click="addgad2()">取消</el-button>
        </div>
        <div id=mod5_7 v-if="(addgaddisplay === 2)">
          应用正在创建，请不要关闭窗口
        </div>
      </div>
    </div>
    <div id=mod6 v-if="(this.addDisplay === 2)">
      <div id=mod6_1>
        <div id=mod6_2>
          <div style="height:0.5rem"></div>
          <div id=mod6_6>修改应用信息</div>
          <div class=mod6_4>
            <span>应用中文名：</span>
            <el-input v-model="upDemo.cnName"></el-input>
          </div>
          <div class=mod6_4>
            <span>应用英文名：</span>
            <el-input v-model="upDemo.enName"></el-input>
          </div>
          <div class=mod6_4 style="height:2.5rem">
            <span>应用简介：</span>
            <el-input
              v-model="upDemo.introduce"
              type="textarea"
              placeholder="请输入应用简介">
            </el-input>
          </div>
          <div class=mod6_4>
            <span>使用语言：</span>
            <el-radio-group v-model="upDemo.file_type">
              <el-radio-button label="R3.6"></el-radio-button>
              <el-radio-button label="R4.2"></el-radio-button>
              <el-radio-button label="python" style="width:2.5rem"></el-radio-button>
              <el-radio-button label="Bash"></el-radio-button>
            </el-radio-group>
          </div>
          <div class=mod6_4 id=mod6_5>
            <span>展示页图片：</span>
            <el-upload
              class="avatar-uploader"
              action="1"
              :multiple="false"
              :auto-upload="false"
              :show-file-list="false"
              :on-change="handleAvatarSuccess"
              ref="upload3"
              :http-request="httpRequest">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </div>
        <div id=mod6_3>
          <el-button @click="replace()">提交</el-button>
          <el-button @click="addgad2()">取消</el-button>
        </div>
      </div>
    </div>
    <div id=mod1>
      <img src="../../assets/logo.png">
      <div id=mod1_1>开发者中心</div>
      <div id=mod1_2><el-button @click="logout()">退出</el-button></div>
    </div>
    <div id=mod2>
      <div id=mod3>
        <div style="height:1rem"></div>
        <el-menu
          el-menuid="right_1"
          mode="horizontal"
          :router=false
          :default-active='activation'
        >
          <el-menu-item class="menulist" v-for="(list, key) in leftList" :key="key" :index="list.name" @click="handleSelect(key)">
            <i class="iconfont icon-lifangti" v-if="(list.name === '应用中心')"></i>
            <i class="iconfont icon-renminbi" v-if="(list.name === '收益中心')"></i>
            <i class="iconfont icon-xiaoxi" v-if="(list.name === '消息中心')"></i>
            <i class="iconfont icon-gerenyonghutouxiang" v-if="(list.name === '个人中心')"></i>
            {{list.name}}
          </el-menu-item>
        </el-menu>
      </div>
      <el-scrollbar id=mod4><router-view></router-view></el-scrollbar>
    </div>
  </div>
</template>

<script>
// $emit----v-on子传父
// 非父子组件进行传值公共js
import DataChild from '../Mate/data.vue'
import Data from '../Mate/data.js'
export default {
  inject: ['reload'],
  // 引用this.reload()-----刷新
  data() {
    return {
      screenWidth: document.body.clientWidth,
      screeHeight: document.body.clientHeight,
      mainName: 'devMain',
      activation: '应用中心',
      leftList: { 1: { name: '应用中心', url: '/AppCenter' }, 2: { name: '收益中心', url: '/404' }, 3: { name: '消息中心', url: '/404' }, 4: { name: '个人中心', url: '/Dev_Personal' }},
      url: '/AppCenter',
      addDisplay: 0,
      upDemo: { cnName: '', enName: '', id: '',  introduce: '', file_type: '', copy: '' },
      upUrl: '',
      addUrl: '',
      file: '',
      imageUrl: '',
      informate: '',
      test: '',
      addgaddisplay: 1
    }
  },
  created() {
    this.$router.push({ path: '/appCenter' }).catch(error => error)
    var c = localStorage.getItem('Https')
    if (c === 'No.1') {
      this.Url = DataChild.httpUrl_1
    } else if (c === 'No.2'){
      this.Url = DataChild.httpUrl_2
    } else if (c === 'No.3'){
      this.Url = DataChild.httpUrl_3
    } else if (c === 'No.4'){
      this.Url = DataChild.httpUrl_4
    } else {
      console.clear()
    }
  },
  mounted: function () {
    // mounted一直监控？可改位置----目前此方法还不能正确运用
    // 用$on事件来接收参数
    var that = this
    Data.$on('demo', (data) => {
      // console.log(data)
    })
    Data.$on('addDisplay', (data) => {
      if (data === 2) {
        setTimeout(async() => {
          var k = { username: localStorage.getItem('username-Dev') }
          const { data: res } = await this.$http.post('/application/getToolsList', k)
          for (var i in res.toolslist) {
            if (res.toolslist[i].tool_id === this.addUrl) break
          }
          this.informate = res.toolslist[i]
          console.log(res.toolslist[i])
          this.upDemo = this.informate
          this.imageUrl = this.Url + '/' + this.upDemo.img_url
        }, 100)
      }
      setTimeout(async() => { this.addDisplay = data }, 200)
    })
    Data.$on('addUrl', (data) => {
      this.addUrl = data
      // console.log(this.upUrl + '/api' + '/upfile/' + this.addUrl + '/Data/img')
    })
  },
  methods: {
    // 接收子组件的值
    childData: function (data) {
    // console.log(data)
    },
    // 接收公共js的值
    demoData: function () {
      Data.$emit('demo', this.demo)
    },
    handleSelect(key) {
      // console.log(key, this.leftList[key].url, this.url)
      if (this.url === this.leftList[key].url) return 0
      this.url = this.leftList[key].url
      // console.log(this.url)
      this.$router.push({ path: this.url, query: { name: localStorage.getItem('name') } }).catch(error => error)
    },
    async addgad1() {
      if (this.upDemo.cnName === '' || this.upDemo.enName === '' || this.upDemo.introduce === '' || this.upDemo.file_type === '') {
        this.$message.error("请填写完整信息");
        return;
      }
      if (this.imageUrl === '' || this.upDemo.enName === '' || this.upDemo.introduce === '' || this.upDemo.file_type === '') {
        this.$message.error("请上传一个不大于300KB的图片");
        return;
      }
      this.$message({
        type: 'success',
        message: '正在安装环境请稍等'
      });
      // this.addgaddisplay = 2
      const { data: res } = await this.$http.post('application/devAdd', { username: localStorage.getItem('username-Dev'), tool_msg: { cnName: this.upDemo.cnName, enName: this.upDemo.enName, copy: this.upDemo.copy, introduce: this.upDemo.introduce, file_type: this.upDemo.file_type }, tokenDev: localStorage.getItem('token-Dev') }, { emulateJSON: true })
      // console.log(res)
      if (res.code === 'tokenError') {
        // localStorage.removeItem('username-Dev');
        // localStorage.removeItem('token-Dev');
        // localStorage.clear()
        this.$message({
          type: 'error',
          message: 'token失效，请从数据库获取。'
        });
        // this.$router.push({ path: '/loginDev' })
        return '登录已失效请重新登录。'
      }
      if (res.code === 200) {
        this.$refs.upload1.submit()
      } else {
        this.$message.error("创建失败");
        this.addgaddisplay = 1
        return;
      }
      this.addgad1_addf()
    },
    addgad2() {
      // var k = { username: localStorage.getItem('name'), toolname: { cnName: this.upDemo.cnName, enName: this.upDemo.enName, introduce: this.upDemo.introduce, file_type: this.upDemo.file_type } }
      // console.log(k)
      Data.$emit('addDisplay', 0)
      setTimeout(async() => {
        this.upDemo = { cnName: '', enName: '', id: '',  introduce: '', file_type: ''}
        this.imageUrl = '' 
      }, 250)
    },
    async addgad1_addf() {
      console.clear()
      var k = { username: localStorage.getItem('username-Dev'), tool_msg: { cnName: this.upDemo.cnName, enName: this.upDemo.enName, introduce: this.upDemo.introduce, file_type: this.upDemo.file_type } }
      const { data: res } = await this.$http.post('application/verification', k, { emulateJSON: true })
      if (res.code === 200) {
        this.$message.success("应用创建成功");
        setTimeout(async() => { Data.$emit('addDisplay', 0) }, 100 * 1)
        setTimeout(async() => { this.reload() }, 100 * 2)
      } else {
        this.addgad1_addf_time()
      }
    },
    addgad1_addf_time() {
      setTimeout(async() => {
        this.addgad1_addf()
      }, 10000)
    },
    httpRequest(params) {
      const form = new FormData()
      form.append("file", params.file)
      // application/devAddImage
      this.$http.post('application/devAddImage/' + this.upDemo.enName, form, { emulateJSON: true })
    },
    logout() {
      window.sessionStorage.clear()
      localStorage.removeItem('username-Dev');
      localStorage.removeItem('token-Dev');
      // localStorage.clear()
      this.$message({
        type: 'success',
        message: '已退出。'
      });
      this.$router.push({ path: '/BioinforCloud' })
      return '已退出。'
    },
    handleAvatarSuccess(file, fileList) {
      // console.log(this.imageUrl)
      const fileType = file.raw.type
      const isImage = fileType.indexOf("image") != -1
      const isLt2M = file.size / 1024  < 300
      if (!isImage) {
        this.$message.error("只能上传图片");
        return 0;
      }
      if (!isLt2M) {
        this.$message.error("只能上传图片大小小于300KB");
        return 0;
      }
      // console.log(file, fileList)
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    replace() {
      var k = { tool_id: {}, tool_msg: {}, tokenDev: localStorage.getItem('token-Dev') }
      for (var i in this.upDemo) {
        if (i === 'tool_id') {
          k[i] = this.upDemo[i]
        } else {
          k.tool_msg[i] = this.upDemo[i]
        }
      }
      k.tool_msg.copy = ''
      k.username = localStorage.getItem('username-Dev')
      setTimeout(async() => {  this.$refs.upload3.submit() }, 0)
      setTimeout(async() => { Data.$emit('addDisplay', 0) }, 50)
      setTimeout(async() => {
        const { data: res } = await this.$http.post('application/updateToolMsg', k, { emulateJSON: true })
        if (res.code === 'tokenError') {
          // localStorage.removeItem('username-Dev');
          // localStorage.removeItem('token-Dev');
          // localStorage.clear()
          this.$message({
            type: 'error',
            message: 'token失效，请从数据库获取。'
          });
          // this.$router.push({ path: '/loginDev' })
          return '登录已失效请重新登录。'
        }
        this.$message(res.msg);
        if (res.state === 203) {
          location.reload()
        }
      }, 100)
    }
  },
  props: {},
  components: {
    DataChild
  },
}
</script>

<style lang="less" scoped>
#devMain {
  width: 100%;
  height: 100%;
  background-color: rgba(250,250,250,1);
  #mod1 {
    position: absolute;
    height: 2rem;
    line-height: 2rem;
    width: 100%;
    background-color: rgba(240,240,240,0.1);
    // box-shadow: 0rem 0.1rem 0.1rem rgba(0,0,0,0.1);
    border-bottom: 1px solid #ccc;
    color: #000;
    margin-bottom: 0.1rem;
    z-index: 99;
    > img {
      height: 1.5rem;
      width: auto;
      float: left;
      margin: 0.25rem 0 0 0.5rem;
    }
    #mod1_1 {
      height: 2rem;
      line-height: 2rem;
      font-size: 0.8rem;
      text-align: center;
      width: 8rem;
      margin: 0 auto;
    }
    #mod1_2 {
      position: absolute;
      height: 2rem;
      line-height: 2rem;
      top: 0;
      right: 1rem;
      .el-button {
        height: 2rem;
        line-height: 2rem;
        width: 2rem;
        font-size: 0.6rem;
        font-weight: normal;
        color: #222;
        border-radius: 0.5rem;
        padding: 0;
        background-color: rgba(0,0,0,0);
        border: none;
      }
    }
  }
  #mod2 {
    box-sizing: border-box;
    padding-top: 2rem;
    height: 100%;
    background-color:rgba(0,0,0,0);
    #mod3, #mod4 {
      float: left;
      box-sizing: border-box;
      box-shadow: none;
    }
    #mod3 {
      height: 100%;
      width: 6rem;
      border-right: 1px solid #ccc;
      .el-menu-item {
        border: none;
      }
      .el-menu {
        width: 100%;
        height: calc(100% - 1rem);
        border-radius: 1rem;
        background-color: rgba(0,0,0,0);
        box-sizing: border-box;
      }
      .el-menu-item {
        color: #000;
        font-size: 0.6rem;
        box-sizing: border-box;
        padding: 0 1rem;
        height: 2rem;
        line-height: 2rem;
        float: left;
        width: 100%;
        background-color: rgba(0,0,0,0);
      }
      .el-menu.el-menu--horizontal {
        border: none;
      }
      .el-menu-item:focus {
        border: none;
      }
      .el-menu--horizontal > .el-menu-item.is-active {
        border: none;
        background-color: #e2eefa;
      }
      .iconfont {
        font-size: 0.6rem;
        margin-right: 0.5rem;
      }
    }
    #mod4 {
      width: calc(100% - 6rem);
      background-color: rgba(0,0,0,0);
      /deep/ .el-scrollbar__view {
        width: 100%;
        height: 100%;
      }
    }
  }
  #mod5 {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.15);
    z-index: 100;
    #mod5_1 {
      // margin: calc(50% - 2.5rem);
      margin: calc(25% - 12rem) auto 0 auto;
      width: 20rem;
      height: 20rem;
      background-color: #fff;
      border-radius: 0.5rem;
      #mod5_2 {
        height: 1rem;
        line-height: 1rem;
        text-align: center;
        font-size: 0.7rem;
      }
      #mod5_3 {
        height: calc(100% - 4rem);
      }
      .mod5_4 {
        font-size: 0.6rem;
        height: 1rem;
        line-height: 1rem;
        margin: 0.5rem 2.5% 0 2.5%;
        margin-bottom: 1rem;
        > span {
          width: 25%;
          float: left;
          text-align: right;
        }
        .el-input {
          float: left;
          width: 70%;
        }
        /deep/ .el-input__inner {
          font-size: 0.5rem;
          padding: 0 0.5rem;
          height: 1rem;
          line-height: 1rem;
          text-align: left;
        }
        .el-button {
          padding: 0;
          height: 0.8rem;
          border-radius: 0.4rem;
          font-size: 0.45rem;
          width: 2.5rem;
        }
        /deep/ .el-upload-list__item-name {
          margin-right: 0.7rem;
          padding-left: 0.7rem;
        }
        /deep/ .el-upload-list__item {
          font-size: 0.45rem;
          height: 1rem;
          line-height: 1rem;
        }
        /deep/ .el-upload-list {
          width: calc(75% - 3rem );
          float: right;
        }
        /deep/ .el-upload-list__item:first-child{
          margin-top: 0;
        }
        /deep/ .el-upload-list__item .el-icon-close {
          top: 0.25rem;
          right: 0.25rem;
        }
        .el-textarea {
          float: left;
          width: 70%;
        }
        /deep/ .el-textarea__inner {
          font-size: 0.5rem;
          padding: 0 0.5rem;
          height: 1rem;
          line-height: 1rem;
          text-align: left;
          height: 2.5rem!important;
          // max-height: 3rem;
        }
        .el-radio-button {
          box-sizing: border-box;
          width: auto;
          padding: 0;
          width: 2rem;
          height: 1rem;
          border-radius: 0.5rem;
          margin-left: 0.5rem;
          border: 1px solid #DCDFE6;
          text-align: center;
        }
        /deep/ .el-radio-button__inner {
          box-sizing: border-box;
          border: none;
          padding: 0;
          line-height: 1rem;
          color: rgba(0,0,0,1);
          background-color: rgba(0,0,0,0);
          font-size: 0.5rem;
        }
        /deep/ .el-radio-button:first-child .el-radio-button__inner {
          width: 100%;
          border-radius: 0.5rem;
        }
        /deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner
        {
          box-sizing: border-box;
          width: 100%;
          border-radius: 0.5rem;
          background-color: #409eff;
          // box-shadow: 0 0 0.5rem #409eff;
        }
        /deep/ .el-radio-button__orig-radio:disabled+.el-radio-button__inner {
          color: #c0c4cc;
          background-color: rgba(0,0,0,0);
          border-color: #ebeef5;
        }
      }
      #mod5_5, #mod5_7 {
        width: 6.5rem;
        margin: auto;
        height: 1rem;
        line-height: 1rem;
        .el-button {
          padding: 0;
          font-size: 0.45rem;
          margin: 0 0 0 0.5rem;
          width: 2.5rem;
          height: 1rem;
          border-radius: 0.5rem;
        }
      }
      #mod5_6 {
        height: 4rem;
        line-height: 4rem;
        /deep/ .el-upload {
          width: calc(4rem / 9 * 16);
          height: 4rem;
          box-sizing: border-box;
          margin-left: 1rem;
          border: 1px dashed #000;
          > img {
            height: calc(100% + 2px);
            width: calc(100% + 2px);
            position: relative;
            top: -1px;
            left: -1px;
          }
        }
      }
      #mod5_7 {
        width:95%;
        text-align: center;
        font-size: 0.6rem;
        margin: 0.5rem 2.5% 0 2.5%;
      }
    }
  }
  #mod6 {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.15);
    z-index: 100;
    #mod6_1 {
      margin: calc(25% - 10rem) auto 0 auto;
      width: 20rem;
      height: 20rem;
      background-color: #fff;
      border-radius: 0.5rem;
    }
    #mod6_2 {
      width: 100%;
      height: calc(100% - 2rem);
      // background-color: #ccc;
    }
    #mod6_3 {
      // background-color: #eee;
      height: 2rem;
      width: 6.5rem;
      margin: auto;
      height: 1rem;
      line-height: 1rem;
      .el-button {
        padding: 0;
        font-size: 0.45rem;
        margin: 0 0 0 0.5rem;
        width: 2.5rem;
        height: 1rem;
        border-radius: 0.5rem;
      }
    }
    .mod6_4 {
      font-size: 0.6rem;
      height: 1rem;
      line-height: 1rem;
      margin: 0.5rem 2.5% 0 2.5%;
      margin-bottom: 1rem;
      > span {
        width: 25%;
        float: left;
        text-align: right;
      }
      .el-input {
        float: left;
        width: 70%;
      }
      /deep/ .el-input__inner {
        font-size: 0.5rem;
        padding: 0 0.5rem;
        height: 1rem;
        line-height: 1rem;
        text-align: left;
      }
      .el-button {
        padding: 0;
        height: 0.8rem;
        border-radius: 0.4rem;
        font-size: 0.45rem;
        width: 2.5rem;
      }
      /deep/ .el-upload-list__item-name {
        margin-right: 0.7rem;
        padding-left: 0.7rem;
      }
      /deep/ .el-upload-list__item {
        font-size: 0.45rem;
        height: 1rem;
        line-height: 1rem;
      }
      /deep/ .el-upload-list {
        width: calc(75% - 3rem );
        float: right;
      }
      /deep/ .el-upload-list__item:first-child{
        margin-top: 0;
      }
      /deep/ .el-upload-list__item .el-icon-close {
        top: 0.25rem;
        right: 0.25rem;
      }
      .el-textarea {
        float: left;
        width: 70%;
      }
      /deep/ .el-textarea__inner {
        font-size: 0.5rem;
        padding: 0 0.5rem;
        height: 1rem;
        line-height: 1rem;
        text-align: left;
        height: 2.5rem!important;
        // max-height: 3rem;
      }
      .el-radio-button {
        box-sizing: border-box;
        width: auto;
        padding: 0;
        width:2rem;
        height: 1rem;
        margin-left: 0.5rem;
        border: 1px solid #DCDFE6;
        text-align: center;
        border-radius: 0.5rem;
      }
      /deep/ .el-radio-button__inner {
        box-sizing: border-box;
        border: none;
        padding: 0;
        line-height: 1rem;
        color: rgba(0,0,0,1);
        background-color: rgba(0,0,0,0);
        font-size: 0.5rem;
      }
      /deep/ .el-radio-button:first-child .el-radio-button__inner {
        width: 100%;
        border-radius: 0.5rem;
      }
      /deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner
      {
        box-sizing: border-box;
        width: 100%;
        border-radius: 0.5rem;
        background-color: #409eff;
        // box-shadow: 0 0 0.5rem #409eff;
      }
      /deep/ .el-radio-button__orig-radio:disabled+.el-radio-button__inner {
        color: #c0c4cc;
        background-color: rgba(0,0,0,0);
        border-color: #ebeef5;
      }
    }
    #mod6_5 {
      height: 4rem;
      line-height: 4rem;
      /deep/ .el-upload {
        width: calc(4rem / 9 * 16);
        height: 4rem;
        box-sizing: border-box;
        margin-left: 1rem;
        border: 1px dashed #000;
        > img {
          height: calc(100% + 2px);
          width: calc(100% + 2px);
          position: relative;
          top: -1px;
          left: -1px;
        }
      }
    }
    #mod6_6 {
      height: 1rem;
      line-height: 1rem;
      text-align: center;
      font-size: 0.7rem;
    }
  }
}
#mod4 {
  height: 100%;
  width: 100%;
  border-radius: 0.3rem;
  .el-scrollbar{
    height: 100%;
    width: 100%;
  }
  .el-scrollbar__bar{
  right: 0;
  z-index: 10;
  }
  /deep/ .el-scrollbar__wrap{
    overflow-x:hidden;
    // overflow-y:hidden;
  }
  .el-scrollbar__bar.is-vertical {
    width: 12px;
    border-radius: 6px;
  }
  .el-scrollbar__thumb{
    background-color: rgba(0,0,0,0);
  }
  .el-scrollbar__thumb:hover {
    background-color: rgba(0,0,0,0);
  }
}
</style>
