<template>
  <div :style="{ width: screenWidth+ 'px' , height: screeHeight+ 'px' }" id=DevData  v-cloak>
  <!-- <div style="width:100%,height:100%"> -->
    <dataChild v-on:childData="childData" :mainName=mainName></dataChild>
    <div id=mod1>
      {{title}}
    </div>
    <div id=mod2>
      <el-input
        placeholder="输入关键字进行过滤"
        v-model="filterText">
      </el-input>
      <div id=mod2_1>
        <div v-if="(typeof(nodeData.address) === 'string')" style="float:left;margin-right: 1rem;width:9rem">{{nodeData.address + ':'}}</div>
        <el-button @click="Del(nodeData.address, 1)" v-if="(this.buttonDisplay === 1)" type="primary">删除</el-button>
        <el-button @click="Del(nodeData.address, 0)" v-if="(this.buttonDisplay === 0)" type="primary" style="width:2.5rem">删除文件夹</el-button>
        <el-button @click="Download(nodeData.address)" v-if="(this.buttonDisplay === 0 || this.buttonDisplay === 1)" type="primary">下载</el-button>
        <el-upload
          v-if="(this.buttonDisplay === 0)"
          class="upload-demo"
          :show-file-list='false'
          :action="Url + '/application/upload1/DevFile/' + this.$route.query.ID + '/' + G_usernameDev + '/' + nodeData.address"
          multiple
          title='上传文件'
          :on-change="(file, fileList) => uploadRe(file, fileList)"
          :on-progress='(event, file, fileList) => onUploadProgress(event, file, fileList)'
          :on-success='(file, fileList) => Upload2(file, fileList, nodeData.address)'>
          <el-button size="small" type="success" style="width:3rem">上传到文件夹</el-button>
        </el-upload>
        <div v-if="(this.upload_percentage !== '')">{{this.upload_percentage}}</div>
      </div>
      <el-scrollbar class="MainScrollbar">
        <el-tree
          accordion
          class="filter-tree"
          :data="directory"
          :props="defaultProps"
          :filter-node-method="filterNode"
          node-key="id"
          @node-click="nodeClick"
          :default-expanded-keys="treeOpen"
          :default-expand-all='true'
          ref="tree">
        </el-tree>
      </el-scrollbar>
    </div>
    <div id=mod3>
      <!-- 选取展示图片 -->
      <div id=mod3_1>
        <div style="float: left;">预装R包：</div>
        <el-input
          placeholder="请输入包名后点击运行"
          type="textarea"
          v-model="R_name">
        </el-input>
        <el-button @click="run_Rname" size="small" type="success">运行</el-button>
        <el-button @click="reUpload2" size="small" type="success">重置</el-button>
        <div style="margin-top: 1.4rem;">
          {{InstalStatus}}
        </div>
        <div id=mod3_2>
          <el-button class="iconfont icon-icon-test4" @click="close"></el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// $emit----v-on子传父
// 非父子组件进行传值公共js
import DataChild from '../Mate/data.vue'
import Data from '../Mate/data.js'
// import qs from 'qs'
export default {
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  inject: ['reload'],
  // 引用this.reload()-----刷新
  data() {
    return {
      screenWidth: document.body.clientWidth,
      screeHeight: document.body.clientHeight,
      mainName: 'DevData',
      filterText: '',
      directory: [],
      Url: '',
      ID: '',
      defaultProps: { children: 'children', label: 'label' },
      fileList: { },
      title: '',
      nodeData: '',
      traverseData: '',
      buttonDisplay: 2,
      treeOpen: [],
      uploadForm: 0,
      uploadFormMax: 0,
      InstalStatus: '',
      form: new FormData(),
      R_name: '',
      upload_percentage: '',
      upload_success: 0,
      G_usernameDev: localStorage.getItem('username-Dev'),
      G_tokenDev: localStorage.getItem('token-Dev'),
      G_Https: localStorage.getItem('Https'),
    }
  },
  created() {
    setTimeout(async() => { 
      this.ini()
    }, 1000)
  },
  mounted: function () {
    // mounted一直监控？可改位置----目前此方法还不能正确运用
    // 用$on事件来接收参数
    var that = this
    Data.$on('demo', (data) => {
      console.log(data)
    })
  },
  methods: {
    // 接收子组件的值
    childData: function (data) {
    // console.log(data)
    },
    // 接收公共js的值
    demoData: function () {
      Data.$emit('demo', this.demo)
    },
    async ini () {      
      this.getURL()
      var k = { username: this.G_usernameDev, tool_id: this.$route.query.ID }
      // token验证待写
      // const { data: res1 } = await this.$http.post('checkingid_for_username', k)
      // // console.log(res1, k)
      // if (res1.code !== 200) {
      //   window.close()
      //   return 0
      // }
      k = { tool_id: this.$route.query.ID, username: this.G_usernameDev, tokenDev: this.G_tokenDev }
      this.ID = this.$route.query.ID
      const { data: res } = await this.$http.post('application/ToolsAttribute', k)
      if (res.code === 'tokenError') {
        localStorage.removeItem('username-Dev');
        localStorage.removeItem('token-Dev');
        // localStorage.clear()
        this.$message({
          type: 'error',
          message: '登录已失效请重新登录。'
        });
        this.$router.push({ path: '/loginDev' })
        return '登录已失效请重新登录。'
      }
      this.title = '"' + res.cnName + '"' + '文件夹'
      const { data: res_a } = await this.$http.post('application/getFloder', k)
      // console.log(res_a.file_list)
      this.fileList = res_a.file_list
      this.getDirectory ()
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    getDirectory () {
      this.directory = this.fileList
      // console.log(this.$route.query.open)
      if (typeof(this.$route.query.open) !== 'undefined') {
        this.$nextTick(() => {
          this.$refs.tree.store.nodesMap[this.$route.query.open].expanded = true  
        })
      }
    },
    close () {
      // this.$router.push({ path: '/Dev_Main', query: { name: this.G_usernameDev } })
      window.close()
    },
    nodeClick:function(obj, node, data) {
      this.nodeData = { id: obj.id, label: obj.label, type: obj.type, address: obj.address }
      this.Traverse()
    },
    Traverse () {
      if (this.nodeData.type === 'main') {
        this.buttonDisplay = 0
      } else {
        this.buttonDisplay = 1
      }
    },
    async Del(data, model) {
      if (data.split('/')[1] === '') {
        return this.$message.error('根目录不能删除')
      }
      // 1删除文件0删除文件夹
      var k = { tool_id: this.$route.query.ID, path: data, model: model}
      const { data: res } = await this.$http.post('application/delFile', k)
      // console.log(res)
      if (res.code === 200) {
        this.$message.success(res.msg)
        // var l = this.nodeData.id
        // this.$router.push({ path: '', query: { name: this.G_usernameDev, ID: this.$route.query.ID, open: l } }).catch(error => error)
        this.$router.push({ path: '', query: { name: this.G_usernameDev, ID: this.$route.query.ID } }).catch(error => error)
        this.reload()
      }
    },
    async Download(data) {
      var filename = data
      // 将文件压缩为tar
      const { data: res1 } = await this.$http.post('application/beforGetFile2/Dev/' + this.$route.query.ID + '/' + filename)
      if (res1.code === 200) {
        // 下载tar文件
        var url = this.Url + '/application/get_file/DevFile/' + this.$route.query.ID + '/' + this.G_usernameDev + '/' + this.$route.query.ID + '.tar'
        console.log(url)
        window.open(url)
      }
    },
    Upload1 () {
      this.$refs.upload3.submit()
    },
    Upload3 () {
      this.$refs.upload1.submit()
    },
    uploadRe(file, fileList) {
      // console.log(fileList)
      this.uploadForm = 0
      this.uploadFormMax = fileList.length
    },
    onUploadProgress(event, file, fileList) {
      this.upload_percentage = file.name + ' : ' + event.percent.toFixed(1) + '%'
      if (event.percent === 100) {
        this.upload_success = this.upload_success + 1
      }
    },
    async Upload2(file, fileList, data) {
      if (data !== 'R安装' && this.upload_success === this.uploadFormMax) {
        this.form = new FormData()
        this.reload()
      }
    },
    async ViewStatus () {
    //   const { data: res } = await this.$http.get('get_install/Rpackages/state/' + this.G_usernameDev, { emulateJSON: true })
    //   if (res.code === 101) {
    //     setTimeout(async() => {
    //       this.ViewStatus()
    //     }, 5000)
    //     this.InstalStatus = res.state
    //   } else if (res.code === 206) {
    //     var er = res.state
    //     this.InstalStatus = er + '安装失败'
    //   } else if (res.code === 200) {   
    //   }
    },
    judge(data, k) {
      var i = 0
      for (var j in data) {
        if(data[j] === k) i = i + 1
      }
      return i
    },
    reUpload () {
      this.$refs.upload1.clearFiles()
      this.form = new FormData()
      this.InstalStatus = ''
      this.$message.success('已重置')
    },
    async run_Rname () {
      var k = { packages: this.R_name, username: this.G_usernameDev, ID: this.$route.query.ID }
      const { data: res } = await this.$http.post('application/install_R', k, { emulateJSON: true })
      this.InstalStatus = '正在准备安装...'
      setTimeout(() => {
        this.InstalStatus = '安装预处理完成，请等待code下输出安装日志后再使用。'
      }, 3000);
    },
    reUpload2 () {
      this.R_name = ''
      this.InstalStatus = ''
    },
    async getURL() {
      var c = localStorage.getItem('Https')
      if (c === 'No.1') {
        this.Url = DataChild.httpUrl_1
      } else if (c === 'No.2'){
        this.Url = DataChild.httpUrl_2
      } else if (c === 'No.3'){
        this.Url = DataChild.httpUrl_3
      } else if (c === 'No.4'){
        this.Url = DataChild.httpUrl_4
      } else {
        console.clear()
      }
      return c
    },
  },
  props: {},
  components: {
    DataChild
  },
}
</script>

<style lang="less" scoped>
#DevData {
  background-color: rgba(250,250,250,1);
  #mod1 {
    width: 95%;
    height: 2rem;
    line-height: 2rem;
    font-size: 0.8rem;
    text-align: center;
    background-color: rgba(0,0,0,0);
    margin: auto;
  }
  #mod2 {
    box-sizing: border-box;
    padding: 0.5rem 0;
    width: 95%;
    height: calc(100% - 7rem);
    margin: auto;
    background-color: #fff;
    box-shadow: 0 0 0.3rem rgba(0,0,0,0.15) inset;
    .el-input {
      height: 1rem;
      line-height: 1rem;
      font-size: 0.5rem;
      width: 60%;
      margin: 0.5rem 20%;
    }
    #mod2_1 {
      height: 2rem;
      line-height: 2rem;
      font-size: 0.6rem;
      margin: 0.5rem 2.5%;
      box-sizing: border-box;
      background-color: #fff;
      box-shadow: 0 0 0.3rem rgba(0,0,0,0.15) inset;
      border-radius: 0.5rem;
      padding: 0 0.5rem;
      > div {
        float:left;
      }
      .upload-demo {
        float:left;
      }
      .el-button {
        float:left;
        padding: 0;
        width: 2rem;
        height: 1rem;
        border-radius: 0.25rem;
        margin:0.5rem 0.5rem 0 0;
        font-size: 0.45rem;
      }
    }
    /deep/ .el-input__inner {
      box-sizing: border-box;
      height: 1rem;
      line-height: 1rem;
      padding: 0 0.5rem;
    }
    .el-tree {
      // max-height: calc(100% - 4rem);
      width: 95%;
      margin: auto;
      background-color: rgba(0,0,0,0);
    }
    /deep/ .el-tree-node__content {
      height: 1.2rem;
      line-height: 1.2rem;
      padding: 0!important;
    }
    /deep/ .el-tree-node__label {
      font-size: 0.7rem;
    }
    /deep/ .el-tree-node__children {
      padding-left: 0.5rem;
    }
    /deep/ .el-tree-node__content:hover, /deep/ .el-tree-node:focus > .el-tree-node__content {
      background-color: rgba(0,0,0,0);
    }
  }
  #mod3 {
    width: 95%;
    height: 5rem;
    background-color: rgba(0,0,0,0);
    margin: auto;
    #mod3_1 {
      font-size: 0.6rem;
      font-weight: 500;
      float: left;
      height: 1.2rem;
      line-height: 1.2rem;
      margin: 0.5rem 0.2rem 0 0;
      width: 100%;
      /deep/ .el-textarea {
        width: 30%;
        margin-right: 2.5%;
        font-size: 0.5rem;
        height: 2.6rem;
        float: left;
      }
      /deep/ .el-textarea__inner {
        box-sizing: border-box;
        padding: 0.25rem;
        line-height: 0.7rem;
        border-radius: 0.25rem;
        height: 100%!important;
      }
    }
    .el-button {
      box-sizing: border-box;
      width: 4rem;
      height: 1.2rem;
      font-size: 0.5rem;
      line-height: 1.2rem;
      border-radius: 0.6rem;
      margin: 1.4rem 1rem 0 0;
      float: left;
      padding: 0;
    }
    /deep/ .el-upload {
      float: left;
    }
    #mod3_2 {
      position: absolute;
      right: 2.5%;
      bottom: 2rem;
      .el-button {
        width: auto;
        height: auto;
        background-color: rgba(0,0,0,0);
        border: none;
        color: #000;
      }
      /deep/ .iconfont {
        font-size: 1rem;
      }
      .el-button:focus, .el-button:hover {
        color: #000;
      }
    }
  }
}
.MainScrollbar {
  height: calc(100% - 5rem);
  width: 100%;
  border-radius: 0.3rem;
  .el-scrollbar{
    height: 100%;
    width: 100%;
  }
  .el-scrollbar__bar{
  right: 0;
  z-index: 10;
  }
  /deep/ .el-scrollbar__wrap{
    overflow-x:hidden;
    // overflow-y:hidden;
  }
  .el-scrollbar__bar.is-vertical {
    width: 12px;
    border-radius: 6px;
  }
  .el-scrollbar__thumb{
    background-color: rgba(0,0,0,0);
  }
  .el-scrollbar__thumb:hover {
    background-color: rgba(0,0,0,0);
  }
}
</style>
