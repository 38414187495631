<template>
  <div style="width:100%,height:100%" id=add>
    <dataChild v-on:childData="childData" :mainName=mainName></dataChild>
    <el-scrollbar id="MainScrollbar">
      <div id=mod1>
        <el-collapse v-model="activeNames" @change="handleChange">
          <div v-for="(item,key) in menulist" :key="key">
            <div class=mod2 v-if="(listDisplay[key])" style="border-bottom: 0.035rem solid #5874d8;"></div>
            <div class=mod2 v-if="!(listDisplay[key])"></div>
            <div class="mod3 iconfont icon-Barrow" v-if="(listDisplay[key])"></div>
            <div class="mod3 iconfont icon-arrow-right" v-if="!(listDisplay[key])"></div>
            <el-collapse-item :title=item.name :name="key">
              <div v-for="(page,key) in item.list" :key="key">
                <el-button @click="addto(page)">
                  <div class="iconfont icon-wenben" v-if="(page === '单行文本')"></div>
                  <div class="iconfont icon-8636f874" v-if="(page === '多行文本')"></div>
                  <div class="iconfont icon-danxuankuangxuanzhong" v-if="(page === '单项选择')"></div>
                  <div class="iconfont icon-fuxuankuangxuanzhong" v-if="(page === '多项选择')"></div>
                  <div class="iconfont icon-chazhaobiaodanliebiao" v-if="(page === '搜索选项')"></div>
                  <div class="iconfont icon-shujuwenjian" v-if="(page === '文件上传')"></div>
                  <div class="iconfont icon-excelwenjian" v-if="(page === '多文件上传')"></div>
                  <div class="iconfont icon-shujubao" v-if="(page === '文件码上传')"></div>
                  <div class="iconfont icon-tupian01" v-if="(page === '图片上传')"></div>
                  <div class="iconfont icon-xiazai" v-if="(page === '下载')"></div>
                  <div class="iconfont icon-shujuzuozhanshi" v-if="(page === '显示框')"></div>
                  <div>{{page}}</div>
                </el-button>
              </div>
            </el-collapse-item>
          </div>
        </el-collapse>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
// $emit----v-on子传父
// 非父子组件进行传值公共js
import DataChild from '../Mate/data.vue'
import Data from '../Mate/data.js'
export default {
  inject: ['reload'],
  // 引用this.reload()-----刷新
  data() {
    return {
      screenWidth: document.body.clientWidth,
      screeHeight: document.body.clientHeight,
      mainName: 'Add',
      activeNames: [''],
      // 1:{name: '常用列表', list: { 1: '单行文本', 2: '多行文本', 3: '单选按钮', 4: '多选按钮', 5: '上传表格', 6: '上传文本' }},
      menulist: { 1:{name: '文本', list: { 1: '单行文本', 2: '多行文本' }}, 2:{name: '选项', list: { 1: '单项选择', 2: '多项选择', 3: '搜索选项' }}, 3:{name: '上传', list: { 1: '文件上传', 2: '多文件上传', 3: '文件码上传', 4: '图片上传' }}, 4:{name: '输出', list: { 1: '下载', 2: '显示框' }} },
      addlist: {},
      listDisplay: { 1: false, 2: false, 3: false, 4: false }
    }
  },
  created() {
  },
  mounted: function () {
    // mounted一直监控？可改位置----目前此方法还不能正确运用
    // 用$on事件来接收参数
    var that = this
    Data.$on('demo', (data) => {
      console.log(data)
    })
    Data.$on('delete', (data) => {
      this.addlist = data
      Data.$emit('addlist', this.addlist)
    })
    Data.$on('DevIniData', (data) => {
      var a = JSON.parse(data.toolat)
      var k = {}
      for (var i in a) {
        k[i] = a[i].type
      }
      // console.log(a, k)
      this.addlist = k
      Data.$emit('addlist', this.addlist)
    })
  },
  methods: {
    // 初始化
    ini () {

    },
    // 接收子组件的值
    childData: function (data) {
    // console.log(data)
    },
    // 接收公共js的值
    demoData: function () {
      Data.$emit('demo', this.demo)
    },
    handleChange(val) {
      for (var k in this.listDisplay) {
        for (var j in val) {
          // console.log(k, j, k === val[j])
          this.listDisplay[k] = (k === val[j])
          if (k === val[j]) break
        }
      }
    },
    addto (page) {
      // console.log(page)
      if (page === '显示框') {
        for (var j in this.addlist) {
          if (this.addlist[j] === '显示框') {
            const h = this.$createElement
            this.$message({
              showClose: false,
              message: h('div', { style: 'color:#F56C6C;height:1rem;font-size:0.6rem;line-height:1rem;padding:0;width:100%;text-align: center;background-color: #fff;', class: 'iconfont icon-icon-test4' }, '显示框已创建'),
              type: 'none',
              center: true
            })
            return 0
          }
        }
      }
      for (var i in this.addlist) {
        // console.log(i)
      }
      if (typeof(i) === 'undefined') {
        i = 0
      } else {
        i = Number(i) + 1
      }
      // console.log(i)
      this.addlist[i] = page
      // console.log(this.addlist)
      Data.$emit('addlist', this.addlist)
    }
  },
  props: {
  },
  components: {
    DataChild
  },
}
</script>

<style lang="less" scoped>
#add {
  width: 100%;
  height: 100%;
  // background-color: #666;
  border-radius: 1rem;
  #mod1 {
    padding: 1rem 0;
    width: 95%;
    height: 100%;
    box-sizing: border-box;
    .el-button, .el-button:focus {
      float: left;
      margin: 0.2rem 1.65% 0.5rem 1.65%;
      font-size: 0.35rem;
      // font-family: "微软雅黑","microsoft yahei","宋体","新宋体",sans-serif;
      // font-size: 12px;
      height: 2.5rem;
      width: 2.5rem;
      line-height: 0.8rem;
      padding: 0;
      border-radius: 0.4rem;
      background-color: rgba(0,0,0,0);
      color: #505567;
      border: 0px solid #DCDFE6;
    }
    .el-button:hover{
      background-color: rgba(0,0,0,0);
      color: #5874D8;
      border-color: #c6e2ff;
      border: 0px solid #DCDFE6;
      box-shadow: 0 0 0.3rem 0 rgba(0,0,0,0.13);
    }
    /deep/ .el-icon-arrow-right{
      display: none;
    }
    /deep/ .el-collapse, /deep/ .el-collapse-item__header, /deep/ .el-collapse-item__wrap {
      border: none;
    }
    // /deep/ .el-collapse-item__wrap {
    // }
    /deep/ .el-collapse-item__header{
      color: #5874d8;
      background-color: rgba(0,0,0,0);
      // background-color: rgba(203,203,203,1);
      // color: #000;
      // background-color: #FFFFCC;
      height: 1.5rem;
      line-height: 1.5rem;
      border-radius: 1rem;
      // background-color: red;
      margin: 0 2.5% 0 2.5%;
      font-size: 0.45rem;
      padding-left: 1rem;
    }
    .iconfont {
      font-size: 0.8rem;
      height: 1.4rem;
      line-height: 1.4rem;
    }
    /deep/ .el-collapse-item__content {
      padding: 0;
    }
    .mod2, .mod3 {
      position: relative;
      width: 65%;
      margin: 0.7rem 1rem 0 0;
      height: 0.035rem;
      background-color: rgba(0,0,0,0);
      border: none;
    }
    .mod3 {
      left: 0;
      width: 10%;
      font-size: 0.5rem;
      top: 0.05rem;
      margin: 0rem 0 0 0.5rem;
      color: #5874d8;
    }
    .mod2 {
      border-bottom: 0.035rem solid #ebeef6;
      float: right;
      // position: absolute;
      // z-index: 99;
      // width: 100%;
    }
  }
  #MainScrollbar {
    height: 100%;
    width: 100%;
    border-radius: 1rem;
    .el-scrollbar{
      height: 100%;
      width: 100%;
    }
    .el-scrollbar__bar{
    right: 0;
    z-index: 10;
    }
    /deep/ .el-scrollbar__wrap{
      overflow-x:hidden;
      // overflow-y:hidden;
    }
    .el-scrollbar__bar.is-vertical {
      width: 12px;
      border-radius: 6px;
    }
    .el-scrollbar__thumb{
      background-color: rgba(0,0,0,0);
    }
    .el-scrollbar__thumb:hover {
      background-color: rgba(0,0,0,0);
    }
  }
}
</style>
