<template>
  <div>
    <div v-if='(size === 1)'>
      <!-- 1920宽的尺寸 -->
      <div :style="{ width: screenWidth+ 'px' , height: screeHeight+ 'px' }" id=template>
      <!-- <div style="width:100%,height:100%"> -->
        <dataChild v-on:childData="childData" :mainName=mainName></dataChild>
        <img src="../../assets/404.jpg" alt="" style="width:100%;height:auto">
      </div>
    </div>
    <div v-if='(size === 0)'>
      <!-- rem的自适应尺寸 -->
      发帖管理
    </div>
  </div>
</template>

<script>
// $emit----v-on子传父
// 非父子组件进行传值公共js
import DataChild from '../Mate/data.vue'
import Data from '../Mate/data.js'
export default {
  // 引用this.getAppUrl()-----切换内外网
  // 引用this.reload()-----刷新
  // window.location.reload() -------刷新整个页面
  inject: ['reload', 'getAppUrl'],
  // 页面标签设置(在模板里面修改文件名【package.json文件修改后重启可设置主标签】)
  metaInfo: {
    title: '消息中心',
    titleTemplate: 'BioinforCloud - %s',
    htmlAttrs: {
      lang: 'ch',
      amp: true
    }
  },
  data() {
    return {
      screenWidth: document.body.clientWidth,
      screeHeight: document.body.clientHeight,
      mainName: 'Template',
      size: 0,
      forum1: [],
      forum2: [],
      forum3: [],
    }
  },
  created() {
    this.sizeData()
    this.ini()
    setTimeout(async() => { 
      
    }, 1000)
  },
  mounted: function () {
    // mounted一直监控？可改位置----目前此方法还不能正确运用
    // 用$on事件来接收参数
    var that = this
    Data.$on('demo', (data) => {
      console.log(data)
    })
  },
  methods: {
    childData: function (data) {
      // 接收子组件的值
      // console.log(data)
    },
    demoData: function () {
      // 接收公共js的值
      Data.$emit('demo', this.demo)
    },
    sizeData() {
      // 默认设置
      this.size = 0
      // 大小设定
      // if (this.screenWidth >= 1920) this.size = 1
    },
    async ini() {
      var k = { username:localStorage.getItem('username'), token: localStorage.getItem('token') }
      const { data: res } = await this.$http.post('application/getForum3', k)
      // 0 待审核
      this.forum1 = res.result1
      // 1 已发布
      // 1 已发布的最新评论时间
      // 之后修改为点开帖子刷新时间，获取new在打开发帖管理和个人中心两次
      // 前后端都改
      var time1 = ''
      var time2 = ''
      var a1 = ''
      var a2 = ''
      var a3 = ['月', '日', '时', '分', '秒']
      this.forum2 = res.result2
      for (var i in res.result2) {
        this.forum2[i]['New'] = ''
        a1 = res.result2[i].newComment.split('年')
        a2 = res.result2[i].accessTime.split('年')
        time1 = a1[0]
        time2 = a2[0]
        for (var j in a3) {
          a1 = a1[1].split(a3[j])
          if (a1[0] < 10) {
            time1 = time1 + '0' + a1[0]
          } else {
            time1 = time1 + a1[0]
          }
          a2 = a2[1].split(a3[j])
          if (a2[0] < 10) {
            time2 = time2 + '0' + a2[0]
          } else {
            time2 = time2 + a2[0]
          }
        }
        if (time1 < time2) {
          this.forum2[i]['New'] = 'New'
        }
      }
      // 2 被驳回
      this.forum3 = res.result3
    },
  },
  props: {},
  components: {
    DataChild
  },
}
</script>

<style lang="less" scoped>
</style>
