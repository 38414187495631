<template>
  <div id=Dev v-cloak>
    <dataChild v-on:childData="childData" :mainName=mainName></dataChild>
    <div id=mod4>
      <div id=mod1>
        <add></add>
      </div>
      <div id=mod2>
        <interface></interface>
      </div>
      <div id=mod3>
        <attribute></attribute>
      </div>
    </div>
  </div>
</template>

<script>
// $emit----v-on子传父
// 非父子组件进行传值公共js
import DataChild from '../Mate/data.vue'
import Data from '../Mate/data.js'
import Add from './UI插件界面1.vue'
import Interface from './UI显示界面2.vue'
import Attribute from './UI属性设置3.vue'
export default {
  inject: ['reload'],
  // 引用this.reload()-----刷新
  data() {
    return {
      screenWidth: document.body.clientWidth,
      screeHeight: document.body.clientHeight,
      mainName: 'Dev'
    }
  },
  created() {
    setTimeout(async() => { 
      this.ini()
    }, 1000)
  },
  mounted: function () {
    // mounted一直监控？可改位置----目前此方法还不能正确运用
    // 用$on事件来接收参数
    var that = this
    Data.$on('demo', (data) => {
      console.log(data)
    })
  },
  methods: {
    // 接收子组件的值
    childData: function (data) {
    // console.log(data)
    },
    // 接收公共js的值
    demoData: function () {
      Data.$emit('demo', this.demo)
    },
    async ini () {
      var k = { tool_id: this.$route.query.ID, tokenDev: localStorage.getItem('token-Dev'), username: localStorage.getItem('username-Dev') }
      const { data: res1 } = await this.$http.post('user/checkUsername1', k)
      if (res1.code === 'tokenError') {
        localStorage.removeItem('username-Dev');
        localStorage.removeItem('token-Dev');
        // localStorage.clear()
        this.$message({
          type: 'error',
          message: '登录已失效请重新登录。'
        });
        this.$router.push({ path: '/loginDev' })
        return '登录已失效请重新登录。'
      }
      if (res1.code !== 200) {
        window.close()
        return 0
      }
      k = { tool_id: this.$route.query.ID, username: localStorage.getItem('username-Dev'), tokenDev: localStorage.getItem('token-Dev') }
      // const { data: res } = await this.$http.post('dev_select_all', k)
      const { data: res } = await this.$http.post('application/ToolsAttribute', k)
      if (res.code === 'tokenError') {
        localStorage.removeItem('username-Dev');
        localStorage.removeItem('token-Dev');
        // localStorage.clear()
        this.$message({
          type: 'error',
          message: '登录已失效请重新登录。'
        });
        this.$router.push({ path: '/loginDev' })
        return '登录已失效请重新登录。'
      }
      // console.log(res)
      Data.$emit('DevIniData', res)
    },
  },
  props: {},
  components: {
    DataChild,
    Add,
    Interface,
    Attribute
  },
}
</script>

<style lang="less" scoped>
#Dev {
  height: 100%;
  width: 100%;
  background-color: rgba(250, 250, 250);
  #mod1, #mod3 {
    float:left;
    width: 19%;
    background-color: #fff;
    box-shadow: 0.15rem 0.15rem 0.15rem rgba(0,0,0,0.15);
    height: 97%;
    margin: 1% 0;
    border-radius: 1rem;
    box-sizing: border-box;
    border: 0.1rem solid rgba(0,0,0,0.3);
  }
  #mod2{
    float:left;
    width: 60%;
    margin: 1% 1%;
    // background-color: rgba(234,234,234,1);
    background-color: rgba(255,255,255,1);
    box-shadow: 0.15rem 0.15rem 0.15rem rgba(0,0,0,0.15);
    border-radius: 1rem;
    height: 97%;
    box-sizing: border-box;
    border: 0.1rem solid rgba(0,0,0,0.3);
  }
  #mod4 {
    width: 98%;
    height: 99%;
    margin: 0 1%;
    border-radius: 1rem;
    background-color: rgba(0,0,0,0);
  }
}
</style>
