<template>
  <!-- <div :style="{ width: screenWidth+ 'px' , height: screeHeight+ 'px' }" id=appManage> -->
  <div style="width:100%;height:100%" id=appManage>
    <dataChild v-on:childData="childData" :mainName=mainName></dataChild>
    <div id=mod1></div>
    <div id=mod2>
      <div class=mod3 :style="{ width: (screenWidth/36-6.5)/5 + 'rem', 'margin-left': (screenWidth/36-6.5)/25 + 'rem', height: (screenWidth/36-6.5)/5 + 'rem' }">
        <div id=mod3_3 @click="addgad(1, 0)">
          <div class="iconfont icon-del" id=mod3_2></div>
        </div>
      </div>
      <div class="mod3 mod4" v-for="(item, key) in this.toolList" :key="key" :style="{ width: (screenWidth/36-6.5)/5 + 'rem', 'margin-left': (screenWidth/36-6.5)/25 + 'rem', height: (screenWidth/36-6.5)/5 + 'rem' }">
      <!-- <div class="mod3 mod4" v-for="(item, key) in this.toolList" :key="key"> -->
        <img v-lazy="item.img_url" v-if="(item.img_url.substr(0,3) === 'htt')" @click="Refresh(item)">
        <img src="../../assets/404.jpg" v-if="(item.img_url.substr(1,3) === 'Nul')" @click="Refresh(item)">
        <div id=mod3_4>
          <div class=mod3_4_1 style="width:80%;font-size: 0.7rem">{{item.cnName}}</div>
          <div class=mod3_4_1 style="width:20%;float:right;text-align: right;font-size: 0.7rem">{{item.file_type}}</div>
          <div class=mod3_4_1 style="font-size: 0.6rem">{{item.enName}}</div>
          <div class=mod3_4_1 v-if="(item.introduce.length <= 45)" style="font-size: 0.35rem">{{item.introduce}}</div>
          <div class=mod3_4_1 v-if="(item.introduce.length > 45)" style="font-size: 0.35rem">{{item.introduce.substr(0,45) + '...'}}</div>
          <div class=mod3_4_2></div>
          <div id=mod3_5>
            <el-button class="iconfont icon-bianji" title='编辑' @click="addgad(2,key)"></el-button>
            <el-button class="iconfont icon-shujuzuozhanshi" @click="edit(item)" title='前端编辑'></el-button>
            <el-button class="iconfont icon-shujubao" @click="editData(item)" title='后端编辑'></el-button>
            <el-button class="iconfont icon-danxuankuangxuanzhong" @click="Demo(item)" title='调试'></el-button>
            <el-button class="iconfont icon-shangchuan" title='发布/下架' v-if="(item.statu !== 0 && item.statu !== 1 && item.statu !== 2)"></el-button>
            <el-button class="iconfont icon-shangchuan" @click="release(item)" title='发布' v-if="(item.statu === 0)"></el-button>
            <el-button class="iconfont icon-tubiao-duanhengxian" @click="release(item)" title='下架' v-if="(item.statu === 1 || item.statu === 2)"></el-button>
            <el-button class="iconfont icon-shanchu2" @click="DeleteList(item)" title='删除'></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// $emit----v-on子传父
// 非父子组件进行传值公共js
import DataChild from '../Mate/data.vue'
import Data from '../Mate/data.js'
export default {
  inject: ['reload'],
  // 引用this.reload()-----刷新
  data() {
    return {
      screenWidth: document.body.clientWidth,
      screeHeight: document.body.clientHeight,
      mainName: 'appManage',
      toolList: {},
      Url: '',
      addDisplay: 0
    }
  },
  created() {
    // console.log(this.screenWidth, this.screeHeight)
    setTimeout(async() => { 
      this.ini()
    }, 1000)
  },
  mounted: function () {
    // mounted一直监控？可改位置----目前此方法还不能正确运用
    // 用$on事件来接收参数
    var that = this
    Data.$on('demo', (data) => {
      console.log(data)
    })
    Data.$on('addDisplay', (data) => {
      this.addDisplay = data
    })
  },
  methods: {
    // 接收子组件的值
    childData: function (data) {
    // console.log(data)
    },
    // 接收公共js的值
    demoData: function () {
      Data.$emit('demo', this.demo)
    },
    async ini() {
      var k = { username: localStorage.getItem('username-Dev') }
      this.getURL()
      const { data: res } = await this.$http.post('application/devSelectTools', k)
      // console.log(res.toolslist.length)
      if (res.code === 200) {
        var c = []
        var d = 0
        for (var b in res.toolslist) {
          c[d] = res.toolslist[res.toolslist.length - d - 1]
          d = d + 1
          if (res.toolslist.length - d - 1 === 0) break
        }
        this.toolList = c
        if (this.toolList.length === 0) {
          this.toolList = { 0: { cnName: '没有数据', enName: '请创建一个数据', img_url: '/Nul' } }
          return 0
        }
        var i = 0
        var a = []
        // console.log(this.toolList)
        for (var j in this.toolList) {
          this.toolList[i].img_url = this.Url + '/' + this.toolList[i].img_url
          i = i + 1
        }
      } else if (res.code === 500) {
        this.toolList = { 0: { cnName: '没有数据', enName: '请创建一个数据', img_url: '/Nul', introduce: '点击右侧加号创建第一个应用' } }
        return 0
      }
      // console.log(a)
    },
    async getURL() {
      var c = localStorage.getItem('Https')
      if (c === 'No.1') {
        this.Url = DataChild.httpUrl_1
      } else if (c === 'No.2'){
        this.Url = DataChild.httpUrl_2
      } else if (c === 'No.3'){
        this.Url = DataChild.httpUrl_3
      } else if (c === 'No.4'){
        this.Url = DataChild.httpUrl_4
      } else {
        console.clear()
      }
      this.getUrl = this.Url
      this.toolList = { 0: { cnName: '正在获取数据', enName: '', img_url: this.Url + '/application/get_img/404.jpg', introduce: '正在获取数据' } }
      return c
    },
    addgad(data, key) {
      // console.log(data, key)
      if (this.toolList[key].cnName === '正在获取数据') return 0
      Data.$emit('addDisplay', data)
      if (data === 2) {
        Data.$emit('addUrl', this.toolList[key].tool_id)
      }
    },
    async DeleteList(item) {
      this.$confirm('该操作不可还原, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const set = this.$message({
          type: 'success',
          message: '正在删除请稍后!',
          duration: 0,
        });
        const { data: res } = await this.$http.post('application/deleatTool', { tool_id: item.tool_id, tokenDev: localStorage.getItem('token-Dev'), username: localStorage.getItem('username-Dev') }, { emulateJSON: true })
        set.close()
        if (res.code === 'tokenError') {
          localStorage.removeItem('username-Dev');
          localStorage.removeItem('token-Dev');
          // localStorage.clear()
          this.$message({
            type: 'error',
            message: '登录已失效请重新登录。'
          });
          this.$router.push({ path: '/loginDev' })
          return '登录已失效请重新登录。'
        }
        this.reload()
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        })
      });
    },
    edit (item) {
      // console.log(item)
      const a = this.$router.resolve({ path: '/toolUI', query: { ID: item.tool_id } })
      window.open(a.href,'_blank')
    },
    Demo (item) {
      const a = this.$router.resolve({ path: '/Debug', query: { ID: item.tool_id } })
      window.open(a.href,'_blank')
    },
    async release (item) {
      var i = 1
      if (item.statu === 1 || item.statu === 2) {
        i = 0
      }
      var k = { login_name: localStorage.getItem('username-Dev'), examine_msg: [{ tool_id: item.tool_id, statu: i }], tokenDev: localStorage.getItem('token-Dev'), username: localStorage.getItem('username-Dev') }
      const { data: res } = await this.$http.post('application/tool_statu', k, { emulateJSON: true })
      if (res.code === 'tokenError') {
        localStorage.removeItem('username-Dev');
        localStorage.removeItem('token-Dev');
        // localStorage.clear()
        this.$message({
          type: 'error',
          message: '登录已失效请重新登录。'
        });
        this.$router.push({ path: '/loginDev' })
        return '登录已失效请重新登录。'
      }
      this.reload()
    },
    async editData (item) {
    const a = this.$router.resolve({ path: '/DevData', query: { ID: item.tool_id } })
    window.open(a.href,'_blank')
    },
    async Refresh(data) {
      if (typeof(data) !== 'undefined') {
        // const { data: res } = await this.$http.get('get_img/' + data)
        // console.log(res)
      } else {
        this.reload()
      }
    }
  },
  props: {},
  components: {
    DataChild
  },
}
</script>

<style lang="less" scoped>
#appManage {
  #mod1 {
    position: absolute;
  }
  #mod2 {
    width: 100%;
    .mod3 {
      float: left;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      background-color: rgba(0,0,0,0);
      font-size: 0.5rem;
      text-align: center;
      #mod3_3 {
        height: 80%;
        width: 80%;
        margin: 10%;
        border: 1px dotted #000;
        #mod3_2 {
          box-sizing: border-box;
          position: relative;
          top: 50%;
          font-size: 2.5rem;
          margin-top: -1.25rem;
        }
      }
      #mod3_4 {
        height: calc(43.75% - 0.5rem);
        box-sizing: border-box;
        text-align: left;
        border-top: 1px solid rgba(0,0,0,0.12);
        padding: 1.25% 0.25rem 0 0.25rem;
        margin-top: 1.25%;
      }
      .mod3_4_1 {
        height: 25%;
        width: 100%;
        float: left;
      }
      > img {
        border-radius: 0.5rem 0.5rem 0 0;
        width: 100%;
        height: 56.25%;
      }
      #mod3_5 {
        width: 100%;
        .el-button {
          width: calc(100% / 7);
          margin-left: calc(100% / 49);
          margin-top: 0.2rem;
          box-sizing: border-box;
          padding: 0.1rem;
          float: left;
          height: 1rem;
          border-radius: 0.5rem;
          border: none;
          background-color: rgba(0,0,0,0);
        }
        /deep/ .iconfont {
          font-size: 0.7rem;
        }
      }
    }
    .mod4 {
      box-sizing: border-box;
      // padding: 0.5rem;
      background-color: #fff;
      box-shadow: 0rem 0rem 0.3rem rgba(0,0,0,0.14);
      border-radius: 0.5rem;
    }
  }
}
</style>
