<template>
  <div>
    <div v-if='(size === 1)'>
      <!-- 1920宽的尺寸 -->
      <div :style="{ width: screenWidth+ 'px' , height: screeHeight+ 'px' }" id=template>
      <!-- <div style="width:100%,height:100%"> -->
        <dataChild v-on:childData="childData" :mainName=mainName></dataChild>
        <img src="../../assets/404.jpg" alt="" style="width:100%;height:auto">
      </div>
    </div>
    <div v-if='(size === 0)'>
      <!-- rem的自适应尺寸 -->
      <div id="modPersonalDisk" v-if="(this.displayImage === 1)">
        <div id="modPersonalDisk_001">云盘上传</div>
        <!-- 悬浮栏模板 -->
        <div  id=modB_7 v-if="(this.dirDis === 1)">
          <div id=modB_7_1>
            <div style="height: 20px"></div>
            <div  id=modB_7_2>
              <el-button type="success" @click="appDir(0, 11)">确定</el-button>
              <el-button type="danger" @click="appDir(0, 12)">关闭</el-button>
              <div id=modB_7_2_1>{{this.uploadText}}</div>
              <div>{{this.appName}}</div>
            </div>
            <div  id=modB_7_3>
              <div class=modB_7_5 style="width: 10px;height: 1px;"> </div>
              <div class=modB_7_5 @click="appDir(0,6)">
                <img src="../../assets/个人中心素材/左.png" alt="">
              </div>
              <div class=modB_7_5 @click="appDir(0,7)">
                <img src="../../assets/个人中心素材/右.png" alt="">
              </div>
              <div class=modB_7_5>
                <img src="../../assets/个人中心素材/隔断.png" alt="">
              </div>
              <div class=modB_7_5 style="width: 300px;text-align: left;">文件：{{this.focusing}}</div>
              <div class=modB_7_5>
                <img src="../../assets/个人中心素材/隔断.png" alt="">
              </div>
              <div class=modB_7_5 style="width: 700px;text-align: left;">路径：{{this.appPath[this.dirNum]}}</div>
            </div>
            <div  id=modB_7_4>
              <el-scrollbar style="width: 100%;height: 100%;">
                <div class=modB_7_6 v-for="i in this.dirList[this.dirNum]" :key="i.ID">
                  <div style="height: 87px;" >
                    <!-- 文件夹 -->
                    <img src="../../assets/个人中心素材/文件夹.png" alt="" @dblclick="appDir(i,5)" @click="appDir(i,13)" v-if="(i.dir === 1)">
                    <!-- 一般文件 -->
                    <img src="../../assets/个人中心素材/一般文件.png" alt="" @click="appDir(i,9)" v-if="(i.dir !== 1 & (i.type !== 'zip' & i.type !== 'rds'))">
                    <!-- 压缩包 -->
                    <img src="../../assets/个人中心素材/zip.png" alt="" @click="appDir(i,10)" v-if="(i.dir !== 1 & (i.type === 'zip' | i.type === 'rds'))">
                  </div>
                  <div style="height: 51px;" id=modB_7_4_1>{{i.name}}</div>
                </div>
                <!-- 暂无文件 -->
                <div class=modB_7_6>
                  <div style="height: 87px;" v-if="(dirList[dirNum].length === 0)" @dblclick="appDir(0,6)">
                    <img src="../../assets/个人中心素材/一般文件.png" alt="">
                  </div>
                  <div style="height: 51px;" v-if="(dirList[dirNum].length === 0)">暂无文件</div>
                </div>
              </el-scrollbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// $emit----v-on子传父
// 非父子组件进行传值公共js
import DataChild from '../Mate/data.vue'
import Data from '../Mate/data.js'
export default {
  // 引用this.getAppUrl()-----切换内外网
  // 引用this.reload()-----刷新
  // window.location.reload() -------刷新整个页面
  inject: ['reload', 'getAppUrl'],
  // 页面标签设置(在模板里面修改文件名【package.json文件修改后重启可设置主标签】)
  metaInfo: {
    title: '个人文件夹',
    titleTemplate: 'BioinforCloud - %s',
    htmlAttrs: {
      lang: 'ch',
      amp: true
    }
  },
  data() {
    return {
      screenWidth: document.body.clientWidth,
      screeHeight: document.body.clientHeight,
      mainName: 'Template',
      size: 0,
      forum1: [],
      forum2: [],
      forum3: [],
      displayImage: 0,
      // 文件夹参数
      dirList: [],
      // 当前层数
      dirNum: 0,
      dirDis: 0,
      Url: '',
      username: '',
      // 当前路径
      appPath: '',
      uploadText: '',
      uploadpercent: '',
      uploadfilename: '',
      // 打开的应用
      appName: '',
      // 聚焦
      focusing: '',
      // 当前点击的是不是文件夹
      dirState: 0,
      // 最后打开的文件夹提交的信息
      dirData1: '',
      // 点击文件夹顺序
      dirData2: [],
      // 要上传的文件夹信息
      forPathInfo: {},
    }
  },
  created() {
    this.sizeData()
    this.ini()
    this.FolderPreview()
    setTimeout(async() => { 
      this.getURL()
    }, 1000)
  },
  mounted: function () {
    // mounted一直监控？可改位置----目前此方法还不能正确运用
    // 用$on事件来接收参数
    var that = this
    Data.$on('demo', (data) => {
      console.log(data)
    })
    Data.$on('Disk3', (data) => {
      this.forPathInfo = data
    })
  },
  methods: {
    childData: function (data) {
      // 接收子组件的值
      // console.log(data)
    },
    demoData: function () {
      // 接收公共js的值
      Data.$emit('demo', this.demo)
    },
    sizeData() {
      // 默认设置
      this.size = 0
      // 大小设定
      // if (this.screenWidth >= 1920) this.size = 1
    },
    async ini() {
      this.username = localStorage.getItem('username')
      var k = { username:localStorage.getItem('username'), token: localStorage.getItem('token') }
      const { data: res } = await this.$http.post('application/getForum3', k)
      // 0 待审核
      this.forum1 = res.result1
      // 1 已发布
      // 1 已发布的最新评论时间
      // 之后修改为点开帖子刷新时间，获取new在打开发帖管理和个人中心两次
      // 前后端都改
      var time1 = ''
      var time2 = ''
      var a1 = ''
      var a2 = ''
      var a3 = ['月', '日', '时', '分', '秒']
      this.forum2 = res.result2
      for (var i in res.result2) {
        this.forum2[i]['New'] = ''
        a1 = res.result2[i].newComment.split('年')
        a2 = res.result2[i].accessTime.split('年')
        time1 = a1[0]
        time2 = a2[0]
        for (var j in a3) {
          a1 = a1[1].split(a3[j])
          if (a1[0] < 10) {
            time1 = time1 + '0' + a1[0]
          } else {
            time1 = time1 + a1[0]
          }
          a2 = a2[1].split(a3[j])
          if (a2[0] < 10) {
            time2 = time2 + '0' + a2[0]
          } else {
            time2 = time2 + a2[0]
          }
        }
        if (time1 < time2) {
          this.forum2[i]['New'] = 'New'
        }
      }
      // 2 被驳回
      this.forum3 = res.result3
    },
    async FolderPreview() {
      var k = { 'username': localStorage.getItem('username') , 'toolDir': 'App' }
      const { data: res1 } = await this.$http.post('application/appInfor', k)
      this.uploadpercent = ''
      this.uploadText = ''
      this.uploadfilename = ''
      this.dirNum = 0
      this.dirList[0] = []
      var b = { username: localStorage.getItem('username'), token: localStorage.getItem('token') }
      const { data: res } = await this.$http.post('application/AppDir2', b)
      if (res.code == 200) {
        var j = 0
        var screenList = ['hadoopLogs.txt']
        for (var i in res.filelist) {
          if (screenList.indexOf(res.filelist[i].name) > -1) {
          } else {
            for (var l in res1) {
              if (res1[l] === undefined) continue
              if (res1[l].state === undefined) break
              var DataName = res1[l].appName + '_' + res1[l].appID
              if (res.filelist[i].name === DataName) {
                res.filelist[i].state = res1[l].state
                break
              }
            }
            var fileType = ''
            if (res.filelist[i].dir === 1) {
              fileType = '文件夹'
            } else {
              for (var k in res.filelist[i].name.split('.')) {
                fileType = res.filelist[i].name.split('.')[k]
              }
            }
            this.dirList[this.dirNum][j] = {'ID': j, 'dir': res.filelist[i].dir, 'name': res.filelist[i].name, 'path': res.filelist[i].path, 'filelist': res.filelist[i].filelist, 'type': fileType, 'state': res.filelist[i].state}
            j = j + 1
          }
        }
        this.appPath = []
        this.appPath[this.dirNum] = '/'
        this.fileSort()
        this.$message.closeAll()
        this.dirDis = 1
      } else if (res.code == 404) {
        this.$message.closeAll()
        this.$message({
          type: 'error',
          message: res.msg,
          duration: 3000,
        });
      }
      this.displayImage = 1
      this.dirDis = 1
    },
    upload () {
    },
    successDisplay (response, file, fileList) {
      this.$message.closeAll()
      // this.uploadilename = response.filename
      if (response.code === 200) {
        //模拟重新打开，待做
        this.reload()
      }
    },
    onUploadProgress(event, file, fileList) {
      this.$message.closeAll()
      this.uploadpercent = event.percent.toFixed(1)
      this.uploadText = '正在上传:' + file.name + '，上传进度: ' + this.uploadpercent + '%'
    },
    uploadError (err, file, fileList) {
      this.$message.closeAll()
      if (this.appPath[this.dirNum] === '/') {
        this.$message({
          type: 'error',
          message: '该目录不可上传',
          duration: 3000,
        });
      } else {
        this.$message({
          type: 'error',
          message: '上传失败',
          duration: 3000,
        });
      }
      // console.clear()
    },
    async appDir(data, mod) {
      // console.log(mod)
      if (mod === 0){
      } else if(mod === 5) {
        // 点击文件夹
        var screenList = []
        if (data.state === '等待压缩' | data.state === '运行完成') {
          this.resultTrue = 1
          screenList = ['hadoopLogs.txt', 'Activate']
        } else if (data.state === '运行中' | data.state === '未知'){
          this.resultTrue = 0
          screenList = ['hadoopLogs.txt', 'Activate', 'Result', 'Download']
        }
        this.dirData2[this.dirNum] = data
        if (data.name === 'Result') {
          if (this.resultTrue === 0) {
            this.$message.closeAll()
            this.$message({
              type: 'success',
              message: '应用还未运行完成',
              duration: 5000,
            });
            return 0
          }
        }
        var j = 0
        this.dirNum = this.dirNum + 1
        this.dirList[this.dirNum] = []
        this.appPath[this.dirNum] = data.path
        for (var i in data.filelist) {
          var fileType = ''
          if (data.filelist[i].dir === 1) {
            fileType = '文件夹'
            if (screenList.indexOf(data.filelist[i].name) > -1) continue
          } else {
            for (var k in data.filelist[i].name.split('.')) {
              fileType = data.filelist[i].name.split('.')[k]
            }
          }
          this.dirList[this.dirNum][j] = {'ID': j, 'dir': data.filelist[i].dir, 'name': data.filelist[i].name, 'path': data.filelist[i].path, 'filelist': data.filelist[i].filelist, 'type': fileType}
          j = j + 1
          this.fileSort()
        }
      } else if(mod === 6) {
        // 左
        if (this.dirList[this.dirNum - 1] === undefined) {
        } else {
          this.dirNum = this.dirNum - 1
        }
        this.focusing = ''
      } else if(mod === 7) {
        // 右
        if (this.dirList[this.dirNum + 1] === undefined) {
        } else {
          this.dirNum = this.dirNum + 1
        }
        this.focusing = ''
      } else if(mod === 8) {
        
      } else if(mod === 9) {
        // 一般文件
        this.dirState = 0
        this.focusing = data.name
      } else if(mod === 10) {
        // 压缩包
        this.dirState = 0
        this.focusing = data.name
      } else if(mod === 11) {
        var a = this.appPath[this.dirNum]
        var b = a.split('/')
        var c = this.focusing
        var e = ['Result', 'TempData', 'Download']
        if (e.indexOf(this.focusing) > -1) {
          this.$message.closeAll()
          return this.$message({
            type: 'error',
            message: '该文件夹不可上传',
            duration: 3000,
          });
        }
        if (b[b.length - 1] === c || c === '' || a === '/') {
          this.$message.closeAll()
          this.$message({
            type: 'error',
            message: '请选择文件',
            duration: 3000,
          });
        } else {
          var e = ['多文件上传', '文件夹上传']
          if (e.indexOf(this.forPathInfo.item) > -1) {
            var d = {'path': a + '/' + c, 'disk': a, 'name': c, 'dir': this.dirState, 'file': 1, 'forPathInfo': this.forPathInfo['data'], 'key': this.forPathInfo['num']}
          } else {
            var d = {'path': a + '/' + c, 'disk': a, 'name': c, 'dir': this.dirState, 'file': 0, 'forPathInfo': this.forPathInfo['data'], 'key': this.forPathInfo['num']}
          }
          if (d.dir !== d.file) {
            this.$message.closeAll()
            return this.$message({
              type: 'error',
              message: '上传的文件类型错误',
              duration: 0,
            });
          }
          Data.$emit('Disk2', d)
        }
      } else if(mod === 12) {
        // 关闭
        Data.$emit('Disk1', 1)
      } else if(mod === 13) {
        // 单击文件夹
        this.dirState = 1
        this.focusing = data.name
      } else {
        this.$message.closeAll()
        this.$message({
          type: 'error',
          message: '类型错误',
          duration: 3000,
        });
      }
    },
    fileSort() {
      var b = 0
      // 按a-z排序 排序还要修改，考虑在前端还是后端排序
      // var b = 0
      // for (var a in this.dirList[this.dirNum]) {
      //   b = 0
      //   while(b < this.dirList[this.dirNum].length - 1) {
      //     if (this.dirList[this.dirNum][b].name > this.dirList[this.dirNum][b + 1].name) {
      //       var t = this.dirList[this.dirNum][b]
      //       this.dirList[this.dirNum][b] = this.dirList[this.dirNum][b + 1]
      //       this.dirList[this.dirNum][b + 1] = t
      //     }
      //     b = b + 1
      //   }
      // }
      // 按照文件夹排序
      for (var a in this.dirList[this.dirNum]) {
        b = 0
        while(b < this.dirList[this.dirNum].length - 1) {
          if (this.dirList[this.dirNum][b].dir < this.dirList[this.dirNum][b + 1].dir) {
            var t = this.dirList[this.dirNum][b]
            this.dirList[this.dirNum][b] = this.dirList[this.dirNum][b + 1]
            this.dirList[this.dirNum][b + 1] = t
          }
          b = b + 1
        }
      }
    },
    async getURL() {
      var c = localStorage.getItem('Https')
      if (c === 'No.1') {
        this.Url = DataChild.httpUrl_1
      } else if (c === 'No.2'){
        this.Url = DataChild.httpUrl_2
      } else if (c === 'No.3'){
        this.Url = DataChild.httpUrl_3
      } else if (c === 'No.4'){
        this.Url = DataChild.httpUrl_4
      } else {
        console.clear()
      }
      this.getUrl = this.Url
      return c
    },
  },
  props: {},
  components: {
    DataChild
  },
}
</script>

<style lang="less" scoped>
#modPersonalDisk {
  width: 1352px;
  height: calc(55px + 55px * 10 + 36px);
  margin: auto;
  margin-top: 100px;
  background-color: #fff;
  #modPersonalDisk_001 {
    font-size: 36px;
    text-align: center;
    margin-top: 10px;
  }
  #modB_7{
    width: calc(100% - 180px);
    height: 100%;
    #modB_7_1{
      width: 1352px;
      height: calc(100% - 27px - 27px - 41px);
      background-color: #fff;
      top: calc((100% - (100% - 27px - 27px - 41px))/2);
      border-radius: 10px;
    }
    #modB_7_2{
      margin: 0px 31px;
      .el-button, .upload-demo {
        float: left;
        width: 92px;
        height: 36px;
        border-radius: 18px;
      }
      .el-button {
        margin-left: 15px;
      }
      > div {
        float: right;
        line-height: 36px;
        font-size: 18px
      }
      .upload-demo {
        background-color: #2fa8e1;
        font-size: 14px;
        color: #fff;
      }
      .upload-demo:active, .upload-demo:hover {
        background-color: #2fa8e1;
        color: #fff;
      }
      #modB_7_2_1 {
        float: left;
        margin-left: 50px;
      }
    }
    #modB_7_3 {
      height: 42px;
      line-height: 42px;
      width: 100%;
      float: left;
      margin: 10px 31px;
      width: calc(100% - 62px);
      border-radius: 5px;
      border: 1px rgba(222,222,222,0.5) solid;
      > div>img {
        height: 18px;
        margin: 0 5px;
        margin-top: 12px
      }
    }
    .modB_7_5 {
      font-size: 18px;
      float: left;
      margin: 0 10px;
      white-space: nowrap;
      /* 2.超出的部分隐藏 */
      overflow: hidden;
      /* 3.文字用省略号替代超出的部分 */
      text-overflow: ellipsis;
    }
    #modB_7_4{
      float: left;
      width: calc(100% - 62px);
      margin: 10px 31px;
      height: calc(100% - 36px - 42px - 20px - 40px - 20px);
    }
    .modB_7_6 {
      height: 138px;
      width: 168px;
      float:left;
      margin: 24px 18px;
      > div>img {
        width:96px;
        height:87px;
        margin: 0 36px;
      }
      > div {
        font-size: 16px;
        line-height: calc(30px + 8px)
      }
    }
    #modB_7_4_1 {
      // white-space: nowrap;
      // overflow: hidden;
      // 自动换行
      word-break: break-all;
      text-align: center;
    }
  }
}
</style>
