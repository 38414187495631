<template>
  <div id=scrollbar>
    <el-scrollbar ref="myScrollbar">
      <!-- 1920宽的尺寸 -->
      <div v-if='(attribute.size === 1)' id=mod1>
        <div :style="{ width: screenWidth+ 'px' , height: screeHeight+ 'px' }" id=Home>
          <dataChild v-on:childData="childData" :mainName=mainName></dataChild>
          <!-- 登录页面 -->
          <div id=login_page v-if="(attribute.login !== 0) & (attribute.login === 1)">
            <div id=login_page_1 v-if="(attribute.login === 1)" @keydown.enter="login(1)">
              <!-- 表头图片 -->
              <div class="avatar_box">
                <img src="../../assets/logo.png" alt />
              </div>
              <!-- 登录 -->
              <el-form
                ref="loginFormRef"
                :model="loginForm"
                :rules="loginFormRules"
                label-width="0px"
                class="login_form"
              >
                <!-- 用户名 -->
                <el-form-item prop="username" class=login_form_1>
                  <div class=login_form_2>账号：</div>
                  <el-input v-model="loginForm.username" prefix-icon="iconfont icon-icon-test2"></el-input>
                </el-form-item>
                <!-- 密码 -->
                <el-form-item prop="password" class=login_form_1>
                  <div class=login_form_2>密码：</div>
                  <el-input v-model="loginForm.password" prefix-icon="iconfont icon-icon-test1" type="password"></el-input>
                </el-form-item>
                <!-- 按钮 -->
                <el-form-item class="btns">
                  <el-button type="primary" @click="login(1)">登录</el-button>
                  <el-button type="primary" @click="user(0)">取消</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div id=login_page_2 v-if="(attribute.login === 2)">345</div>
          </div>
          <!-- 注册页面 -->
          <div id=login_page_2 v-if="(attribute.login !== 0) & (attribute.login === 2)">
            <div id=login_page_1 v-if="(attribute.login === 2)" @keydown.enter="login(2)">
              <!-- 表头图片 -->
              <div class="avatar_box">
                <img src="../../assets/logo.png" alt />
              </div>
              <!-- 登录 -->
              <el-form
                ref="loginFormRef_2"
                :model="loginForm_2"
                :rules="loginFormRules_2"
                label-width="0px"
                class="login_form"
              >
                <!-- 名称 -->
                <el-form-item prop="name" class=login_form_1>
                  <div class=login_form_2>名称：</div>
                  <el-input v-model="loginForm_2.name" prefix-icon="iconfont icon-icon-test2"></el-input>
                </el-form-item>
                <!-- 用户名 -->
                <el-form-item prop="username" class=login_form_1>
                  <div class=login_form_2>账号：</div>
                  <el-input v-model="loginForm_2.username" prefix-icon="iconfont icon-icon-test2"></el-input>
                </el-form-item>
                <!-- 密码 -->
                <el-form-item prop="password" class=login_form_1>
                  <div class=login_form_2>密码：</div>
                  <el-input v-model="loginForm_2.password" prefix-icon="iconfont icon-icon-test1"></el-input>
                </el-form-item>
                <!-- 邮箱 -->
                <el-form-item prop="email" class=login_form_1>
                  <div class=login_form_2>邮箱：</div>
                  <el-input v-model="loginForm_2.email" prefix-icon="iconfont icon-icon-test1"></el-input>
                </el-form-item>
                <!-- 手机号 -->
                <el-form-item prop="phone" class=login_form_1>
                  <div class=login_form_2>手机号：</div>
                  <el-input v-model="loginForm_2.phone" prefix-icon="iconfont icon-icon-test1"></el-input>
                </el-form-item>
                <!-- 按钮 -->
                <el-form-item class="btns">
                  <el-button type="primary" @click="login(2)">注册</el-button>
                  <el-button type="primary" @click="user(0)">取消</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <!-- 头部 -->
          <div id=top>
            <div id=top1><img src="../../assets/logo3.png" alt="" style="width:auto;height:100%"></div>
            <div id=top2>
              <el-menu el-menuid="right_1" mode="horizontal" @select="handleSelect"  :default-active="attribute.activeIndex" :router=false >
                <el-menu-item index="/BioinforCloud" class="menulist">首页</el-menu-item>
                <el-menu-item index="/AppSquare" class="menulist">应用广场</el-menu-item>
                <el-menu-item index="/Procedure" class="menulist">生信流程</el-menu-item>
                <el-menu-item index="#3" class="menulist">视频课程</el-menu-item>
                <el-menu-item index="#4" class="menulist">学术成果</el-menu-item>
                <el-menu-item index="#5" class="menulist">佰茵笔记</el-menu-item>
                <el-menu-item index="佰茵论坛" class="menulist">佰茵论坛</el-menu-item>
                <el-menu-item index="#7" class="menulist">联系我们</el-menu-item>
              </el-menu>
            </div>
            <div id=top3>
              <!-- 登录 -->
              <div id=login v-if="(attribute.name === '')">
                <!-- <div  id=login4>欢迎访问佰茵云</div> -->
                <!-- <div id=login7>|</div> -->
                <div id=login3 @click=user(2)>免费注册</div>
                <div id=login9>|</div>
                <div id=login1 @click=user(1)>请登录</div>
              </div>
              <div v-if="(attribute.name !== '')" id=login8 @click=personal()>{{attribute.name}}</div>
              <img v-if="(attribute.name !== '')" src="../../assets/404.jpg" alt="">
              <div id=login6 @click=change() v-if="(attribute.name !== '')" style="cursor: pointer;">{{this.urlPath}}</div>
              <div id=login6 v-if="(attribute.name !== '')" style="margin:0 5px">|</div>
              <div id=login6 @click=user(0) v-if="(attribute.name !== '')" style="cursor: pointer;">退出</div>
            </div>
          </div>
          <div v-if="(this.mod === '主页' & this.forumDis === undefined)">
            <!-- slider -->
            <div id=slider>
              <div id=slider_1>
                <img src="../../assets/slider-1.png" alt="" >
              </div>
              <div id=slider_2>
                <div id=slider_2_1>融合基因型和表型进行病理诊断</div>
                <div id=slider_2_2></div>
                <div class=slider_2_3>
                  肿瘤演化随着基因变异的积累，会在细胞和组织层面产生各种表型变化
                </div>
                <div class=slider_2_3>
                  靶向测序可检测出重要基因的基因型信息，病理染色图像 则是直接反映肿瘤组织和细胞形态的重要表型组数据
                </div>
                <div class=slider_2_3>
                  病理医生目前融合基因型(靶向测序)和表型(染色图像) 数据得到的定性病理诊断结果，是目前临床诊断的“金标准”
                </div>
              </div>
            </div>
            <!-- main 924 -->
            <div id=main>
              <div id=main1>研发中心</div>
              <div id=main2>
                <div class=main2_1 @mouseenter="devMain(1)" @mouseleave="devMain(0)">
                  <img src="../../assets/参赛者中心.png" alt="" style="width:345px">
                  <div class=main2_2 v-if="(attribute.devMainChoice === 1)" style="color:#666">参赛者中心</div>
                </div>
                <div class=main2_1 @mouseenter="devMain(2)" @mouseleave="devMain(0)">
                  <img src="../../assets/创作者中心背景图.png" alt="" style="width:460px">
                  <div class=main2_2 v-if="(attribute.devMainChoice === 2)" style="color:#eee">创作者中心</div>
                </div>
                <div class=main2_1 @mouseenter="devMain(3)" @mouseleave="devMain(0)" @click="toLoginDev()">
                  <img src="../../assets/开发者中心背景图.png" alt="" style="width:345px">
                  <div class=main2_2 v-if="(attribute.devMainChoice === 3)" style="color:#fff">开发者中心</div>
                </div>
              </div>
              <div id=main3>关于佰茵云</div>
              <div class=main4>佰茵云，一个自主研发的生物信息分析平台，致力于为生命科学服务。</div>
              <div class=main4>本平台集数据下载、分析和结果可视化于一体，汇集了多种数据处理功能及生物信息学分析方法，</div>
              <div class=main4>包括单细胞聚类分析、拟时序分析、拷贝数估算、细胞间通讯分析及RNA速率计算等，</div>
              <div class=main4>涉及空间组学、单细胞组学、转录组、基因组、蛋白组及代谢组等多个组学。</div>
              <div class=main4>此外，本平台可根据用户需求，提供量身定制的分析方案，简化繁琐的软件安装、使用等步骤，致力于帮助科研人员进行个性化研究。</div>
              <div id=main5>
                <div id=main5_1>Consultation</div>
                <div id=main5_2>
                  <div id=main5_3></div>
                </div>
              </div>
            </div>
            <!-- footer 279px-->
            <div id=footer>
              <div id=footer0>
                <div id=footer1>
                  <img src="../../assets/logo2.png" alt="">
                </div>
                <div id=footer2>
                  <div id=footer2_1>
                    <div class="iconfont icon-phone2"></div>
                    <div class=footer2_4>
                      <div>电话</div>
                      <div>19899493993</div>
                    </div>
                  </div>
                  <div id=footer2_2>
                    <div class="iconfont icon-dizhi"></div>
                    <div class=footer2_4>
                      <div>地址</div>
                      <div style="line-height:24px">广西壮族自治区南宁市</div>
                      <div style="line-height:24px">青秀区教育路17号</div>
                    </div>
                  </div>
                  <div id=footer2_3>
                    <div class="iconfont icon-zhifeijifuzhi"></div>
                    <div class=footer2_4>
                      <div>邮箱</div>
                      <div>cyx@ydlife.org</div>
                    </div>
                  </div>
                </div>
                <div id=footer3>
                  <img src="../../assets/二维码.png" alt="">
                </div>
              </div>
              <div id=footer4>
                <div>
                  <a href="https://beian.miit.gov.cn">{{DataWeb2}}</a>
                  <span><br/>南宁源动生命医学研发有限公司</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="(this.mod === '个人中心' & this.forumDis === undefined)" id=web1>
            <personal-center></personal-center>
          </div>
          <div v-if="(this.mod === '佰茵论坛' | this.forumDis !== undefined)" id=web2>
            <Forum1 v-if="(this.forumDis === undefined)"></Forum1>
            <Forum2 v-if="(this.forumDis !== undefined)"></Forum2>
          </div>
        </div>
      </div>
      <!-- rem的自适应尺寸 -->
      <div v-if='(attribute.size === 0)' id=mod2>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
// $emit----v-on子传父
// 非父子组件进行传值公共js
import DataChild from '../Mate/data.vue'
import Data from '../Mate/data.js'
import {machineId, machineIdSync} from 'node-machine-id'
import PersonalCenter from '../个人中心/个人中心主页.vue'
import Forum1 from '../论坛/论坛首页.vue'
import Forum2 from '../论坛/论坛详情页.vue'
export default {
  // 引用this.getAppUrl()-----切换内外网
  // 引用this.reload()-----刷新
  // window.location.reload() -------刷新整个页面
  inject: ['reload', 'getAppUrl', 'getAppUrl_1'],
  // 页面标签设置
  metaInfo: {
    title: '首页',
    titleTemplate: '佰茵云 - %s',
    htmlAttrs: {
      lang: 'ch',
      amp: true
    }
  },
  data() {
    return {
      screenWidth: document.body.clientWidth,
      screeHeight: document.body.clientHeight,
      mainName: 'Home',
      // 登录表单数据位置
      loginForm: { username: '', password: ''},
      loginFormRules: { username: [{ required: true, message: '请输入用户名', trigger: 'blur' }], password: [{ required: true, message: '请输入密码', trigger: 'blur' }] },
      // 注册表单数据位置
      loginForm_2: { name: '', username: '', password: '', email: '', phone: '' },
      loginFormRules_2: { name: [{ required: true, message: '请输入名称', trigger: 'blur' }], username: [{ required: true, message: '请输入用户名', trigger: 'blur' }], password: [{ required: true, message: '请输入密码', trigger: 'blur' }], email: [{ required: true, message: '请输入邮箱', trigger: 'blur' }], phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }] },
      attribute: { size: 0, activeIndex: '/BioinforCloud', name: '', devMainChoice: 0, login: 0 },
      current: '',
      mod: '主页',
      urlPath: '获取中',
      // 论坛详情页
      forumDis: '',
      DataWeb2: DataChild.DataWeb2,
    }
  },
  created() {
    this.forumDis = this.$route.query.forumID
    setTimeout(async() => { 
      this.sizeData()
      this.helloUser()
      this.getIP()
    }, 1000)
  },
  mounted: function () {
    // mounted一直监控？可改位置----目前此方法还不能正确运用
    // 用$on事件来接收参数
    var that = this
    Data.$on('demo', (data) => {
      console.log(data)
    })
    Data.$on('Forum5', (data) => {
      this.$refs.myScrollbar.wrap.scrollTop = 0
    })
  },
  methods: {
    childData: function (data) {
      // 接收子组件的值
      // console.log(data)
    },
    demoData: function () {
      // 接收公共js的值
      Data.$emit('demo', this.demo)
    },
    // 大小设定
    sizeData() {
      // 先取消自适应
      // if (this.screenWidth >= 1920) this.attribute.size = 1、
      this.attribute.size = 1
    },
    // 欢迎用户
    helloUser() {
      // console.log(localStorage.getItem('username'), localStorage.getItem('username') === null)
      if (localStorage.getItem('username') !== null) {
        this.attribute.name = localStorage.getItem('name')
      } else {
        this.attribute.name = ''
      }
      if (localStorage.getItem('mainWeb') === null) {
        this.mod = '主页'
      } else {
        this.mod = localStorage.getItem('mainWeb')
      }
    },
    // 获取IP等功能
    async getIP () {
      // 提示异常
      var ip=localStorage.getItem('IP')
      if (ip === '0.0.0.0') {
        this.$message.closeAll()
        const set = this.$message({
          type: 'success',
          message: 'IP异常已自动切换外网访问',
          duration: 5000,
        });
      }
      this.get_url()
    },
    user(data) {
      if (data === 0) {
        this.reload()
        window.location.reload()
        localStorage.clear()
        window.sessionStorage.clear()
        this.$router.push({ path: '/BioinforCloud' }).catch(error => error)
      }
      if (data === 1) {
        this.attribute.login = data
      }
      if (data === 2) {
        this.attribute.login = data
      }
    },
    get_url() {
      var a = localStorage.getItem('Https')
      if (a === 'No.1' || a === 'No.2' || a === 'No.3' || a === 'No.4') {
        a = a
      } else {
        a = 'No.5'
      }
      if (a === 'No.1' || a === 'No.2') {
        this.urlPath = '内网'
      } else if (a === 'No.3' || a === 'No.4') {
        this.urlPath = '外网'
      } else {
        this.urlPath = '异常'
      }
    },
    login(data) {
      // 登录
      if (data === 1) {
        this.$refs.loginFormRef.validate(async (valid) => {
          if (!valid) return
          var ip = localStorage.getItem('IP')
          var k = { username: this.loginForm.username, password: this.loginForm.password, ip: ip }
          this.$message.closeAll()
          const set = this.$message({
            type: 'success',
            message: '正在验证账号，请稍后',
            duration: 10000,
          });
          const { data: res } = await this.$http.post('user/login', k)
          if (res.code !== '200') {
            this.$message.closeAll()
            return this.$message.error('密码错误或账号不存在')
          } else if (res.code === '200'){
            this.get_url()
          }
          this.$message.closeAll()
          this.$message.success('登录成功')
          localStorage.setItem('username',res.username)
          localStorage.setItem('name',res.name)
          localStorage.setItem('token',res.token)
          // this.$router.push({ path: '/BioinforCloud', query: { name: res.username, username: res.name } })
          var url3 = DataChild.httpUrl_3
          var url4 = DataChild.httpUrl_4
          this.getAppUrl_1(url3, 'No.1', 'No.3')
          this.getAppUrl_1(url4, 'No.2', 'No.4')
          this.reload()
        })
      }
      // 注册
      if (data === 2) {
        this.$refs.loginFormRef_2.validate(async (valid) => {
          if (!valid) return
          var k = { name:this.loginForm_2.name, username: this.loginForm_2.username, password: this.loginForm_2.password, email:this.loginForm_2.email, phone:this.loginForm_2.phone }
          // 不用这个加密了
          // k.password = this.strToBinary(k.password)
          const { data: res } = await this.$http.post('user/register', k)
          if (res.code === 200) {
            this.$message.success('注册信息申请成功(待审核)')
            this.reload()
          }
        })
      }
    },
    toLoginDev() {
      this.$router.push({ path: '/loginDev' })
    },
    devMain(data) {
      this.attribute.devMainChoice = data
    },
    handleSelect(key, keyPath) {
      this.forumDis = undefined
      if (key === '/BioinforCloud') {
        this.mod = '主页'
        localStorage.setItem('mainWeb', '主页')
      } else if (key === '佰茵论坛') {
        this.mod = '佰茵论坛'
        return localStorage.setItem('mainWeb', '佰茵论坛')
      }
      // console.log(key, this.current)
      if (key === this.current) return 0
      this.current = key
      // this.$router.push({ path: '/Model', query: { username: this.name } })
      if (localStorage.getItem('username') === null) {
        if (key === '/BioinforCloud') return this.$router.push({ path: key }).catch(error => error)
        this.$message.closeAll()
        this.$message.error('请登录')
        this.reload()
        // if (key === '/AppSquare') return this.$router.push({ path: key }).catch(error => error)
        return 0
      } else if (localStorage.getItem('username') !== null) {
        if (key === '/BioinforCloud') return this.$router.push({ path: key }).catch(error => error)
        if (key === '/AppSquare') return this.$router.push({ path: key }).catch(error => error)
        if (key === '/Procedure') return this.$router.push({ path: key }).catch(error => error)
        // 测试页面
        if (key === '#7') return this.$router.push({ path: '/Test2' }).catch(error => error)
        if (key === '#3' || key === '#4' || key === '#5' || key === '#6' || key === '#7') return this.$router.push({ path: '/BioinforCloud' }).catch(error => error)
        return 0
      }
    },
    personal () {
      this.$router.push({ path: '/BioinforCloud' })
      this.forumDis = undefined
      this.mod = '个人中心'
      localStorage.setItem('mainWeb', '个人中心')
      // return this.$router.push({ path: 'Personal' }).catch(error => error)
    },
    change() {
      // 获取中, 异常，内网，外网
      // console.log(localStorage.getItem('Https'), this.urlPath)
      if (localStorage.getItem('Https') === null) {
        this.urlPath = '异常'
        localStorage.setItem('Https','NO.5')
      }
      this.$confirm('将重新获取网络情况', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success'
      }).then(async () => {
        this.$message.closeAll()
        const set = this.$message({
          type: 'success',
          message: '正在获取，请稍后!',
          duration: 0,
        });
        if (this.urlPath === '异常') {
          var url1 = DataChild.httpUrl_1
          var url2 = DataChild.httpUrl_2
          var url3 = DataChild.httpUrl_3
          var url4 = DataChild.httpUrl_4
          this.getAppUrl_1(url3, 'No.1', 'No.3')
          this.getAppUrl_1(url4, 'No.2', 'No.4')
        } else if (this.urlPath === '外网') {
          var url1 = DataChild.httpUrl_1
          var url2 = DataChild.httpUrl_2
          var url3 = DataChild.httpUrl_3
          var url4 = DataChild.httpUrl_4
          this.getAppUrl_1(url3, 'No.1', 'No.3')
          this.getAppUrl_1(url4, 'No.2', 'No.4')
        } else if (this.urlPath === '内网') {
          var url1 = DataChild.httpUrl_1
          var url2 = DataChild.httpUrl_2
          var url3 = DataChild.httpUrl_3
          var url4 = DataChild.httpUrl_4
          this.getAppUrl_1(url3, 'No.1', 'No.3')
          this.getAppUrl_1(url4, 'No.2', 'No.4')
        }
      }).catch(() => {
        this.$message.closeAll()
        this.$message({
          type: 'info',
          message: '已取消操作',
          duration: 3000,
        })
      });
    },
  },
  props: {},
  components: {
    DataChild,
    PersonalCenter,
    Forum1,
    Forum2,
  },
}
</script>

<style lang="less" scoped>
// 滑动条属性
#scrollbar {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  /deep/ .el-scrollbar__wrap{
    overflow: scroll;
    overflow-x:auto;
    overflow-y:auto;
    position: relative;
    z-index: 25;
  }
  .el-scrollbar{
    height: 100%;
  }
  /deep/ .el-scrollbar__bar{
    right: 0px;
    z-index: 100;
  }
  /deep/ .el-scrollbar__bar.is-vertical {
    width: 2px;
    border-radius: 1px;
  }
  /deep/ .el-scrollbar__thumb:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
  /deep/ .el-scrollbar__thumb{
    background-color: rgba(0, 0, 0, 0.1);
  }
}
#mod1 {
  #login {
    // height: 80px;
    // width: 270px;
    height: 100%;
    width: 100%;
    margin-right: 30px;
    line-height: 80px;
    color: #fff;
    font-size: 16px;
    #login1,#login2,#login3,#login4,#login7,#login9 {
      width: auto;
      float: right;
    }
    #login1,#login3,#login4 {
      white-space: nowrap;
      /* 2.超出的部分隐藏 */
      overflow: hidden;
      cursor: pointer;
      margin: 0 5px;
    }
    
  }
  #login_page {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    background-color: rgba(133,133,133,0.8);
    #login_page_1 {
      padding-top: 20px;
      position: relative;
      top: calc(40% - 130px);
      left: calc(50% - 200px);
      width: 400px;
      height: 260px;
      background-color: #fff;
      border-radius: 10px;
      .avatar_box {
        position: relative;
        left: calc(50% - 100px);
        width: 200px;
        height: auto;
        margin-bottom: 20px;
        >img {
          width: 100%;
          height: auto;
        }
      }
      .login_form {
        .login_form_1 {
          width: 90%;
          margin: 0 5%;
        }
        .el-input{
          float: left;
          font-size: 24px;
          height: 60px;
          float: left;
          width: calc(90% - 24px * 4);
        }
        .login_form_2 {
          float: left;
          font-size: 24px;
          width: calc(24px * 4);
          text-align: right;
        }
        /deep/ .el-input__inner{
          height: 36px;
          line-height: 24px;
          padding: 6px 10px;
        }
        .btns {
          width: calc(72px * 2 + 12px);
          margin: auto;
        }
        /deep/ .el-input__inner {
          border: 1px solid #DCDFE6;
          border-radius: 5px;
        }
        /deep/ .el-form-item__error {
          font-size: 12px;
          padding-top: 4px;
          left: 5%;
        }
        /deep/ .el-button{
          font-size: 24px;
          width: 72px;
          height: 36px;
          padding: 0;
          border-radius: 18px;
        }
        .el-button + .el-button {
          margin-left: 12px;
        }
      }
      /deep/ .el-form-item {
        margin-bottom: 18px;
      }
      /deep/ .el-form-item__content {
        height: 36px;
        line-height: 36px;
      }
    }
  }
  #login_page_2 {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    background-color: rgba(133,133,133,0.8);
    #login_page_1 {
      padding-top: 20px;
      position: relative;
      top: calc(40% - 130px - 90px);
      left: calc(50% - 200px);
      width: 400px;
      height: calc(260px + 60px * 3);
      background-color: #fff;
      border-radius: 10px;
      .avatar_box {
        position: relative;
        left: calc(50% - 100px);
        width: 200px;
        height: auto;
        margin-bottom: 20px;
        >img {
          width: 100%;
          height: auto;
        }
      }
      .login_form {
        .login_form_1 {
          width: 90%;
          margin: 0 5%;
        }
        .el-input{
          float: left;
          font-size: 24px;
          height: 60px;
          float: left;
          width: calc(90% - 24px * 4);
        }
        .login_form_2 {
          float: left;
          font-size: 24px;
          width: calc(24px * 4);
          text-align: right;
        }
        /deep/ .el-input__inner{
          height: 36px;
          line-height: 24px;
          padding: 6px 10px;
        }
        .btns {
          width: calc(72px * 2 + 12px);
          margin: auto;
        }
        /deep/ .el-input__inner {
          border: 1px solid #DCDFE6;
          border-radius: 5px;
        }
        /deep/ .el-form-item__error {
          font-size: 12px;
          padding-top: 4px;
          left: 5%;
        }
        /deep/ .el-button{
          font-size: 24px;
          width: 72px;
          height: 36px;
          padding: 0;
          border-radius: 18px;
        }
        .el-button + .el-button {
          margin-left: 12px;
        }
      }
      /deep/ .el-form-item {
        margin-bottom: 18px;
      }
      /deep/ .el-form-item__content {
        height: 36px;
        line-height: 36px;
      }
    }
  }
  #top {
    font-size: 32px;
    height: 80px;
    line-height: 50px;
    width: 100%;
    background-color: #2fa8e1;
    #top1 {
      float: left;
      height: 100%;
      width: 180px;
      > img {
        float: left;
        width: 180px;
      }
    }
    #top2 {
      width: calc(100% - 180px - 242px);
      height: 80px;
      float: left;
      .el-menu {
        background-color: rgba(0,0,0,0);
        border: 0;
        height: 80px;
        margin: 0;
        margin-left: calc((100% - 110px * 8)/2);
      }
      .el-menu-item {
        background-color: rgba(0,0,0,0);
        color: #fff;
        height: 80px;
        line-height: 80px;
        font-size: 18px;
        width: 110px;
        padding: 0;
        text-align: center;
      }
      .el-menu--horizontal>.el-menu-item {
        border: 0;
      }
      > ul {
        width: max-content;
        float: left;
      }
    }
    #top3 {
      font-size: 16px;
      height: 80px;
      line-height: 80px;
      color: #fff;
      width: 230px;
      float: left;
      color: #fff;
      margin-right: 8px;
      > div {
        float: left;
      }
      > img {
        height: 40px;
        width: 40px;
        border-radius: 20px;
        margin: 20px 10px;
        float: left;
      }
      #login8 {
        height: 100%;
        width: 80px;
        text-align: right;
        white-space: nowrap;
        /* 2.超出的部分隐藏 */
        overflow: hidden;
        /* 3.文字用省略号替代超出的部分 */
        // text-overflow: ellipsis;
      }
      #login8:hover{
        cursor: pointer;
      }
    }
  }
  #web1 {
    height: calc(100% - 80px);
    width: 100%;
  }
  #slider {
    width: 100%;
    height: 1021px;
    background-color: #000;
    #slider_1 {
      width: 1920px;
      height: 1021px;
      margin: auto;
      > img {
        width: 100%;
        height: 1021px;
        box-sizing: border-box;
      }
    }
    #slider_2 {
      box-sizing: border-box;
      position: relative;
      top: -362px;
      background-color: rgba(70,73,89,0.8);
      height: 362px;
      width: 100%;
      padding-top: 71px;
      #slider_2_1, #slider_2_2, .slider_2_3 {
        color: #fff;
        text-align: center;
      }
      #slider_2_1 {
        line-height: 60px;
        font-size: 60px;
        margin-bottom: 26px;
        width: auto;
      }
      #slider_2_2 {
        width: calc(60px * 14);
        border-bottom: 1px solid #fff;
        margin: auto;
        margin-bottom: 29px;
      }
      .slider_2_3 {
        line-height: 24px;
        font-size: 24px;
        margin-bottom: 24px;
      }
    }
  }
  #main {
    height: 924px;
    width: 100%;
    background-color: #fff;
    #main1, #main3 {
      font-size: 60px;
      font-weight: 600;
      color: #0083b1;
      text-align: center;
    }
    #main1 {
      margin: 80px auto 50px auto;
    }
    #main2 {
      height: 230px;
      width: calc(345px * 2 + 460px);
      margin: 0 auto;
      .main2_1 {
        height: 100%;
        float: left;
        >img {
          height: 100%;
        }
      }
      .main2_2 {
        font-size: 30px;
        font-weight: 600;
        line-height: calc(160px * 2 + 30px);
        padding-left: 20px;
        position: relative;
        top: calc(-100% + 5px);
        left: 10px;
        height: calc(100% - 20px);
        width: calc(100% - 20px);
        border: 2px solid #6ad2f6;
        box-sizing: border-box;
      }
    }
    #main3 {
      margin: 70px auto 50px auto;
    }
    .main4 {
      text-align: center;
      color: #2e3037;
      font-size: 24px;
      line-height: 36px;
      font-weight: 600;
    }
    #main5 {
      height: 60px;
      line-height: 60px;
      width: calc(210px + 67px);
      margin: auto;
      margin-top: 30px;
      #main5_1, #main5_2 {
        float: left;
      }
      #main5_1 {
        width: 210px;
        height: 60px;
        background-color: #6ad2f6;
        font-size: 24px;
        color: #fff;
        font-weight: 700;
        text-align: center;
      }
      #main5_2 {
        width: 67px;
        height: 60px;
        background-color: #44c1ec;
        #main5_3 {
          margin: calc(60px/2 - 12px/2) calc(67px/2 - 12px/2 + 6px/2);
          height:0px;
          width:0px;
          border-color:rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0) #fff;
          border-style:solid;
          border-width:6px;
        }
      }
    }
  }
  #footer {
    height: 279px;
    width:100%;
    background-image: linear-gradient(to right, #0083b1 , #6ad2f6);
    #footer0 {
      width: 1920px;
      margin: 0 auto;
    }
    #footer1 {
      float: left;
      height: 279px;
      >img {
        height: 82px;
        width: 260px;
        margin: calc((279px - 82px)/2) 0 0 180px;
      }
    }
    #footer2 {
      float: left;
      height: 137px;
      width: 1164px;
      margin: 71px 25px;
      #footer2_1, #footer2_2, #footer2_3 {
        height: 100%;
        width: 388px;
        float: left;
        box-sizing: border-box;
        .iconfont {
          float: left;
          margin: 40px 0 0 32px;
          height: 60px;
        }
        .iconfont {
          font-size: 60px;
          color: #fff;
        }
      }
      #footer2_1 {
        background-color: #2377c3;
      }
      #footer2_2 {
        background-color: #3f97d1;
      }
      #footer2_3 {
        background-color: #3fa6d1;
      }
      .footer2_4 {
        margin: 30px 0 0 18px;
        float: left;
        font-size: 18px;
        color: #8ecce1;
        font-weight: 700;
        line-height: 36px;
      }
    }
    #footer3 {
      float: right;
      height: 279px;
      >img {
        height: 132px;
        margin: calc((279px - 132px)/2) 132px 0 0;
      }
    }
    #footer4 {
      >div {
        font-size: 12px;
        color: #999;
        line-height: 18px;
        height: 18px;
        width: 100%;
        text-align: center;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  #web2 {
    width: 100%;
    height: calc(100% - 80px);
  }
}
</style>
