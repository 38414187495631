<template>
  <!-- <div :style="{ width: screenWidth+ 'px' , height: screeHeight+ 'px' }" id=AppSquare> -->
  <el-scrollbar style="width:100%;height:100%" id=AppSquare>
    <dataChild v-on:childData="childData" :mainName=mainName></dataChild>
    <img src="../../assets/bgIMG.gif" id=mod1>
    <div style="height:1rem"></div>
    <div id=mod_search @keydown.enter="runSearch()">
      <el-select v-model="value" placeholder="请选择" :style="{ 'margin-left': (screenWidth/36)/25 + 'rem'}">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" >
        </el-option>
      </el-select>
      <el-input
        id=mod_search_2
        v-model="searchText"
        placeholder="请从左侧项目进行选择后搜索"
      ></el-input>
      <el-button type="success" id=mod_search_3 @click="runSearch()">搜索</el-button>
      <el-button type="success" id=mod_search_4 :style="{ 'margin-right': (screenWidth/36)/25 + 'rem'}" title='清除个人文件夹下运行失败的应用文件夹' @click="clearDir(1)">清理失效文件夹</el-button>
      <el-button type="success" id=mod_search_4 title='清除个人文件夹下所有文件夹' @click="clearDir(0)">清空个人文件夹</el-button>
    </div>
    <div style="height:2rem"></div>
    <el-card v-for="i in test" :key="i.tool_id" id=mod2 :style="{ width: (screenWidth/36)/5 + 'rem', 'margin-left': (screenWidth/36)/25 + 'rem', height: (screenWidth/32)/5 + 'rem' }" @click.native="openSquare(i.tool_id)" shadow="hover">
      <div id=mod2_1 v-if="(typeof(i.tool_id) !== 'undefined')">
        <img v-lazy="Url + '/' + i.img" alt="">
        <div style="height:2%;border-bottom: 1px #ccc solid;margin-bottom: 2%;margin-left: 0;"></div>
        <div v-if="(i.cnName.length <= 12)" style="font-size: 0.6rem;">{{i.cnName}}</div>
        <div v-if="(i.cnName.length >  12)" style="font-size: 0.6rem;">{{i.cnName.substr(0,12) + '...'}}</div>
        <div v-if="(i.enName.length <= 25)" style="font-size: 0.55rem;">{{i.enName}}</div>
        <div v-if="(i.enName.length >  25)" style="font-size: 0.55rem;">{{i.enName.substr(0,25) + '...'}}</div>
        <div v-if="(i.introduce.length <= 35)" style="font-size: 0.5rem;">{{i.introduce}}</div>
        <div v-if="(i.introduce.length >  35)" style="font-size: 0.5rem;">{{i.introduce.substr(0,30) + '...'}}</div>
      </div>
    </el-card>
  </el-scrollbar>
</template>

<script>
// $emit----v-on子传父
// 非父子组件进行传值公共js
import DataChild from '../Mate/data.vue'
import Data from '../Mate/data.js'
export default {
  // 引用this.getAppUrl()-----切换内外网
  // 引用this.reload()-----刷新
  // window.location.reload() -------刷新整个页面
  inject: ['reload', 'getAppUrl'],
  metaInfo: {
    title: '流程分析',
    titleTemplate: '佰茵云 - %s',
    htmlAttrs: {
      lang: 'ch',
      amp: true
    }
  },
  data() {
    return {
      screenWidth: 0,
      screeHeight: 0,
      userAgent: '',
      rule: { type: '全部', keyword: '' },
      mainName: 'Template',
      test: ['正在获取数据...'],
      test1: [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],
      AppData: '',
      Url: '',
      options: [{ value: '全部', label: '全部'}, { value: '应用名', label: '应用名'}, { value: '应用环境', label: '应用环境'}, { value: '应用说明', label: '应用说明'}],
      value: '全部',
      searchText: '',
      addApp: 0,
    }
  },
  created() {
    this.getAppUrl()
    setTimeout(async() => { 
      this.ini()
    }, 1000)
  },
  mounted: function () {
    // mounted一直监控？可改位置----目前此方法还不能正确运用
    // 用$on事件来接收参数
    var that = this
    Data.$on('demo', (data) => {
      console.log(data)
    }) 
  },
  methods: {
    // 接收子组件的值
    childData: function (data) {
    // console.log(data)
    },
    // 接收公共js的值
    demoData: function () {
      Data.$emit('demo', this.demo)
    },
    async ini () {
      // 获取屏幕宽高，浏览器还有电脑系统信息
      this.screenWidth = document.body.clientWidth,
      this.screeHeight = document.body.clientHeight,
      this.userAgent = navigator.userAgent
      // 火狐浏览器的宽度修改[浏览器单独修改]
      // console.log(this.screenWidth)
      // if (this.userAgent.indexOf("Firefox") !== -1) {
      //   this.screenWidth = this.screenWidth
      // }
      var k = { username: localStorage.getItem('username'), token: localStorage.getItem('token') }
      const { data: res } = await this.$http.post('application/published_tools', k, { emulateJSON: true })
      if (res.code === 'tokenError') {
        localStorage.removeItem('username');
        localStorage.removeItem('name');
        localStorage.removeItem('token');
        localStorage.clear()
        this.$message.closeAll()
        this.$message({
          type: 'error',
          message: '登录已失效请重新登录。'
        });
        this.$router.push({ path: '/BioinforCloud' })
        return '登录已失效请重新登录。'
      }
      var a = 0
      var b = []
      this.getURL()
      for (var i in res.tool_lists) {
        if (res.tool_lists[i]['type'] === 'Process') {
          b[a] = res.tool_lists[i]
          a = a + 1
        }
      }
      this.AppData = b
      var a = 0
      var b = []
      for(var i in this.AppData) {
        // console.log(res.tool_lists[i]['cnName'], res.tool_lists[i]['cnName'].indexOf(this.rule.keyword))
        if (this.rule.type === '全部' && this.rule.keyword === '') {
          b[a] = this.AppData[i]
          a = a + 1
          continue
        }
      }
      this.test = b
      // console.log(this.test)
    },
    async getURL() {
      this.Url = DataChild.httpUrl_3
      var c = localStorage.getItem('Https')
      if (c === 'No.1') {
        this.Url = DataChild.httpUrl_1
      } else if (c === 'No.2'){
        this.Url = DataChild.httpUrl_2
      } else if (c === 'No.3'){
        this.Url = DataChild.httpUrl_3
      } else if (c === 'No.4'){
        this.Url = DataChild.httpUrl_4
      } else if (c === 'No.5'){
        this.Url = DataChild.httpUrl_3
      } else {
        console.clear()
      }
      this.getUrl = this.Url
      // console.log(c, c === 'No.1', this.getUrl)
      return c
    },
    async openSquare (data) {
      if (this.addApp === 1) return 0
      var k = { username: localStorage.getItem('username'), tool_id: data, token: localStorage.getItem('token') }
      this.$message.closeAll()
      this.addApp = 1
      const set = this.$message({
        message: '正在创建请稍等',
        type: 'success',
        duration: 0,
      });
      const { data: res } = await this.$http.post('application/setRunSpace', k, { emulateJSON: true })
      if (res.code === 'tokenError') {
        localStorage.removeItem('username');
        localStorage.removeItem('token');
        localStorage.clear()
        this.$message.closeAll()
        this.$message({
          type: 'error',
          message: '登录已失效请重新登录。'
        });
        this.$router.push({ path: '/BioinforCloud' })
        return '登录已失效请重新登录。'
      }
      // console.log(res.code, res.msg, res.code === 200, res.msg === '创建成功')
      if (res.code === 200 || res.msg === '创建成功') {
        const a = this.$router.resolve({ path: '/appRun', query: { dir: res.dirname } })
        setTimeout( async() => {
          set.close()
          window.open(a.href,'_blank')
          this.addApp = 0
        },5000)
      } else if (res.code === 400) {
        this.$message.closeAll()
        this.$message.error('创建失败请重新尝试')
        this.reload()
      }
    },
    async runSearch() {
      if (this.value === '') {
        this.$message.closeAll()
        this.$message.error('请选择要搜索的项目')
        return 0
      }
      this.rule =  { type: this.value, keyword: this.searchText }
      var k = { username: localStorage.getItem('username'), token: localStorage.getItem('token') }
      const { data: res } = await this.$http.post('application/published_tools', k, { emulateJSON: true })
      var a = 0
      var b = []
      for(var i in res.tool_lists) {
        // console.log(res.tool_lists[i])
        if (res.tool_lists[i]['type'] !== 'Process') {
          continue
        }
        if (this.rule.type === '全部') {
          if (res.tool_lists[i]['cnName'].indexOf(this.rule.keyword) !== -1) {
            b[a] = res.tool_lists[i]
            a = a + 1
            continue
          } else if (res.tool_lists[i]['enName'].indexOf(this.rule.keyword) !== -1) {
            b[a] = res.tool_lists[i]
            a = a + 1
            continue
          } else if (res.tool_lists[i]['introduce'].indexOf(this.rule.keyword) !== -1) {
            b[a] = res.tool_lists[i]
            a = a + 1
            continue
          }
        } else if ((this.rule.type === '应用名') && (res.tool_lists[i]['cnName'].indexOf(this.rule.keyword) !== -1) ) {
          b[a] = res.tool_lists[i]
          a = a + 1
          continue
        } else if ((this.rule.type === '应用环境') && (res.tool_lists[i]['enName'].indexOf(this.rule.keyword) !== -1) ) {
          b[a] = res.tool_lists[i]
          a = a + 1
          continue
        } else if ((this.rule.type === '应用说明') && (res.tool_lists[i]['introduce'].indexOf(this.rule.keyword) !== -1) ) {
          b[a] = res.tool_lists[i]
          a = a + 1
          continue
        }
      }
      this.test = b
      // console.log(this.test)
    },
    clearDir(data) {
      var k = { username: localStorage.getItem('username'), token: localStorage.getItem('token'), data: data }
      this.$confirm('该操作不可还原, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.$message.closeAll()
        const set = this.$message({
          type: 'success',
          message: '正在清理请稍后!',
          duration: 0,
        });
        // console.log(k)
        const { data: res } = await this.$http.post('application/clearDir', k, { emulateJSON: true })
        // console.log(res)
        if (res.code === 200) {
          set.close()
          this.$message.closeAll()
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }
      }).catch(() => {
        // k = { name: '4124', code: '1' }
        // this.$http.post('user/examine', k, { emulateJSON: true })
        this.$message.closeAll()
        this.$message({
          type: 'info',
          message: '已取消操作'
        })
      });
    }
  },
  props: {},
  components: {
    DataChild
  },
}
</script>

<style lang="less" scoped>
// 滑动条属性
#AppSquare {
  /deep/ .el-scrollbar{
    height: 100%;
  }
  /deep/ .el-scrollbar__bar{
    right: 0rem;
    z-index: 99;
  }
  /deep/ .el-scrollbar__wrap{
    overflow: scroll;
    overflow-x:auto;
    overflow-y:auto;
    position: relative;
    z-index: 25;
  }
  /deep/ .el-scrollbar__bar.is-vertical {
    width: 0.5rem;
    border-radius: 0.25rem;
  }
  /deep/ .el-scrollbar__thumb{
    background-color: rgba(0, 0, 0, 0.15);
  }
  /deep/ .el-scrollbar__thumb:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
#AppSquare {
  height: 100%;
  width: 100%;
  #mod1 {
    width:100%;
    height:100%;
    opacity: 0.25;
    position: absolute;
    z-index: 0;
  }
  #mod2 {
    position: relative;
    box-sizing: border-box;
    border-radius: 0.5rem;
    z-index: 10;
    width: 20%;
    height: 3rem;
    background-color: #fff;
    margin-bottom: 1rem;
    float:left;
  }
  /deep/ .el-card__body {
    padding: 0;
    height: 100%;
    width: 100%;
  }
  .el-card.is-hover-shadow:focus, .el-card.is-hover-shadow:hover {
    box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.2);
  }
  #mod2_1 {
    width: 100%;
    height: 100%;
    font-size: 0.45rem;
    > img {
      width: 100%;
      height: 56.25%;
      border-radius: 0.5rem 0.5rem 0 0;
    }
    > div {
      height: 1rem;
      line-height: 1rem;
      margin-left: 0.2rem;
    }
  }
  #mod_search {
    .el-select {
      float: left;
    }
    /deep/.el-select .el-input {
      width: 5rem;
      font-size: 0.5rem;
      .el-input__inner {
        height: 1rem;
      }
    }
    .el-input {
      float: left;
      margin: 0 0.5rem;
      width: 50%;
      font-size: 0.5rem;
      /deep/ .el-input__inner {
        height: 1rem;
      }
    }
    /deep/ .el-input__icon {
      line-height: 1rem;
      font-size: 0.5rem;
    }
    #mod_search_3 {
      float: left;
      width: 2rem;
      font-size: 0.5rem;
      height: 1rem;
      line-height: 1rem;
    }
    #mod_search_4 {
      float: right;
      width: 4rem;
      font-size: 0.5rem;
      height: 1rem;
      line-height: 1rem;
    }
    /deep/ .el-button {
      position: relative;
      padding: 0;
      z-index: 20;
    }
  }
}
</style>
