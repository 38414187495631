<template>
  <!-- <div :style="{ width: screenWidth+ 'px' , height: screeHeight+ 'px' }" id=devRight> -->
  <div style="width:100%,height:100%" id=AppCenter>
    <dataChild v-on:childData="childData" :mainName=mainName></dataChild>
    <div id=mod1>
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="1">综合排序</el-menu-item>
        <el-menu-item index="2">A-Z排序</el-menu-item>
        <el-menu-item index="3">Z-A排序</el-menu-item>
        <el-input
        placeholder="输入关键字进行过滤"
        v-model="filterText">
        </el-input>
      </el-menu>
    </div>
    <div id=mod6><appManage></appManage></div>
  </div>
</template>

<script>
// $emit----v-on子传父
// 非父子组件进行传值公共js
import DataChild from '../Mate/data.vue'
import Data from '../Mate/data.js'
import AppManage from './应用中心子页面.vue'
export default {
  inject: ['reload'],
  // 引用this.reload()-----刷新
  data() {
    return {
      screenWidth: document.body.clientWidth,
      screeHeight: document.body.clientHeight,
      mainName: 'devRight',
      activation: '应用管理',
      activeIndex: '1',
      filterText: ''
    }
  },
  created() {
  },
  mounted: function () {
    // mounted一直监控？可改位置----目前此方法还不能正确运用
    // 用$on事件来接收参数
    var that = this
    Data.$on('demo', (data) => {
      console.log(data)
    })
  },
  methods: {
    // 接收子组件的值
    childData: function (data) {
    // console.log(data)
    },
    // 接收公共js的值
    demoData: function () {
      Data.$emit('demo', this.demo)
    },
    handleSelect(key, keyPath) {
      // console.log(key, keyPath)
    }
  },
  props: {},
  components: {
    DataChild,
    AppManage
  },
}
</script>

<style lang="less" scoped>
#AppCenter {
  height: 100%;
  width: 100%;
  #mod1 {
    height:2rem;
    .el-menu-item {
      height:2rem;
      line-height: 2rem;
      padding: 0;
      text-align: center;
      font-size: 0.5rem;
      width: 3rem;
      border: none;
    }
    .el-menu-item.is-active {
      border: none;
    }
    /deep/ .el-submenu__title {
      font-size: 0.5rem;
      padding: 0 0.5rem;
      border: none;
    }
    /deep/ .el-menu--horizontal > .el-submenu .el-submenu__title {
      font-size: 0.5rem;
      height:2rem;
      line-height: 2rem;
    }
    /deep/ .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
      border: none;
    }
    .el-input {
      height:2rem;
      line-height: 2rem;
      width: 50%;
      float: right;
      margin: 0;
    }
    /deep/ .el-input__inner {
      width: 80%;
      margin-left: 10%;
      padding: 0;
      padding-left: 1rem;
      height: 1.5rem;
      line-height: 1.5rem;
      border-radius: 1rem;
    }
  }
  #mod6 {
    width: 100%;
    border-radius: 1rem;
    // box-shadow: 0rem 0.1rem 0.1rem rgba(0,0,0,0.1);
    box-sizing: border-box;
    margin-bottom: 0.5rem;
    background-color: #fff;
    height: calc(100% - 2rem);
    // box-shadow: 0rem 0rem 0.1rem rgba(0,0,0,0.1);
    // background-color: #000;
    background-color: rgba(0,0,0,0);
  }
}
</style>
