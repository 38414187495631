import { render, staticRenderFns } from "./消息中心.vue?vue&type=template&id=70da9e45&scoped=true&"
import script from "./消息中心.vue?vue&type=script&lang=js&"
export * from "./消息中心.vue?vue&type=script&lang=js&"
import style0 from "./消息中心.vue?vue&type=style&index=0&id=70da9e45&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70da9e45",
  null
  
)

export default component.exports