<template>
  <div style="width:100%;height:100%">
    <div v-if='(size === 1)'>
      <!-- 1920宽的尺寸 -->
      <div :style="{ width: screenWidth+ 'px' , height: screeHeight+ 'px' }" id=template>
      <!-- <div style="width:100%,height:100%"> -->
        <dataChild v-on:childData="childData" :mainName=mainName></dataChild>
        <img src="../../assets/404.jpg" alt="" style="width:100%;height:auto">
      </div>
    </div>
    <div v-if='(size === 0)' style="width:100%;height:100%">
      <!-- 左侧栏 -->
      <div id=mod2>
        <!-- <img src="../../assets/logo.jpg" alt /> -->
        <el-menu :default-active="this.iniWeb1">
          <el-menu-item>
            <el-menu-item index="1" @click="clickInterface('任务中心')">
              <img src="../../assets/个人中心素材/任务中心_未经过.png" alt="" v-if="(this.interface !== '任务中心')">
              <img src="../../assets/个人中心素材/任务中心_经过.png" alt="" v-if="(this.interface === '任务中心')">
              任务中心
            </el-menu-item>
          </el-menu-item>
          <el-menu-item>
            <el-menu-item index="2" @click="clickInterface('消息中心')" class="mod2_1">
              <img src="../../assets/个人中心素材/消息中心_未经过.png" alt="" v-if="(this.interface !== '消息中心')">
              <img src="../../assets/个人中心素材/消息中心_经过.png" alt="" v-if="(this.interface === '消息中心')">
              消息中心
            </el-menu-item>
          </el-menu-item>
          <el-menu-item v-if="(this.toExamine === 1)">
            <el-menu-item index="3" @click="clickInterface('待审核帖子')">
              <img src="../../assets/个人中心素材/待审核贴子_未经过.png" alt="" v-if="(this.interface !== '待审核帖子')">
              <img src="../../assets/个人中心素材/待审核贴子_经过.png" alt="" v-if="(this.interface === '待审核帖子')">
              待审核帖子
            </el-menu-item>
          </el-menu-item>
          <el-menu-item v-if="(this.toExamine === 1)">
            <el-menu-item index="5" @click="clickInterface('管理员功能')">
              <img src="../../assets/个人中心素材/待审核贴子_未经过.png" alt="" v-if="(this.interface !== '管理员功能')">
              <img src="../../assets/个人中心素材/待审核贴子_经过.png" alt="" v-if="(this.interface === '管理员功能')">
              管理员功能
            </el-menu-item>
          </el-menu-item>
          <el-menu-item>
            <el-menu-item index="6" @click="clickInterface('个人文件夹')">
              <img src="../../assets/个人中心素材/文件夹1.png" alt="" v-if="(this.interface !== '个人文件夹')">
              <img src="../../assets/个人中心素材/文件夹2.png" alt="" v-if="(this.interface === '个人文件夹')">
              个人云盘
            </el-menu-item>
          </el-menu-item>
          <el-menu-item>
            <el-menu-item index="4" @click="clickInterface('个人设置')">
              <img src="../../assets/个人中心素材/个人设置_未经过.png" alt="" v-if="(this.interface !== '个人设置')">
              <img src="../../assets/个人中心素材/个人设置_经过.png" alt="" v-if="(this.interface === '个人设置')">
              个人设置
            </el-menu-item>
          </el-menu-item>
        </el-menu>
      </div>
      <!-- 右侧栏 -->
      <div id=mod3>
        <!-- 头部信息
        <div id=mod1>
          <el-menu el-menuid="right_1" mode="horizontal" :router=false @select="handleSelect">
            <el-menu-item index="/BioinforCloud">首页</el-menu-item>
            <el-menu-item>应用广场</el-menu-item>
            <el-menu-item>生信流程</el-menu-item>
            <el-menu-item>视频课程</el-menu-item>
            <el-menu-item>学术成果</el-menu-item>
            <el-menu-item>佰茵笔记</el-menu-item>
            <el-menu-item>佰茵论坛</el-menu-item>
            <el-menu-item>联系我们</el-menu-item>
          </el-menu>
          <div id=mod1_1>
            <div>你好！{{this.username}}</div>
            <img src="../../assets/404.jpg" alt="">
          </div>
        </div> -->
        <!-- 各种页面信息 -->
        <div id=mod3_2>
          <message-center v-if="(this.interface === '消息中心')"></message-center>
          <forum-center v-if="(this.interface === '待审核帖子')"></forum-center>
          <admin-center v-if="(this.interface === '管理员功能')"></admin-center>
          <app-center v-if="(this.interface === '任务中心')"></app-center>
          <personal-center v-if="(this.interface === '个人设置')"></personal-center>
          <personal-disk v-if="(this.interface === '个人文件夹')"></personal-disk>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// $emit----v-on子传父
// 非父子组件进行传值公共js
import DataChild from '../Mate/data.vue'
import Data from '../Mate/data.js'
import PersonalCenter from './个人中心.vue'
import AppCenter from './应用中心.vue'
import ForumCenter from './发帖管理.vue'
import MessageCenter from './消息中心.vue'
import AdminCenter from './管理员功能.vue'
import PersonalDisk from './个人文件夹.vue'
export default {
  // 引用this.getAppUrl()-----切换内外网
  // 引用this.reload()-----刷新
  // window.location.reload() -------刷新整个页面
  inject: ['reload', 'getAppUrl'],
  // 页面标签设置(在模板里面修改文件名【package.json文件修改后重启可设置主标签】)
  metaInfo: {
    title: '个人中心',
    titleTemplate: 'BioinforCloud - %s',
    htmlAttrs: {
      lang: 'ch',
      amp: true
    }
  },
  data() {
    return {
      screenWidth: document.body.clientWidth,
      screeHeight: document.body.clientHeight,
      mainName: 'Template',
      size: 0,
      interface: '个人设置',
      username: '',
      iniWeb1: "4",
      // 审核权限
      toExamine: 0,
    }
  },
  created() {
    this.sizeData()
    this.ini1()
    setTimeout(async() => { 
    }, 1000)
  },
  mounted: function () {
    // mounted一直监控？可改位置----目前此方法还不能正确运用
    // 用$on事件来接收参数
    var that = this
    Data.$on('demo', (data) => {
      console.log(data)
    })
  },
  methods: {
    childData: function (data) {
      // 接收子组件的值
      // console.log(data)
    },
    demoData: function () {
      // 接收公共js的值
      Data.$emit('demo', this.demo)
    },
    sizeData() {
      // 默认设置
      this.size = 0
      // 大小设定
      // if (this.screenWidth >= 1920) this.size = 1
    },
    clickInterface(data) {
      localStorage.setItem('webPath1', data)
      if (data === '任务中心') localStorage.setItem('webPath2', 1)
      if (data === '消息中心') localStorage.setItem('webPath2', 2)
      if (data === '待审核帖子') localStorage.setItem('webPath2', 3)
      if (data === '个人设置') localStorage.setItem('webPath2', 4)
      if (data === '管理员功能') localStorage.setItem('webPath2', 5)
      if (data === '个人文件夹') localStorage.setItem('webPath2', 6)
      this.interface = data
    },
    // 不需要延迟的方法
    ini1() {
      this.username = localStorage.getItem('name')
      if(localStorage.getItem('webPath1') !== null) {
        this.interface = localStorage.getItem('webPath1')
        this.iniWeb1 = localStorage.getItem('webPath2')
      }
      this.adminUser()
    },
    handleSelect(key, keyPath) {
      if (key === null){
        return this.$router.push({ path: '/BioinforCloud' }).catch(error => error)
      } else if (key === '/BioinforCloud') {
        return this.$router.push({ path: key }).catch(error => error)
      }
    },
    // 验证管理员
    async adminUser() {
      var k = { username:localStorage.getItem('username'), token: localStorage.getItem('token') }
      const { data: res } = await this.$http.post('user/userAdmin', k)
      // console.log(res)
      if (res.code === 200) {
        this.toExamine = 1
      }
    }
  },
  props: {},
  components: {
    DataChild,
    PersonalCenter,
    AppCenter,
    ForumCenter,
    MessageCenter,
    AdminCenter,
    PersonalDisk,
  },
}
</script>

<style lang="less" scoped>
#mod2{
  font-size: 16px;
  text-align: center;
  line-height: 0px;
  height: 100%;
  width: 180px;
  float: left;
  background-color: #fff;
  > img {
    height: auto;
    width: 100%;
    // margin: 5px 0;
  }
  .el-menu {
    background-color: rgba(0,0,0,0);
    > li {
      padding: 0!important;
    }
    border: 0;
  }
  .el-menu-item {
    padding: 0;
    margin: 0;
    background-color: rgba(0,0,0,0);
    height: 65px;
    line-height: 65px;
    width: 100%;
    text-align: left;
    font-size: 16px;
    color: #2fa8e1;
    > li {
      padding: 0!important;
    }
    > img {
      height: 20px;
      margin: 0 18px 0 28px;
    }
  }
  .el-menu-item.is-active {
    background-color: #eee;
    color: #000;
  }
}
#mod3{
  font-size: 16px;
  text-align: center;
  line-height: 32px;
  height: 100%;
  width: calc(100% - 180px);
  float: right;
  background-color: #f5f6fb;
  #mod1 {
    font-size: 32px;
    height: 80px;
    line-height: 50px;
    width: 100%;
    background-color: #2fa8e1;
    #mod1_1 {
      font-size: 18px;
      height: 80px;
      line-height: 80px;
      color: #fff;
      > div {
        position: absolute;
        right: 80px;
        top: 0px;
      }
      > img {
        position: absolute;
        right: 0px;
        top: 0px;
        height: 40px;
        width: 40px;
        border-radius: 20px;
        margin: 20px;
      }
    }
    .el-menu {
      background-color: rgba(0,0,0,0);
      border: 0;
      height: 80px;
      margin: 0 auto;
    }
    .el-menu-item {
      background-color: rgba(0,0,0,0);
      color: #fff;
      height: 80px;
      line-height: 80px;
      font-size: 18px;
    }
    .el-menu--horizontal>.el-menu-item {
      border: 0;
    }
    > ul {
      width: max-content;
    }
  }
  #mod3_2 {
    width: 100%;
    // height: calc(100% - 80px)
    height: 100%;
  }
}
</style>
