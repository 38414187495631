import { render, staticRenderFns } from "./UI显示界面2.vue?vue&type=template&id=fd253c48&scoped=true&"
import script from "./UI显示界面2.vue?vue&type=script&lang=js&"
export * from "./UI显示界面2.vue?vue&type=script&lang=js&"
import style0 from "./UI显示界面2.vue?vue&type=style&index=0&id=fd253c48&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd253c48",
  null
  
)

export default component.exports