import { render, staticRenderFns } from "./主页.vue?vue&type=template&id=53f8ddc9&scoped=true&"
import script from "./主页.vue?vue&type=script&lang=js&"
export * from "./主页.vue?vue&type=script&lang=js&"
import style0 from "./主页.vue?vue&type=style&index=0&id=53f8ddc9&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53f8ddc9",
  null
  
)

export default component.exports