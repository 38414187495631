import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import DataChild from './location/Mate/data.vue'
import VueLazyLoad from 'vue-lazyload'

// 导入全局样式表
// 图标
import './assets/css/global.css'
import './assets/fonts/iconfont.css'

import axios from 'axios'
import store from './store'

// 初始化IP地址
// IP接口变了改这里
if (localStorage.getItem('IP') === null) {
  axios.get('https://api64.ipify.org/?format=json').then(function(res){
    var ip=res.data['ip']
    localStorage.setItem('IP',ip)
  }).catch((error) => {
    var ip='0.0.0.0'
    localStorage.setItem('IP',ip)
  })
}
getNew()

const a = localStorage.getItem('Https')
if (localStorage.getItem('demo') === null) {
  localStorage.setItem('demo', '0')
}

function getNew(){
  if (a === 'No.1' || a === 'No.2' || a === 'No.3' || a === 'No.4') {
    // console.log('之后打开页面判断')
    if (a === 'No.1') axios.defaults.baseURL = DataChild.httpUrl_1
    if (a === 'No.2') axios.defaults.baseURL = DataChild.httpUrl_2
    if (a === 'No.3') axios.defaults.baseURL = DataChild.httpUrl_3
    if (a === 'No.4') axios.defaults.baseURL = DataChild.httpUrl_4
  } else if (a === 'No.5') {
    // No.5是后端都异常了
    axios.defaults.baseURL = DataChild.httpUrl_3
  } else {
    axios.defaults.baseURL = DataChild.httpUrl_3
  }
}
Vue.prototype.$http = axios
Vue.config.productionTip = false

Vue.use(VueLazyLoad, {
  loading: require('./assets/404.jpg'),
  error: require('./assets/404.jpg'),
  try: 3 // default 1
})

const baseSize = 36
// 设置 rem 函数
function setRem () {
  // 当前页面宽度相对于 1920 宽的缩放比例，可根据自己需要修改。
  const scale = document.documentElement.clientWidth / 1920
  // 设置页面根节点字体大小
  document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'
}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem()
}

new Vue({
  router,

  data: function() {
    return {
      Cache: '测试'
    }
  },

  store,
  render: h => h(App)
}).$mount('#app')



