<template>
  <div style="width:100%;height:100%">
    <div v-if='(size === 1)' id=modC>
      <!-- 1920宽的尺寸 -->
      <div :style="{ width: screenWidth+ 'px' , height: screeHeight+ 'px' }">
      <!-- <div style="width:100%,height:100%"> -->
        <dataChild v-on:childData="childData" :mainName=mainName></dataChild>
        <img src="../../assets/404.jpg" alt="" style="width:100%;height:auto">
      </div>
    </div>
    <div v-if='(size === 0)' id=modC_1>
      <!-- rem的自适应尺寸 -->
      <div id=modC_2></div>
      <div id=modC_3>
        <div id=modC_3_1>
          <el-input v-model="search" :placeholder="search2"></el-input>
          <el-button type="success" @click="forum3()">搜索</el-button>
        </div>
        <div id=modC_4>
          <div class=modC_4_1>
            <div id=modC_4_1_1>发表新帖</div>
          </div>
          <div class=modC_4_1>
            <img src="../../assets/个人中心素材/New.png" alt="">
          </div>
          <div id=modC_4_2>
            <el-dropdown trigger="click" @command="forum2">
              <el-button type="primary">
                发帖模板
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="提问1">应用提问模板</el-dropdown-item>
                  <el-dropdown-item command="公告">公告信息模板</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <div id=modC_6></div>
          <div id=modC_4_3>
            <el-input v-model="TextB"></el-input>
          </div>
          <div id=modC_4_4>
            <el-input
              v-model="TextN"
              :autosize="{ minRows: 6, maxRows: 6 }"
              type="textarea"
              resize="none"
            >
            </el-input>
          </div>
          <div id=modC_4_5>
            <el-upload
              class="upload-demo"
              multiple
              action="1"
              :on-change="handleAvatarSuccess1"
              :http-request="httpRequest1"
              :show-file-list=false
            >
              <el-button type="primary"><img src="../../assets/个人中心素材/发帖图片按钮.jpg" alt=""></el-button>
            </el-upload>
            <el-upload
              class="upload-demo"
              multiple
              action="1"
              :on-change="handleAvatarSuccess2"
              :http-request="httpRequest2"
              :show-file-list=false
            >
              <el-button type="primary"><img src="../../assets/个人中心素材/发帖附件按钮.jpg" alt=""></el-button>
            </el-upload>
            <el-button type="primary" @click="forum1()">发送</el-button>
          </div>
          <div v-if="(this.pictureDis !== '')" id=modC_4_6>已选择图片：{{this.pictureDis}}</div>
          <div v-if="(this.fileDis !== '')" id=modC_4_6>已选择文件：{{this.fileDis}}</div>
        </div>
        <div id=modC_5></div>
        <!-- <div v-for="i in this.test1" :key="i.id" class=modC_7> -->
        <div v-for="i in this.forumData2" :key="i.ID" class=modC_7 @click="openForum(i.ID)">
          <div class=modC_7_1>
            <img src="../../assets/个人中心素材/临时头像.png" alt="">
            <div class=modC_7_2>{{i.name}}</div>
            <!-- <div class=modC_7_3>{{i.Time}}</div> -->
            <div class=modC_7_3>{{i.create_time}}</div>
          </div>
          <div class=modC_7_4>
            <div class=modC_7_6>{{i.label}}</div>
            <div class=modC_7_5>{{i.title}}</div>
          </div>
          <div class=modC_7_7>{{i.Text}}</div>
          <div class=modC_7_10 v-if="(i.picture !== '暂无' & i.picture !== '')">
            <div class=modC_7_8 v-for="j in i.picture" :key='j.id'>
              <img v-lazy="Url + '/application/get_img/Activate/forum_img/' + i.username + '/' + j.name" alt="" v-if="(j.id < 3)">
            </div>
          </div>
          <div class=modC_7_9>
            <img src="../../assets/论坛素材/评论按钮.jpg" alt="">
            <div>{{i.comment}}</div>
            <div style="width: 31px; float: left; height: 100%;"></div>
            <img src="../../assets/论坛素材/点赞按钮.jpg" alt="" @click="dz()">
            <div>{{i.thumbs_up}}</div>
          </div>
        </div>
        <div id=modC_8>
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="pagesize"
            layout="prev, pager, next"
            :total="forumData.length">
          </el-pagination>
        </div>
      </div>
      <div id=modC_bottom></div>
    </div>
  </div>
</template>

<script>
// $emit----v-on子传父
// 非父子组件进行传值公共js
import DataChild from '../Mate/data.vue'
import Data from '../Mate/data.js'

export default {
  // 引用this.getAppUrl()-----切换内外网
  // 引用this.reload()-----刷新
  // window.location.reload() -------刷新整个页面
  inject: ['reload', 'getAppUrl'],
  // 页面标签设置(在模板里面修改文件名【package.json文件修改后重启可设置主标签】)
  metaInfo: {
    title: '佰茵论坛',
    titleTemplate: '佰茵云 - %s',
    htmlAttrs: {
      lang: 'ch',
      amp: true
    }
  },
  data() {
    return {
      screenWidth: document.body.clientWidth,
      screeHeight: document.body.clientHeight,
      mainName: 'Template',
      size: 0,
      Url: '',
      search: '',
      search2: '搜索文章',
      TextB: '请填写标题',
      TextN: '请填写内容',
      // 测试论坛数据
      test1: [
        {'username': '管理员1', 'Time': '2023年1月1日16:50:55', 'label': '提问', 'title': '这个是帖子的标题2023年1月16日', 'text': '这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。', 'picture': [{'id': 1, 'name': '2.jpg'}, {'id': 2, 'name': '3.jpg'}], 'id': 1, 'comment': '999', 'thumbs_up': '120', }, 
        {'username': '管理员2', 'Time': '2023年1月1日16:50:55', 'label': '公告', 'title': '这个是帖子的标题2023年1月16日', 'text': '这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。', 'picture': [{'id': 1, 'name': '1.jpg'}, {'id': 2, 'name': '2.jpg'}], 'id': 2, 'comment': '1111', 'thumbs_up': '130', }, 
        {'username': '管理员3', 'Time': '2023年1月1日16:50:55', 'label': '说明', 'title': '这个是帖子的标题2023年1月16日', 'text': '这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。这个是帖子的正文。', 'picture': [{'id': 1, 'name': '3.jpg'}, {'id': 2, 'name': '4.jpg'}], 'id': 3, 'comment': '2222', 'thumbs_up': '6666', }
      ],
      // 论坛数据
      forumData: [],
      forumDataAll: [],
      forumData2: [],
      forumData3: [],
      // 默认标签
      label: '提问',
      imageUrl1: 0,
      fileUrl1: 0,
      // 列表
      pictures: [],
      files: [],
      // 图片地址
      picturePath: '',
      // 已上传显示
      pictureDis: '',
      fileDis: '',
      // 预上传
      filePrepare: [],
      picturePrepare: [],
      // 每页显示数目
      pagesize: 8,
      currentPage: 1,
    }
  },
  created() {
    this.getURL()
    this.getForum1()
    this.sizeData()
    setTimeout(async() => {
    }, 1000)
  },
  mounted: function () {
    // mounted一直监控？可改位置----目前此方法还不能正确运用
    // 用$on事件来接收参数
    var that = this
    Data.$on('demo', (data) => {
      console.log(data)
    })
  },
  methods: {
    childData: function (data) {
      // 接收子组件的值
      // console.log(data)
    },
    demoData: function () {
      // 接收公共js的值
      Data.$emit('demo', this.demo)
    },
    sizeData() {
      // 默认设置
      this.size = 0
      // 大小设定
      // if (this.screenWidth >= 1920) this.size = 1
    },
    async getURL() {
      this.Url = DataChild.httpUrl_3
      var c = localStorage.getItem('Https')
      if (c === 'No.1') {
        this.Url = DataChild.httpUrl_1
      } else if (c === 'No.2'){
        this.Url = DataChild.httpUrl_2
      } else if (c === 'No.3'){
        this.Url = DataChild.httpUrl_3
      } else if (c === 'No.4'){
        this.Url = DataChild.httpUrl_4
      } else if (c === 'No.5'){
        this.Url = DataChild.httpUrl_3
      } else {
        console.clear()
      }
      this.getUrl = this.Url
      return c
    },
    async getForum1() {
      // token验证，之后需要再加
      var k = { username:localStorage.getItem('username'), token: localStorage.getItem('token') }
      const { data: res } = await this.$http.post('application/getForum1', k)
      if (res.code === '200') {
        // 排序
        this.forumDataAll = res.result
        this.forumData = res.result
        var l = []
        var i = 0
        while (i < this.forumData.length) {
          var j = 0
          while (j < this.forumData.length - 1) {
            if (this.forumData[j]['ID'] < this.forumData[j + 1]['ID']) {
              l = this.forumData[j]
              this.forumData[j] = this.forumData[j + 1]
              this.forumData[j + 1] = l
            }
            j = j + 1
          }
          i = i + 1
        }
        // 图片转换
        for (var l in this.forumData) {
          if (this.forumData[l].picture !== '暂无' & this.forumData[l].picture !== '') {
            this.forumData[l].picture = JSON.parse(this.forumData[l].picture)
          } else {
            this.forumData[l].picture = ''
          }
        }
        this.handleCurrentChange(this.currentPage)
      }
    },
    async forum1 () {
      // JSON.parse(JSON.stringify(this.upload4))
      var p1 = []
      var q1 = []
      for (var p in this.filePrepare) {
        const { data: res } = await this.$http.post(this.Url + '/application/upload5/' + localStorage.getItem('username'), this.filePrepare[p], { emulateJSON: true })
        // 文件
        if (res.code === 200) {
          p1[p1.length] = { 'id': p1.length, 'name': res.filename }
        }
      }
      for (var q in this.picturePrepare) {
        const { data: res } = await this.$http.post(this.Url + '/application/upload4/' + localStorage.getItem('username'), this.picturePrepare[q], { emulateJSON: true })
        // 图片
        if (res.code === 200) {
          q1[q1.length] = { 'id': q1.length, 'name': res.filename }
        }
      }
      var a = {'username': localStorage.getItem('username'), 'name': localStorage.getItem('name'), 'label': this.label, 'title': this.TextB, 'text': this.TextN, 'picture': JSON.stringify(q1), 'file': JSON.stringify(p1)}
      const { data: res } = await this.$http.post('application/forum1', a)
      if (res.code === '200') {
        this.$message.closeAll()
        const set = this.$message({
          type: 'success',
          message: '发帖成功',
          duration: 3000,
        });
        this.reload()
      }
    },
    async forum2(data) {
      // 从数据库获取然后修改
      // 先是手动修改
      if (data === '提问1') {
        this.label = '提问'
        this.TextB = '应用名称：'
        this.TextN = '运行网址：\n运行时间：\n运行问题：'
      } else if(data === '公告') {
        this.label = '公告'
        this.TextB = '公告时间：'
        this.TextN = '更新内容：'
      } else {
        this.label = data
      }
    },
    async forum3 () {
      var a = this.search.split(' ')
      var b = ''
      var c = []
      var e = 0
      var f = 0
      for (var d in a) {
        b = b + a[d]
        c[a.length + f] = a[d]
        c[a.length - f - 1] = b
        f = f + 1
      }
      this.forumData = []
      this.forumData2 = []
      var k = 0
      for (var i in this.forumDataAll) {
        for (var j in this.forumDataAll[i]) {
          e = 0
          for (var l in c) {
            if ((String(this.forumDataAll[i][j]).toLowerCase()).indexOf(String(c[l]).toLowerCase()) >= 0) {
              this.forumData[k] = this.forumDataAll[i]
              this.forumData2[k] = this.forumDataAll[i]
              // this.forumData2[k]['ID'] = k
              k = k + 1
              e = 1
              break
            }
          }
          if (e === 1) break
        }
      }
      this.handleCurrentChange(this.currentPage)
    },
    handleAvatarSuccess1(file, fileList) {
      const fileType = file.raw.type
      const isImage = fileType.indexOf("image") != -1
      if (!isImage) {
        this.$message.closeAll()
        this.$message.error("只能上传图片");
        return 0;
      }
      this.imageUrl1 = 1
    },
    handleAvatarSuccess2(file, fileList) {
      this.fileUrl1 = 1
    },
    httpRequest1(params) {
      if (this.imageUrl1 === 1) {
        const form = new FormData()
        form.append("file", params.file)
        this.picturePrepare[this.picturePrepare.length] = form
        // const { data: res } = await this.$http.post(this.Url + '/application/upload4/' + localStorage.getItem('username'), form, { emulateJSON: true })
        this.imageUrl1 = 0
        this.pictures[this.pictures.length] = { 'id': this.pictures.length, 'name': params.file.name }
        this.pictureDis = ''
        for (var a in this.pictures) {
          // this.pictureDis = this.pictureDis + this.pictures[a]['name'].split(this.pictures[a]['name'].split('_')[0] + '_')[1] + '\u3000'
          this.pictureDis = this.pictureDis + this.pictures[a]['name'] + '\u3000'
        }
      }
    },
    httpRequest2(params) {
      if (this.fileUrl1 === 1) {
        const form = new FormData()
        form.append("file", params.file)
        this.filePrepare[this.filePrepare.length] = form
        // const { data: res } = await this.$http.post(this.Url + '/application/upload5/' + localStorage.getItem('username'), form, { emulateJSON: true })
        this.fileUrl1 = 0
        this.files[this.files.length] = { 'id': this.files.length, 'name': params.file.name }
        this.fileDis = ''
        for (var a in this.files) {
          // this.fileDis = this.fileDis + this.files[a]['name'].split(this.files[a]['name'].split('_')[0] + '_')[1] + '\u3000'
          this.fileDis = this.fileDis + this.files[a]['name'] + '\u3000'
        }
      }
    },
    // 打开论坛页面
    openForum(data){
      const a = this.$router.resolve({ path: '/Home', query: { forumID: data } })
      window.open(a.href,'_blank')
      // this.$router.push({ path: '/BioinforCloud', query: { forumID: data } })
    },
    // 翻页功能
    handleCurrentChange(currentPage) {
      Data.$emit('Forum5', currentPage)
      this.currentPage = currentPage;
      var i = 0
      var j = 0
      var k = 0
      var l = 0
      this.forumData2 = []
      while ( i < this.pagesize) {
        k = this.currentPage * this.pagesize
        j = (this.currentPage - 1) * this.pagesize - 1
        l = j + i + 2
        if (l > this.forumData.length) break
        this.forumData2[i] = this.forumData[l-1]
        // this.forumData2[i]['ID'] = i
        i = i + 1
      }
      return {'code': 200}
    },
    async dz() {

    },
  },
  props: {},
  components: {
    DataChild
  },
}
</script>

<style lang="less" scoped>
#modC {
  background-color: #f5f6fb;
}
#modC_1 {
  min-height: 100%;
  width: 100%;
  background-color: #f5f6fb;
}
#modC_2 {
  width: 100%;
  height: 57px;
}
#modC_3 {
  min-height: 1040px;
  width: 780px;
  margin: auto;
  background-color: #fff;
  text-align: center;
  padding: 0 1px;
}
#modC_3_1 {
  height: 70px;
  border-bottom: 1px solid #f2f2f2;
  .el-input {
    height: 40px;
    line-height: 40px;
    width: 260px;
    margin: 15px 25px;
    float: left;
  }
  /deep/ .el-input__inner {
    background-color: #eaf6fc;
    color: #b1babe;
  }
  .el-button {
    height: 40px;
    width: 70px;
    line-height: 40px;
    margin: 15px 0px;
    padding: 0;
    float: left;
  }
}
#modC_bottom {
  width: 100%;
  height: 45px;
}
#modC_4 {
  width: 716px;
  min-height: 450px;
  margin: 0 auto;
}
.modC_4_1 {
  width: 93px;
  height: 23px;
  margin: 30px 0 15px 0;
  float: left;
  #modC_4_1_1 {
    font-size: 18px;
    line-height: 18px;
    text-align: right;
    font-weight: 700;
  }
  > img {
    width: 46px;
    height: 23px;
    float: left;
    position: relative;
    z-index: 0;
    top: 0px;
    left: -93px;
  }
}
#modC_4_2 {
  margin: 30px 0 15px 0;
  height: 23px;
  float: right;
  text-align: center;
  .el-dropdown {
    display: block;
  }
  .el-button {
    padding: 0;
    width: 64px;
    height: 100%;
    color: #2fa8e1;
    line-height: 24px;
    font-size: 16px;
    background-color: #fff;
    border: 0;
  }
}
#modC_4_3 {
  height: 53px;
  .el-input {
    height: 53px;
    width: 100%;
    margin: 0 25px 0 0;
    float: left;
  }
  /deep/ .el-input__inner {
    background-color: #f2f2f2;
    color: #919191;
    border: 0;
    height: 53px;
    line-height: 53px;
    font-size: 16px;
  }
}
#modC_4_4 {
  height: 265px;
  .el-textarea {
    height: 246px;
    width: 100%;
    margin: 15px 25px 0 0;
    float: left;
  }
  /deep/ .el-textarea__inner {
    background-color: #f2f2f2;
    color: #919191;
    border: 0;
    line-height: 41px;
    font-size: 16px;
  }
}
#modC_4_5 {
  margin-top: 10px;
  height: 40px;
  zoom:1;
  // overflow:hidden;
  .upload-demo {
    float: left;
    height: 40px;
  }
  /deep/ .el-upload {
    float: left;
    .el-button {
      float: left;
      width: 24px;
      height: 24px;
      margin: 8px 6px 0px 6px;
      border: 0;
      background-color: #fff;
      > img {
        width: 24px;
        height: 24px;
      }
    }
  }
  .el-button {
    width: 110px;
    height: 40px;
    padding: 0;
    text-align: center;
    float: right;
  }
  /deep/ .el-upload-list__item:first-child {
    margin: 0;
  }
  /deep/ .el-upload-list__item {
    margin: 0;
  }
}
#modC_4_6 {
  font-size: 14px;
  width: 100%;
  line-height: 16px;
  text-align: left;
  margin-bottom: 8px;
}
#modC_5 {
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  padding: 0 1px;
}
#modC_6 {
  width: 100%;
  height: 68px;
}
.modC_7 {
  border-bottom: 1px solid #f2f2f2;
  .modC_7_1 {
    margin: 28px 0 0 28px;
    font-size: 16px;
    width: calc(100% - 56px);
    height: 62px;
    text-align: left;
    line-height: 32px;
    > img {
      width: 60px;
      height: 60px;
      border-radius: 30px;
      border: 1px solid #aaa;
      float: left;
    }
  }
  .modC_7_2, .modC_7_3 {
    width: 60%;
    float: left;
    margin-left: 28px;
  }
  .modC_7_2 {
    font-weight: 600;
  }
  .modC_7_3 {
    font-size: 14px;
    color: #ccc;
  }
  .modC_7_4 {
    width: 516px;
    height: 20px;
    margin: 26px auto 0 auto;
    > .modC_7_6 {
      width: 35px;
      height: 20px;
      line-height: 20px;
      font-weight: 600;
      font-size: 14px;
      color: #fff;
      float: left;
      background-color: #2fa8e1;
      border-radius: 2.5px;
    }
    .modC_7_5 {
      margin-left: 8px;
      line-height: 20px;
      font-size: 18px;
      font-weight: 600;
      text-align: left;
      float: left;
    }
  }
  .modC_7_7 {
    font-size: 18px;
    line-height: 24px;
    margin-top: 20px;
    margin-left: 132px;
    width: 618px;
    height: 96px;
    text-align: left;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .modC_7_8 {
    margin-top: 32px;
    margin-left: 132px;
    > img {
      width: 133px;
      height: 133px;
      border-radius: 10px;
      float: left;
      margin-right: 10px;
    }
  }
  .modC_7_10 {
    width: calc(100% - 132px);
    height: 133px;
  }
  .modC_7_9 {
    width: calc(100% - 132px);
    height: 16px;
    margin: 32px auto 32px 132px;
    font-size: 16px;
    line-height: 16px;
    text-align: left;
    > img {
      width: 16px;
      height: 16px;
      margin: 0 8px;
      float: left;
    }
    > div {
      font-size: 8px;
      width: 32px;
      height: 16px;
      margin: 0 8px;
      float: left;
    }
  }
}
#modC_8 {
  height: 28px;
  .el-pagination {
    height: 28px;
    line-height: 28px;
    padding: 0;
  }
}
</style>
