<template>
  <div :style="{ width: screenWidth+ 'px' , height: screeHeight+ 'px' }" id=login>
    <dataChild v-on:childData="childData" :mainName=mainName></dataChild>
    <div class="login_container" @keydown.enter="login">
      <div class="login_box" id=login-in>
        <!-- 表头图片 -->
        <div class="avatar_box">
          <img src="../../assets/logo.png" alt />
        </div>
        <!-- 登录 -->
        <el-form
          ref="loginFormRef"
          :model="loginForm"
          :rules="loginFormRules"
          label-width="0px"
          class="login_form"
        >
          <!-- 用户名 -->
          <el-form-item prop="adminName">
            <el-input v-model="loginForm.adminName" prefix-icon="iconfont icon-dengluzhanghao"></el-input>
          </el-form-item>
          <!-- 密码 -->
          <el-form-item prop="adminPassword">
            <!-- :show-password=true -->
            <el-input
              v-model="loginForm.adminPassword"
              prefix-icon="iconfont icon-denglumima"
              type="password"
            ></el-input>
          </el-form-item>
          <!-- 按钮 -->
          <el-form-item class="btns">
            <el-button type="primary" @click="login">登录</el-button>
            <el-button type="info" @click="reTo">返回</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
// $emit----v-on子传父
// 非父子组件进行传值公共js
import DataChild from '../Mate/data.vue'
import Data from '../Mate/data.js'
export default {
  inject: ['reload'],
  // 引用this.reload()-----刷新
  data() {
    return {
      screenWidth: document.body.clientWidth,
      screeHeight: document.body.clientHeight,
      mainName: 'Template',
      // 登录表单数据位置
      loginForm: {
        adminName: '',
        adminPassword: ''
      },
      loginFormRules: {
        adminName: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        adminPassword: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      },
      id: []
    }
  },
  created() {},
  mounted: function () {
    // mounted一直监控？可改位置----目前此方法还不能正确运用
    // 用$on事件来接收参数
    var that = this
    Data.$on('demo', (data) => {
      console.log(data)
    })
  },
  methods: {
    // 接收子组件的值
    childData: function (data) {
    // console.log(data)
    },
    // 接收公共js的值
    demoData: function () {
      Data.$emit('demo', this.demo)
    },
    reTo() {
      this.$router.push({ path: '/BioinforCloud', query: { name: this.$route.query.name } })
    },
    login() {
      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) return
        var ip = localStorage.getItem('IP')
        var k = { username: this.loginForm.adminName, password: this.loginForm.adminPassword, ip: ip }
        // 不用这个加密了
        // k.password = this.strToBinary(k.password)
        const { data: res } = await this.$http.post('user/loginDev', k)
        // console.log(res)
        if (res.code !== '200') return this.$message.error('密码错误或账号不存在')
        // window.sessionStorage.setItem('devToken', res.data.token)
        this.id = this.loginForm.adminName
        this.$message.success('登录成功')
        localStorage.setItem('username-Dev',res.username)
        localStorage.setItem('token-Dev',res.token)
        this.$router.push({ path: '/mainDev' })
      })
    },
    // strToBinary(str){
    //   var result = [];
    //   var list = str.split("");
    //   for(var i=0;i<list.length;i++){
    //     if(i != 0){
    //         result.push(" ");
    //     }
    //     var item = list[i];
    //     var binaryStr = item.charCodeAt().toString(10) * 5 + 8;
    //     result.push(binaryStr);
    //   }   
    //   return result.join("");
    // }
    
  },
  props: {},
  components: {
    DataChild
  },
}
</script>

<style lang="less" scoped>
#login{
  .login_container {
    background-color: #666;
    height: 100%;
  }
  .login_box {
    width: 18rem;
    height: 12rem;
    background-color: #fff;
    border-radius: 0.5rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .avatar_box {
      height: autorem;
      width: 10rem;
      border: 0.2rem solid #fff;
      border-radius: 1rem;
      box-shadow: 0 0 0.4rem #ddd;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      img {
        box-sizing: border-box;
        padding: 0.2rem 1rem;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        background-color: #eee;
      }
    }
  }
  .login_form {
    position: absolute;
    bottom: 0;
    width: 90%;
    padding: 0 5%;
  }
  .btns {
    display: flex;
    justify-content: flex-end;
  }
  #login-in {
    .el-input{
      font-size: 1rem;
    }
    /deep/ .el-input__inner{
      height: 2rem;
    }
    .el-button{
      font-size: 0.8rem;
      width: 3rem;
      height: 1.5rem;
      line-height: 0.4rem;
      border-radius: 0.75rem;
    }
  }
  .el-form-item{
    margin-bottom: 1rem;
  }
  /deep/ .el-input__inner {
    font-size: 1rem;
  }
  /deep/ .el-input--prefix .el-input__inner{
    padding-left: 2rem;
  }
  /deep/ .el-form-item__content{
    line-height: 0;
  }
  .el-button{
    padding: 0;
  }
  .el-button + .el-button{
    margin-left: 1rem;
  }
  /deep/ .iconfont {
    font-size: 1rem;
    text-align: center;
    line-height: 2rem;
    margin-left: 0.3rem;
  }
  .el-button--primary:focus, .el-button--primary:hover, .el-button:focus, .el-button:hover, .el-button {
    background: #66b1ff;
    border-color: #66b1ff;
    color: #FFF;
    border: 0.1rem solid #fff;
    box-shadow:  0.1rem 0.1rem 0.1rem rgba(0,0,0,0.17);
  }
}
</style>
