<template>
  <div style="width:100%,height:100%" id=appRun v-cloak>
    <dataChild v-on:childData="childData" :mainName=mainName></dataChild>
    <div id=mod4>
      <div class=mod4_ini><add></add></div>
      <div class=mod4_ini><attribute></attribute></div>
      <div id=mod1>
      </div>
      <div id=mod2>
        <AppRunMain></AppRunMain>
      </div>
      <div id=mod3>
        <div v-if="(this.DownloadUrl !== '请压缩')" style="width: 100%;float:left;text-align: center;font-size: 1rem;">
          {{tipeState}}
          <a :href="this.DownloadUrl" v-if="(this.DownloadUrl !== '' && this.tipeState !== '运行出错' && this.tipeState !== '停止运行' && this.DownloadUrl !== '请压缩')">点击下载</a>
        </div>
        <div v-if="(this.DownloadUrl !== '' && this.tipeState !== '运行出错' && this.tipeState !== '停止运行' && this.DownloadUrl === '请压缩')" style="height:3rem;width: 100%;float:left;text-align: center;font-size: 0.8rem;">运行完成，请点击下方"压缩运行结果"</div>
        <img src='../../assets/JDT.gif' alt="" v-if="(this.DownloadUrl === '' && this.tipeState === '正在运行')">
        <div style="height:2rem" v-if="(this.tipeState !== '正在运行')"></div>
        <div style="height:1rem" v-if="(this.tipeState === '正在运行')"></div>
        <div id=mod3_3>
          <el-button class=mod5_1 @click="Run(4)">下载运行日志</el-button>
          <div style="height:1rem"></div>
          <el-button class=mod5_1 @click="Run(5)">压缩运行结果</el-button>
          <div style="height:1rem"></div>
          <el-button class=mod5_1 @click="Run(1)">手动获取状态</el-button>
          <div style="height:1rem"></div>
          <el-button class=mod5_1 @click="Run(2)">中断当前应用</el-button>
          <div style="height:1rem"></div>
          <el-button class=mod5_1 @click="Run(3)">中断全部应用</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// $emit----v-on子传父
// 非父子组件进行传值公共js
import DataChild from '../Mate/data.vue'
import Data from '../Mate/data.js'
import Add from '../children/UI插件界面1.vue'
import AppRunMain from './应用运行子页面.vue'
import Interface from '../children/UI显示界面2.vue'
import Attribute from '../children/UI属性设置3.vue'
export default {
  inject: ['reload'],
  // 引用this.reload()-----刷新
  data() {
    return {
      screenWidth: document.body.clientWidth,
      screeHeight: document.body.clientHeight,
      mainName: 'Dev',
      DownloadUrl: '',
      tipeState: '点击运行',
    }
  },
  created() {
    setTimeout(async() => { 
      this.ini()
    }, 1000)
  },
  mounted: function () {
    // mounted一直监控？可改位置----目前此方法还不能正确运用
    // 用$on事件来接收参数
    var that = this
    Data.$on('demo', (data) => {
      console.log(data)
    })
    Data.$on('download_file', (data) => {
      // console.log(data, data.url === '')
      if (data.id === this.$route.query.dir) {
        this.DownloadUrl = data.url
      } else if (data === '') {
        this.DownloadUrl = ''
      }
    })
    Data.$on('tipeState', (data) => {
      this.tipeState = data
    })
  },
  methods: {
    // 接收子组件的值
    childData: function (data) {
    // console.log(data)
    },
    // 接收公共js的值
    demoData: function () {
      Data.$emit('demo', this.demo)
    },
    async ini () {
      var id = this.$route.query.dir.split('_')
      var k = { username:localStorage.getItem('username'), tool_name: id[0], token: localStorage.getItem('token') }
      const { data: res } = await this.$http.post('application/published_tools', k)
      // console.log(res.tool_lists)
      for (var i in res.tool_lists) {
        if (res.tool_lists[i].enName === k.tool_name) break
      }
      if (res.tool_lists[i].enName !== k.tool_name) {
        window.close()
        return 0
      }
      const { data: res1 } = await this.$http.post('application/enName_ID', { tool_name: k.tool_name })
      // console.log(res1)
      k = { tool_id: res1.tool_id }
      const { data: res2 } = await this.$http.post('application/ToolsAttribute', k)
      Data.$emit('DevIniData', res2)
    },
    Run(k) {
      Data.$emit('Manual', k)
    },
    // Download() {
    //   this.$message.closeAll()
    //   this.$message({
    //     message: '正在对文件进行压缩，请稍等片刻',
    //     type: 'success',
    //     showClose: true,
    //     duration: 20,
    //   })
    // },
  },
  props: {},
  components: {
    DataChild,
    Add,
    Interface,
    Attribute,
    AppRunMain
  },
}
</script>

<style lang="less" scoped>
#appRun {
  // 100%显示超出，不知道啥情况
  height: 98%;
  width: 100%;
  background-color: rgba(250, 250, 250);
  #mod1, #mod3 {
    float:left;
    width: 19%;
    background-color: #fff;
    box-shadow: 0.15rem 0.15rem 0.15rem rgba(0,0,0,0.15);
    height: 97%;
    margin: 1% 0;
    border-radius: 1rem;
    box-sizing: border-box;
    border: 0.1rem solid rgba(0,0,0,0.3);
  }
  #mod2{
    float:left;
    width: 60%;
    margin: 1% 1%;
    // background-color: rgba(234,234,234,1);
    background-color: rgba(255,255,255,1);
    box-shadow: 0.15rem 0.15rem 0.15rem rgba(0,0,0,0.15);
    border-radius: 1rem;
    height: 97%;
    box-sizing: border-box;
    border: 0.1rem solid rgba(0,0,0,0.3);
  }
  #mod3 {
    box-sizing: border-box;
    padding: 1rem 0.5rem 0 0.5rem;
    text-align: center;
    > img {
      width: 100%;
    }
  }
  #mod4 {
    width: 98%;
    height: 99%;
    margin: 0 1%;
    border-radius: 1rem;
    background-color: rgba(0,0,0,0);
  }
  .mod4_ini {
    height: 0;
    width: 0;
  }
}
#mod3_3 {
  .el-button {
    font-size: 0.8rem;
    padding: 0 0.2rem;
    height: 1.2rem;
    line-height: 1.2rem;
  }
}
</style>
