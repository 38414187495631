<template>
  <div id="app">
    <!-- 路由占位符 -->
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
import DataChild from './location/Mate/data.vue'
import Data from './location/Mate/data.js'
export default {
  name: 'app',
  provide () {
    // 引用this.getAppUrl()-----切换内外网
    // 引用this.reload()-----刷新
    // window.location.reload() -------刷新整个页面
    return {
      reload: this.reload,
      getAppUrl: this.getAppUrl,
      getAppUrl_1: this.getAppUrl_1,
    }
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  created() {
    this.getAppUrl()
  },
  methods: {
    // 刷新网页的方法
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    //获取后端IP地址
    // 之后如果加后端要先改
    // 1.var url1 = DataChild.httpUrl_3
    // 2.this.getAppUrl_1(url1, 'No.1', 'No.3')
    // 3.然后在getAppUrl_1的判断里面加对应No
    // 4.修改手动切换内外网，只获取一次，根据localStorage.getItem('Https')来判断
    getAppUrl() {
      if (localStorage.getItem('Https') === undefined | localStorage.getItem('Https') === null) {
        var url1 = DataChild.httpUrl_3
        var url2 = DataChild.httpUrl_4
        localStorage.setItem('Https', 'No.5')
        this.getAppUrl_1(url1, 'No.1', 'No.3')
        this.getAppUrl_1(url2, 'No.2', 'No.4')
      }
    },
    async getAppUrl_1(data, A1, A2) {
      try {
        // demo = 0 是测试的时候不会自动切换网络
        if (localStorage.getItem('demo') === '0') {
          const { data: res } = await this.$http.post(data + '/other/geturl', { ip: localStorage.getItem('IP') })
          if (res.code === 200) {
            var a = localStorage.getItem('Https')
            if (res.msg === 'N') {
              if (a !== 'No.1' && a !== 'No.2') {
                localStorage.setItem('Https', A1)
              }
            } else {
              if (a !== 'No.3' && a !== 'No.4') {
                localStorage.setItem('Https', A2)
              }
            }
            this.$message.closeAll()
            window.location.reload()
          }
        }
      } catch (error) {
        setTimeout(async() => { 
          // console.clear()
        }, 1000)
      }
    },
  },
  components: {
    DataChild
  },
}
</script>

<style>
</style>
