<template>
  <!-- <div :style="{ width: screenWidth+ 'px' , height: screeHeight+ 'px' }" id=template> -->
  <div id=Attribute>
    <dataChild v-on:childData="childData" :mainName=mainName></dataChild>
    <el-scrollbar id="MainScrollbar">
      <div style="height:1rem;width:100%;"></div>
      <div id=mod1>
        <div v-for="(item,key) in Single" :key=key>
          <div v-if="(
            key !== 'choiceName' 
            && key !== 'choice' 
            && key !== 'display' 
            && key !== 'widthStyle' 
            && key !== 'preferences' 
            && key !== 'display' 
            && key !== 'search'
            && key !== 'url'
            && key !== 'text_1'
            && key !== 'displayText'
            && key !== 'Demo'
            && key !== 'Disable'
            && key !== 'Rsa'
            && key !== 'size1'
            && key !== 'size2'
            )" 
          class=mod1_1>
            <div class=mod1_2>{{Dictionaries[key]}}</div>
            <el-input
              v-model="Single[key]"
              @focus="text1(key)"
              @blur="text2(key)"
            ></el-input>
          </div>
          <div v-if="(key === 'text_1')" class=mod1_1>
            <div class=mod1_2>{{Dictionaries[key]}}</div>
            <el-input
              type="textarea"
              v-model="Single[key]"
              @focus="text1(key)"
              @blur="text2(key)"
            ></el-input>
          </div>
          <div v-if="(key === 'widthStyle')" class=mod1_1>
            <div class=mod1_2>样式切换:</div>
            <div class=mod1_4>
              <!-- <el-button @click="stylebutton">{{Dictionaries[key]}}</el-button> -->
              <el-button @click="stylebutton" class="iconfont icon-xialakuangbiaodan" v-if="(Single.widthStyle === '1')"></el-button>
              <el-button @click="stylebutton" class="iconfont icon-leimu" v-if="(Single.widthStyle === '2')"></el-button>
            </div>
          </div>
          <div v-if="(key === 'Disable')" class=mod1_1>
            <div class=mod1_2>是否禁用:</div>
            <div class=mod1_4>
              <el-button @click="Disable1" class="iconfont icon-tubiao-duanhengxian" v-if="(Single.Disable === '0')" title="当前默认为禁用"></el-button>
              <el-button @click="Disable1" class="iconfont icon-danxuankuangxuanzhong" v-if="(Single.Disable === '1')" title="当前默认为非禁用"></el-button>
            </div>
          </div>
          <div v-if="(key === 'preferences')" class=mod1_1>
            <div class=mod1_2>{{Dictionaries[key]}}</div>
            <el-input
              v-model="preferencesText"
              :placeholder="Single[key]"
              @focus="text1(key)"
              @blur="text2(key)"
            ></el-input>
          </div>
          <div v-if="(key === 'search')" class=mod1_1>
            <div class=mod1_2>{{Dictionaries[key]}}</div>
            <el-input
              v-model="searchText"
              :placeholder="Single[key].tips"
              @focus="text1(key)"
              @blur="text2(key)"
            ></el-input>
          </div>
          <div v-if="(key === 'choice')" style="height:auto">
            <div class=mod1_1>
              <div class=mod1_2>选项切换:</div>
              <div class=mod1_4>
                <!-- <el-button @click="stylebutton">{{Dictionaries[key]}}</el-button> -->
                <el-button @click="choiceUpload" class="iconfont icon-bianjibi" v-if="(Single.display === '1')"></el-button>
                <el-button @click="choiceUpload" class="iconfont icon-jiantoushangsheng" v-if="(Single.display === '0')"></el-button>
                <!-- <el-button @click="refresh" class="iconfont" style="margin-left: 1rem;">刷新</el-button> -->
              </div>
            </div>
            <div v-if="(Single.display === '0')" class=mod1_1>
              <div class=mod1_2>{{Dictionaries[key]}}</div>
              <el-input
                v-model="Single[key]"
                @focus="text1(key)"
                @blur="text2(key)"
              ></el-input>
            </div>
            <div v-if="(Single.display === '1')" class=mod1_1>
              <el-upload
                drag
                :action="Url + '/application/readExcel'"
                multiple
                :on-success="successList"
              >
                <i class="iconfont icon-excelwenjian"></i>
              </el-upload>
            </div>
          </div>
        </div>
        <!-- 选项参数的修改 -->
        <div v-for="(item, key) in optionProperties" :key='key + item' class=mod1_3>
          <div v-if="(item === 'choiceName' && Single.display === '0')">
            <div v-for="(text,page) in Single.choiceName" :key=page class=mod1_1>
              <div class=mod1_2 style="font-size: 0.5rem;" v-if="(String(Number(page) + 1).length) <= 3">选项{{Number(page) + 1}}:</div>
              <div class=mod1_2 style="font-size: 0.375rem;" v-if="(String(Number(page) + 1).length) === 4">选项{{Number(page) + 1}}:</div>
              <div class=mod1_2 style="font-size: 0.3rem;" v-if="(String(Number(page) + 1).length) >= 5">选项{{Number(page) + 1}}:</div>
              <el-input
                v-model="Single.choiceName[page]"
                @focus="text2(item, page)"
                @blur="text1(item, page)"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <div style="height:1rem;width:100%;"></div>
    </el-scrollbar>
  </div>
</template>

<script>
// $emit----v-on子传父
// 非父子组件进行传值公共js
import DataChild from '../Mate/data.vue'
import Data from '../Mate/data.js'
export default {
  inject: ['reload'],
  // 引用this.reload()-----刷新
  data() {
    return {
      screenWidth: document.body.clientWidth,
      screeHeight: document.body.clientHeight,
      mainName: 'Attribute',
      Single: '',
      Dictionaries: { title: '标题:', enTitle:'表名:', number: '序号:', Disable: '是否禁用', text: '提示文本:', text_1: '提示文本:', choice: '选项数:', maxChoice: '最大可选数:', url: '上传地址:', uploadtext: '上传提示:', preferences: '首选项:', widthStyle: '样式切换:', download: '下载说明:', result: '输出结果:', resultImg: '结果图片:', search: '搜索提示:', dirName: '文件夹名' },
      optionProperties: { 1: 'choiceName' },
      preferences: '',
      preferencesText: '当前为默认首选项',
      searchText: '',
      cache: '',
      Url: ''
    }
  },
  created() {
    this.getURL()
  },
  mounted: function () {
    // 用$on事件来接收参数
    Data.$on('attribute', (data) => {
      this.Single = data
      // console.log(this.Single)
    })
    Data.$on('DevIniData', (data) => {
      // console.log(data)
      // this.$forceUpdate()
    })
  },
  methods: {
    // 接收子组件的值
    childData: function (data) {
    // console.log(data)
    },
    // 接收公共js的值
    demoData: function () {
      Data.$emit('demo', this.demo)
    },
    text1(key, page) {
      Data.$emit('active', this.Single)
      if (key === 'choiceName') {
        // console.log(typeof(this.Single.search) !== 'undefined')
        if (typeof(this.Single.search) !== 'undefined') {
          this.Single.search.choiceName[this.cache] = this.Single.choiceName[page]
        }
      }
      if (key === 'preferences') {
        this.Single.preferences = Number(this.Single.preferences)
        this.preferencesText = this.Single.preferences + 1
      }
      if (key === 'search') {
        this.searchText = this.Single[key].tips
      }
    },
    text2(key, page) {
      // console.log(this.Single)
      Data.$emit('active', this.Single)
      // 选项添加操作
      if (key === 'choice') {
        var k = {}
        for (var j = 0; j < this.Single.choice; j++) {
          // console.log(this.Single.choiceName[j])
          if (typeof(this.Single.choiceName[j]) !== 'undefined') {
            k[j] = this.Single.choiceName[j]
          } else {
            k[j] = '选项' + (Number(j) + 1)
          }
        }
        this.Single.choiceName = k
        this.$forceUpdate()
      }
      if (key === 'preferences') {
        // this.Single.preferences = Number(this.Single.preferences)
        this.Single.preferences = Number(this.preferencesText) - 1
        this.preferencesText = '首选项是选项' + (this.Single.preferences + 1)
      }
      if (key === 'search') {
        this.Single[key].tips = this.searchText
        this.Single[key].text = this.Single[key].tips
      }
      if (key === 'choiceName') {
        // console.log(this.Single.choiceName[page])
        if (typeof(this.Single.search) !== 'undefined') {
          for (var i in this.Single.search.choiceName){
            if (this.Single.search.choiceName[i] === this.Single.choiceName[page]) {
              this.cache = i
              console.log(i, this.Single.choiceName[page])
            }
          }
        }
      }
    },
    stylebutton() {
      if(this.Single.widthStyle === '1') {
        this.Single.widthStyle = '2'
        Data.$emit('active', this.Single)
        return 0
      }
      if(this.Single.widthStyle === '2') {
        this.Single.widthStyle = '1'
        Data.$emit('active', this.Single)
        return 0
      }
    },
    choiceUpload() {
      if (this.Single.display === '0') {
        this.Single.display = '1'
      } else {
        this.Single.display = '0'
      }
      // console.log(this.Single.display)
    },
    successList(response, file, fileList) {
      // console.log(response.data)
      var k = {}
      for (var i in response.data) {
        // console.log(i)
        k[i] = response.data[i]
      }
      // console.log(k)
      this.Single.choice = Number(i) + 1
      // console.log(this.Single.choice)
      this.Single.choiceName = k
      Data.$emit('active', this.Single)
      this.Single.display = '0'
    },
    refresh () {
      Data.$emit('active', this.Single)
      this.$forceUpdate()
    },
    Disable1() {
      if (this.Single.Disable === '0') {
        this.Single.Disable = '1'
      } else {
        this.Single.Disable = '0'
      }
    },
    async getURL() {
      var c = localStorage.getItem('Https')
      if (c === 'No.1') {
        this.Url = DataChild.httpUrl_1
      } else if (c === 'No.2'){
        this.Url = DataChild.httpUrl_2
      } else if (c === 'No.3'){
        this.Url = DataChild.httpUrl_3
      } else if (c === 'No.4'){
        this.Url = DataChild.httpUrl_4
      } else {
        console.clear()
      }
      return c
    }
  },
  props: {
  },
  components: {
    DataChild
  },
}
</script>

<style lang="less" scoped>
#Attribute {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  box-sizing: border-box;
  #mod1{
    height: 100%;
    .mod1_1 {
      height: 1rem;
      width: 95%;
      float: left;
      margin: 0 2.5% 0.5rem 2.5%;
      .mod1_2 {
        box-sizing: border-box;
        width: 25%;
        height: 1rem;
        line-height: 1rem;
        font-size: 0.45rem;
        text-align: right;
        padding-right: 4%;
        float: left; 
      }
      .el-input {
        width: 70%;
        height: 1rem;
        float: left;
        font-size: 0.4rem;
      }
      /deep/ .el-input__inner {
        height: 1rem;
        line-height: 1rem;
      }
      .el-textarea {
        width: 70%;
        float: left;
      }
      /deep/ .el-textarea__inner {
        padding: 0 0.25rem;
        height: 3rem!important;
        line-height: 1rem;
        border-radius: 0.25rem;
      }
      .el-checkbox__inner::after {
        border: 0.05rem solid #FFF;
        height: 0.2rem;
        left: 0.1rem;
        top: 0.05rem;
        width: 0.13rem;
      }
      .mod1_4 {
        width: 75%;
        height: 1rem;
        float: left;
        line-height: 0.6rem;
        /deep/ .el-button:focus, .el-button:hover{
          color: #666;
          border: 1px solid rgba(0, 0, 0, 0.2);
          box-shadow: 0rem 0rem 0.1rem rgba(0,0,0,0.15);
          background-color: #fff;
        }
        .el-button {
          color: #666;
          margin: 0;
          font-size: 0.5rem;
          height: 0.8rem;
          width: 2.5rem;
          border-radius: 0.5rem;
          padding: 0;
          border: 1px solid rgba(0, 0, 0, 0.2);
          // box-shadow: 0rem 0rem 0.1rem rgba(0,0,0,0.25);
        }
      }
    }
    /deep/ .el-input__inner {
      font-size: 0.4rem;
      padding: 0 0.25rem;
      border: 1px solid rgba(0,0,0,0.2);
      border-radius: 0.25rem;
      height: 1rem;
    }
    /deep/ .el-input__inner:hover, /deep/ .el-input__inner:focus {
      border: 1px solid #99CCFF;
    }
    .mod1_3 {
      width: 100%;
      // width: 95%;
      // margin: 0 2.5% 0 2.5%;
      float: left;
    }
    /deep/ .el-upload {
      width: 100%;
    }
    /deep/ .el-upload-dragger {
      width: 5rem;
      height: 1.5rem;
      line-height: 1.5rem;
      margin: 0 auto;
    }
    .iconfont {
      font-size: 1rem;
    }
    /deep/ .el-upload-list__item:first-child {
      margin-top: 0.5rem;
    }
    /deep/ .el-upload-list__item {
      font-size: 0.45rem;
    }
    /deep/ .el-upload-list__item-name {
      box-sizing: border-box;
      margin: 0 0.2rem;
      padding: 0 0.2rem;
    }
  }
  #MainScrollbar {
    height: 100%;
    width: 100%;
    border-radius: 1rem;
    .el-scrollbar{
      height: 100%;
      width: 100%;
    }
    .el-scrollbar__bar{
    right: 0;
    z-index: 10;
    }
    /deep/ .el-scrollbar__wrap{
      overflow-x:hidden;
      // overflow-y:hidden;
    }
    .el-scrollbar__bar.is-vertical {
      width: 12px;
      border-radius: 6px;
    }
    .el-scrollbar__thumb{
      background-color: rgba(0,0,0,0);
    }
    .el-scrollbar__thumb:hover {
      background-color: rgba(0,0,0,0);
    }
  }
}
</style>
