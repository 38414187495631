import Vue from 'vue'
import Router from 'vue-router'
import VueMeta from 'vue-meta'


import Home from '../location/main/主页.vue'
import loginDev from '../location/children/开发者登录.vue'
import mainDev from '../location/children/开发者主页.vue'
import appCenter from '../location/children/应用中心.vue'
import toolUI from '../location/children/UI设计界面.vue'
import Debug from '../location/children/调试主页面.vue'
import DevData from '../location/children/后端编辑.vue'
import AppSquare from '../location/应用广场/应用广场主页.vue'
import AppSquareInternal from '../location/应用广场/应用广场主页-内部页面.vue'
import AppSquareInternal2 from '../location/应用广场/应用广场主页-内部页面2.vue'
import Procedure from '../location/应用广场/流程分析主页.vue'
import AppRun from '../location/应用广场/应用运行页面.vue'
import Test from '../location/测试/功能测试.vue'
import Personal from '../location/个人中心/个人中心主页.vue'
// 测试页面
import NewHome from '../location/新页面/新主页.vue'

Vue.use(Router)

const router = new Router({
  // path网页地址，redirect重定向，component导入的组件
  // { path: '/', redirect: '/login', component: Main },
  // children: [{},{}]子属
  // -----------------------------
  // { path: '/Gadgets', component: Gadgets,
  //     children: [
  //       { path: '/Survival', component: error_web },
  //       { path: '/Re_annotation', component: error_web },
  //       { path: '/BBB', component: error_web }
  //     ]
  // },
  // { path: '/login', component: Login, meta: { title: '登录' } }
  // -----------------------------
  routes: [
    { path: '/', redirect: '/BioinforCloud' },
    { path: '/#', redirect: '/BioinforCloud' },
    { path: '/Home', redirect: '/BioinforCloud' },
    { path: '/BioinforCloud', component: Home },
    { path: '/loginDev', component: loginDev },
    { path: '/Test', component: Test },
    { path: '/toolUI', component: toolUI },
    { path: '/mainDev', component: mainDev, children: [ 
      { path: '/appCenter', component: appCenter }
    ] },
    { path: '/Debug', component: Debug },
    { path: '/DevData', component: DevData },
    { path: '/AppSquare', component: AppSquare },
    { path: '/Procedure', component: Procedure },
    { path: '/appRun', component: AppRun },
    { path: '/Personal', component: Personal, children: [ 
      { path: '/Personal_1', component: Personal }
    ] },
    { path: '/Test2', component: NewHome },
    { path: '/AppSquareInternal', component: AppSquareInternal },
    { path: '/AppSquareInternal2', component: AppSquareInternal2 },
  ],
  mode: 'history'
})

// 导航守卫
router.beforeEach((to, from, next) => {
  // 直接跳转
  if (to.path === '/login') return next()
  if (to.path === '/BioinforCloud') return next()
  if (to.path === '/Main') return next()
  if (to.path === '/Dev_login') return next()
  // if (to.path === '/test') return next()
  // 返回设置----------------------
  if (to.path !== '/mainDev' && from.path === '/appCenter') {
    return next() 
  } else if (to.path === '/mainDev' && from.path === '/appCenter') {
    next('/BioinforCloud')
    return console.clear()
  }
  // -----------------------------
  // 权限控制
  const tokenStr1 = window.sessionStorage.getItem('token')
  const tokenStr2 = window.sessionStorage.getItem('devToken')
  // console.log(!tokenStr1, !tokenStr2)
  // 管理员验证未通过
  // if (!tokenStr2) {
  //   // 用户权限下的登录
  //   if (!tokenStr1) return next('/login')
  //   if (to.path === '/Dev_Main') return next('/Dev_login')
  //   if (to.path === '/AppCenter') return next('/Dev_login')
  //   if (to.path === '/appManage') return next('/Dev_login')
  //   if (to.path === '/Dev') return next('/Dev_login')
  //   if (to.path === '/DevData') return next('/Dev_login')
  //   if (to.path === '/Debug') return next('/Dev_login')
  //   return next()
  // }
  // if (tokenStr2) {
  //   // 管理员权限下的登录
  //   if (to.path === '/Dev_Main') return next()
  //   if (to.path === '/AppCenter') return next()
  //   if (to.path === '/appManage') return next()
  //   if (to.path === '/Dev') return next()
  //   if (to.path === '/DevData') return next()
  //   if (to.path === '/Debug') return next()
  // }
  next()
})

export default router

// 标签设置
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

// 禁止全局路由错误处理打印
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
