<template>
  <div></div>
</template>

<script>
//判断com或者org
var DataWeb = 'org'
DataWeb = 'com'

// 切换开发模式
var a = 'App'
// a = 'Dev'



var DataWeb1 = ''
var DataWebUrl = ''
var DataRunUrl = ''
if (DataWeb === 'org') {
  DataWeb1 = '桂ICP备2022003373号-4'
  DataWebUrl = 'http://192.168.110.234'
  DataRunUrl = 'http://data.bioinforcloud.org.cn:10002'
} else if (DataWeb === 'com') {
  DataWeb1 = '桂ICP备2022003373号-3'
  DataWebUrl = 'http://192.168.110.133'
  DataRunUrl = 'http://data.bioinforcloud.com:10000'
}

if (a === 'App') {
  // 234服务器未调整共享文件夹，会报错
  // 发布端口
  // 内网
  var a1 = DataWebUrl + ':10000'
  var a2 = DataWebUrl + ':10000'
  // var a2 = 'http://192.168.110.234:10000'
  // 外网
  var a3 = DataRunUrl
  var a4 = DataRunUrl
  // var a4 = DataRunUrl
} else {
  // 开发端口
  // 内网
  var a1 = DataWebUrl + ':10002'
  var a2 = DataWebUrl + ':10002'
  // var a2 = 'http://192.168.110.234:10002'
  // 外网
  var a3 = DataWebUrl + ':10002'
  var a4 = DataWebUrl + ':10002'
  // var a4 = 'http://192.168.110.234:10002'
}


const httpUrl_1 = a1
const httpUrl_2 = a2
const httpUrl_3 = a3
const httpUrl_4 = a4
const DataWeb2 = DataWeb1

export default {
  httpUrl_1,
  httpUrl_2,
  httpUrl_3,
  httpUrl_4,
  DataWeb2,
  data() {
    return {
      demoData: 'this is a demo'
    }
  },
  props: {
    mainName: { default: 'My' }
  },
  created() {
    this.demo()
    this.initialization()
  },
  mounted: function () {},
  methods: {
    demo() {
      this.$emit('childData', this.demoData)
    },
    initialization() {
      // setTimeout(() => {}, 500)
      // if (this.mainName === 'Template') console.log('这个是模板页面')
      // if (this.mainName === 'Home') console.log('这个是主页')
      // if (this.mainName === 'carousel') console.log('这个是走马灯页面')
      // if (this.mainName === 'topnav') console.log('这个是顶部导航栏')
    }
  },
  components: {},
  name: 'dataChild',
}
</script>

<style lang="less" scoped>
</style>
