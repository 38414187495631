<template>
  <div style="width:100%;height:100%;">
    <div v-if='(size === 1)'>
      <!-- 1920宽的尺寸 -->
      <div :style="{ width: screenWidth+ 'px' , height: screeHeight+ 'px' }" id=template>
      <!-- <div style="width:100%,height:100%"> -->
        <dataChild v-on:childData="childData" :mainName=mainName></dataChild>
        <img src="../../assets/404.jpg" alt="" style="width:100%;height:auto">
      </div>
    </div>
    <div v-if='(size === 0)' style="width:100%;height:100%;">
      <!-- rem的自适应尺寸 -->
      <!-- 头部按钮 -->
      <div id="modB">
        <div id="modB_3"></div>
        <div id="modB_1">
          <!-- <el-button type="primary">应用</el-button> -->
          <!-- <el-button type="primary">流程</el-button> -->
          <el-button type="primary" style="float: right;" @click="delect2()" v-if="(this.delDisplay === 0)">批量删除</el-button>
          <el-button type="primary" style="float: right;" @click="delect2()" v-if="(this.delDisplay === 1)">确认删除</el-button>
          <el-button type="success" style="float: right;" @click="search1(search)">搜索</el-button>
          <el-input v-model="search"></el-input>
        </div>
        <!-- 应用列表 -->
        <div id="modB_2">
          <!-- 应用顶部 -->
          <div class="modB_5">
            <div class="modB_4" style="width: 31px"></div>
            <div class="modB_4" style="width: 192px">应用名称</div>
            <div class="modB_4" style="width: 164px">任务号</div>
            <div class="modB_4" style="width: 164px">运行状态</div>
            <!-- 375px+167px -->
            <div class="modB_4" style="width: 542px"></div>
            <!-- 400px-167px -->
            <div class="modB_4" style="width: 233px">
              <!-- (400px-66px)/2 = 167px -->
              <img src="../../assets/个人中心素材/任务中心操作标题图标.jpg" alt="">
              <div class="modB_4_1">操作</div>
            </div>
          </div>
          <el-scrollbar id="modB_2_1">
            <div ID="modB_6">
              <div v-for="i in this.appList2" :key="i.ID">
                <div class="modB_6_1" style="width: 31px">
                  <el-checkbox v-model="delectList[i.ID]" size="large" v-if="(delDisplay === 1)"/>
                </div>
                <div class="modB_6_1" style="width: 192px">{{i.appName}}</div>
                <div class="modB_6_1" style="width: 164px">{{i.appID}}</div>
                <div class="modB_6_1" style="width: 164px">{{i.state}}</div>
                <div class="modB_6_1" style="width: 375px"></div>
                <div class="modB_6_1" style="width: 400px">
                  <el-button @click="appRun(i)">运行页面</el-button>
                  <el-button @click="download1(i)">下载日志</el-button>
                  <el-button @click="appDir(i,2)">应用文件夹</el-button>
                  <el-button @click="copyDir(i)" style="width: 100px">拷贝到共享盘</el-button>
                  <el-button @click="delect1(i)" style="width: 35px;color: #fe2122">删除</el-button>
                </div>
                <div class="modB_6_2" style="background-color: #f4fbfd;" v-if="(i.ID%2 === 0)"></div>
                <div class="modB_6_2" style="background-color: #fff;" v-if="(i.ID%2 === 1)"></div>
              </div>
            </div>
          </el-scrollbar>
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="pagesize"
            layout="prev, pager, next"
            :total="appList.length">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 悬浮栏模板 -->
    <div  id=modB_7 v-if="(this.dirDis === 1)">
      <div id=modB_7_1>
        <div style="height: 20px"></div>
        <div  id=modB_7_2>
          <el-upload
            class="upload-demo"
            :action="Url + '/application/upload6/' + this.username + '/' + this.appPath[this.dirNum]"
            :on-change="upload"
            :show-file-list='false'
            :multiple='true'
            title='上传'
            :on-success='(response, file, fileList) => successDisplay (response, file, fileList)'
            :on-error='(err, file, fileList) => uploadError(err, file, fileList)'
            :on-progress='(event, file, fileList) => onUploadProgress(event, file, fileList)'
          >
            上传
          </el-upload>
          <el-button type="success" @click="appDir(0, 11)">下载</el-button>
          <el-button type="danger" @click="appDir(0, 12)">删除</el-button>
          <el-button type="warning" @click="appDir(0,3)">关闭</el-button>
          <div id=modB_7_2_1>{{this.uploadText}}</div>
          <div>{{this.appName}}</div>
        </div>
        <div  id=modB_7_3>
          <div class=modB_7_5 style="width: 10px;height: 1px;"> </div>
          <div class=modB_7_5 @click="appDir(0,6)">
            <img src="../../assets/个人中心素材/左.png" alt="">
          </div>
          <div class=modB_7_5 @click="appDir(0,7)">
            <img src="../../assets/个人中心素材/右.png" alt="">
          </div>
          <div class=modB_7_5 @click="appDir(0,8)">
            <img src="../../assets/个人中心素材/刷新.png" alt="">
          </div>
          <div class=modB_7_5>
            <img src="../../assets/个人中心素材/隔断.png" alt="">
          </div>
          <div class=modB_7_5 style="width: 300px;text-align: left;">文件：{{this.focusing}}</div>
          <div class=modB_7_5>
            <img src="../../assets/个人中心素材/隔断.png" alt="">
          </div>
          <div class=modB_7_5 style="width: 700px;text-align: left;">路径：{{this.appPath[this.dirNum]}}</div>
        </div>
        <div  id=modB_7_4>
          <el-scrollbar style="width: 100%;height: 100%;">
            <div class=modB_7_6 v-for="i in this.dirList[this.dirNum]" :key="i.ID">
              <div style="height: 87px;" >
                <!-- 文件夹 -->
                <img src="../../assets/个人中心素材/文件夹.png" alt="" @dblclick="appDir(i,5)" @click="appDir(i,13)" v-if="(i.dir === 1)">
                <!-- 一般文件 -->
                <img src="../../assets/个人中心素材/一般文件.png" alt="" @click="appDir(i,9)" v-if="(i.dir !== 1 & (i.type !== 'zip' & i.type !== 'rds'))">
                <!-- 压缩包 -->
                <img src="../../assets/个人中心素材/zip.png" alt="" @click="appDir(i,10)" v-if="(i.dir !== 1 & (i.type === 'zip' | i.type === 'rds'))">
              </div>
              <div style="height: 51px;" id=modB_7_4_1>{{i.name}}</div>
            </div>
            <!-- 暂无文件 -->
            <div class=modB_7_6>
              <div style="height: 87px;" v-if="(dirList[dirNum].length === 0)" @dblclick="appDir(0,6)">
                <img src="../../assets/个人中心素材/一般文件.png" alt="">
              </div>
              <div style="height: 51px;" v-if="(dirList[dirNum].length === 0)">暂无文件</div>
            </div>
          </el-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// $emit----v-on子传父
// 非父子组件进行传值公共js
import DataChild from '../Mate/data.vue'
import Data from '../Mate/data.js'
export default {
  // 引用this.getAppUrl()-----切换内外网
  // 引用this.reload()-----刷新
  // window.location.reload() -------刷新整个页面
  inject: ['reload', 'getAppUrl'],
  // 页面标签设置(在模板里面修改文件名【package.json文件修改后重启可设置主标签】)
  metaInfo: {
    title: '应用中心',
    titleTemplate: 'BioinforCloud - %s',
    htmlAttrs: {
      lang: 'ch',
      amp: true
    }
  },
  data() {
    return {
      screenWidth: document.body.clientWidth,
      screeHeight: document.body.clientHeight,
      mainName: 'Template',
      size: 0,
      appList: [],
      appList2: [],
      appList3: [],
      // 每页显示数目
      pagesize: 10,
      currentPage: 1,
      search: '',
      Url: '',
      username: '',
      uploadpercent: '',
      uploadText: '',
      uploadfilename: '',
      dirDis: 0,
      delectList: [''],
      delDisplay: 0,
      // 文件夹参数
      dirList: [],
      // 当前层数
      dirNum: 0,
      // 打开的应用
      appName: '',
      // 当前路径
      appPath: '',
      // 聚焦
      focusing: '',
      // 是否可以打开result
      resultTrue: 0,
      // 删除的状态
      rmState: 0,
      // 当前点击的是不是文件夹
      dirState: 0,
      // 最后打开的文件夹提交的信息
      dirData1: '',
      // 点击文件夹顺序
      dirData2: [],
    }
  },
  created() {
    this.sizeData()
    setTimeout(async() => { 
      this.getURL()
      this.username = localStorage.getItem('username')
      this.appInforTime()
    }, 1000)
  },
  mounted: function () {
    // mounted一直监控？可改位置----目前此方法还不能正确运用
    // 用$on事件来接收参数
    var that = this
    Data.$on('demo', (data) => {
      console.log(data)
    })
  },
  methods: {
    childData: function (data) {
      // 接收子组件的值
      // console.log(data)
    },
    demoData: function () {
      // 接收公共js的值
      Data.$emit('demo', this.demo)
    },
    sizeData() {
      // 默认设置
      this.size = 0
      // 大小设定
      // if (this.screenWidth >= 1920) this.size = 1
    },
    async getURL() {
      var c = localStorage.getItem('Https')
      if (c === 'No.1') {
        this.Url = DataChild.httpUrl_1
      } else if (c === 'No.2'){
        this.Url = DataChild.httpUrl_2
      } else if (c === 'No.3'){
        this.Url = DataChild.httpUrl_3
      } else if (c === 'No.4'){
        this.Url = DataChild.httpUrl_4
      } else {
        console.clear()
      }
      this.getUrl = this.Url
      return c
    },
    // 定时刷新状态
    appInforTime() {
      this.appInfor().then(res => {
        if (res.code === 200){
          this.search1(this.search)
          setTimeout(async() => { 
            this.appInforTime()
          }, 1200000)
        }
      })
    },
    // 应用运行信息
    async appInfor() {
      var k = { 'username': localStorage.getItem('username') , 'toolDir': 'App' }
      const { data: res } = await this.$http.post('application/appInfor', k)
      this.appList = JSON.parse(JSON.stringify(res))
      this.appList3 = this.appList
      setTimeout(async() => { 
        this.handleCurrentChange(this.currentPage)
      }, 200)
      return {'code': 200}
    },
    handleCurrentChange(currentPage) {
      this.delDisplay = 0
      this.delectList = ['']
      this.currentPage = currentPage;
      var i = 0
      var j = 0
      var k = 0
      var l = 0
      this.appList2 = []
      while ( i < this.pagesize) {
        k = this.currentPage * this.pagesize
        j = (this.currentPage - 1) * this.pagesize - 1
        l = j + i + 2
        if (l > this.appList.length) break
        this.appList2[i] = this.appList[l-1]
        this.appList2[i]['ID'] = i
        i = i + 1
      }
      return {'code': 200}
    },
    search1(data) {
      this.appList2 = []
      var k = 0
      for (var i in this.appList3) {
        for (var j in this.appList3[i]) {
          if (String(this.appList3[i][j]).indexOf(String(data)) >= 0) {
            this.appList2[k] = this.appList3[i]
            this.appList2[k]['ID'] = k
            k = k + 1
            break
          }
        }
      }
    },
    async appRun(data) {
      const a = this.$router.resolve({ path: '/appRun', query: { dir: data['appName'] + '_' + data['appID'] } })
      window.open(a.href,'_blank')
    },
    async download1(data) {
      // http://192.168.110.133:10002/application/DownloadFileA/DEbylimma.logs/publicUser/0/0_Alice/Application/DEbylimma_20221222152600/Result/
      var k = { tool_name: data['appName'], tool_id: 'Null', username: localStorage.getItem('username'), Filedir: 'App', dir: data['appName'] + '_' + data['appID'], mod: 0 }
      const { data: res } = await this.$http.post('application/downloadLogs', k)
      if (res.code === 200) {
        if (res.runLogs !== 'NULL') {
          var url1 = this.Url + '/application/DownloadFileA/' + res.runLogs + '/' + res.runLogsPath
          window.open(url1)
        }
        if (res.runTxt !== 'NULL') {
          var url2 = this.Url + '/application/DownloadFileA/' + res.runTxt + '/' + res.runTxtPath
          window.open(url2)
        }
      } else {
        this.$message.closeAll()
        this.$message({
          type: 'error',
          message: '暂未找到运行日志。'
        });
      }
    },
    delect1(data){
      var k = []
      k[0] = data['appName'] + '_' + data['appID']
      this.delect_main(k)
    },
    delect2(){
      if (this.delDisplay === 0) {
        this.delDisplay = 1
        this.delectList = ['']
        this.$message.closeAll()
        this.$message({
          type: 'success',
          message: '请勾选需要删除的应用',
          duration: 3000,
        });
      } else if (this.delDisplay === 1) {
        var b = 0
        for (var a in this.delectList){
          if (this.delectList[a] === true)
          b = b + 1
        }
        if (b < 1) {
          this.$message.closeAll()
          this.$message({
            type: 'success',
            message: '请勾选需要删除的应用',
            duration: 3000,
          });
          return 0
        } else {
          var k = []
          var j = 0
          for (var i in this.delectList) {
            if (this.delectList[i] === true) {
              k[j] = this.appList2[i].appName + '_' + this.appList2[i].appID
              j = j + 1
            }
          }
          this.delectList = ['']
          this.delDisplay = 0
          this.delect_main(k)
        }
      }
    },
    async delect_main(data){
      this.$message.closeAll()
      this.$message({
        type: 'success',
        message: '正在删除，请稍后!',
        duration: 0,
      });
      var k = { username: localStorage.getItem('username'), token: localStorage.getItem('token'), data: data }
      const { data: res } = await this.$http.post('application/clearAppDir', k)
      if (res.code === 200) {
        this.$message.closeAll()
        this.$message({
          type: 'success',
          message: '删除成功。'
        });
        this.reload()
      }
    },
    async appDir(data, mod) {
      if (mod === 0){
      } else if(mod === 1) {
        // 测试UI
        this.dirDis = 1
      } else if(mod === 2) {
        // 初次点开
        this.dirData1 = data
        this.uploadpercent = ''
        this.uploadText = ''
        this.uploadfilename = ''
        this.$message({
          type: 'success',
          message: '正在打开文件夹请稍等',
          duration: 0,
        });
        this.appName = data['appName']
        this.dirNum = 0
        var a = []
        this.dirList[0] = []
        a[0] = data['appName'] + '_' + data['appID']
        if (data.state === '等待压缩' | data.state === '运行完成') {
          this.resultTrue = 1
        } else {
          this.resultTrue = 0
        }
        var b = { username: localStorage.getItem('username'), token: localStorage.getItem('token'), data: a }
        const { data: res } = await this.$http.post('application/AppDir', b)
        if (res.code == 200) {
          var j = 0
          for (var i in res.filelist) {
            var fileType = ''
            if (res.filelist[i].dir === 1) {
              fileType = '文件夹'
            } else {
              for (var k in res.filelist[i].name.split('.')) {
                fileType = res.filelist[i].name.split('.')[k]
              }
            }
            this.dirList[this.dirNum][j] = {'ID': j, 'dir': res.filelist[i].dir, 'name': res.filelist[i].name, 'path': res.filelist[i].path, 'filelist': res.filelist[i].filelist, 'type': fileType}
            j = j + 1
          }
          this.appPath = []
          this.appPath[this.dirNum] = data['appName'] + '_' + data['appID'] + '/'
          this.fileSort()
          this.$message.closeAll()
          this.dirDis = 1
        }
      } else if(mod === 3) {
        // 关闭
        this.dirDis = 0
      } else if(mod === 4) {
        // 上传
      } else if(mod === 5) {
        // 点击文件夹
        this.dirData2[this.dirNum] = data
        if (data.name === 'Result') {
          if (this.resultTrue === 0) {
            this.$message.closeAll()
            this.$message({
              type: 'success',
              message: '应用还未运行完成',
              duration: 5000,
            });
            return 0
          }
        }
        var j = 0
        this.dirNum = this.dirNum + 1
        this.dirList[this.dirNum] = []
        this.appPath[this.dirNum] = data.path
        for (var i in data.filelist) {
          var fileType = ''
          if (data.filelist[i].dir === 1) {
            fileType = '文件夹'
          } else {
            for (var k in data.filelist[i].name.split('.')) {
              fileType = data.filelist[i].name.split('.')[k]
            }
          }
          this.dirList[this.dirNum][j] = {'ID': j, 'dir': data.filelist[i].dir, 'name': data.filelist[i].name, 'path': data.filelist[i].path, 'filelist': data.filelist[i].filelist, 'type': fileType}
          j = j + 1
          this.fileSort()
        }
      } else if(mod === 6) {
        // 左
        if (this.dirList[this.dirNum - 1] === undefined) {
        } else {
          this.dirNum = this.dirNum - 1
        }
        this.focusing = ''
      } else if(mod === 7) {
        // 右
        if (this.dirList[this.dirNum + 1] === undefined) {
        } else {
          this.dirNum = this.dirNum + 1
        }
        this.focusing = ''
      } else if(mod === 8) {
        // 刷新
        // this.reload()
        var k = {ID: 1, appID: "20230108233409", appName: "scBasePipeline2.1", state: "等待压缩"}
        this.appDir(data, 2)
      } else if(mod === 9) {
        // 一般文件
        this.dirState = 0
        this.focusing = data.name
      } else if(mod === 10) {
        // 压缩包
        this.dirState = 0
        this.focusing = data.name
      } else if(mod === 11) {
        if (this.dirState === 1) {
          this.$message.closeAll()
          this.$message({
            type: 'error',
            message: '文件夹不可下载',
            duration: 3000,
          });
          return 0
        }
        // 下载
        var k = { username: localStorage.getItem('username'), token: localStorage.getItem('token'), data: data ,filePath: this.appPath[this.dirNum], fileName: this.focusing, mod: 'Download'}
        const { data: res } = await this.$http.post('application/AppDirRD', k)
        var url = this.Url + '/application' + res.Url
        window.open(url)
      } else if(mod === 12) {
        // 删除
        if (this.focusing === 'Result' | this.focusing === 'Download' | this.focusing === 'TempData') {
          this.$message.closeAll()
          this.$message({
            type: 'error',
            message: '该文件夹不可删除',
            duration: 3000,
          });
          return 0
        }
        if (this.rmState === 1) {
          this.$message.closeAll()
          this.$message({
            type: 'success',
            message: '正在删除，请稍后!',
            duration: 0,
          });
          return 0
        }
        this.rmState = 1
        this.$message.closeAll()
        this.$message({
          type: 'success',
          message: '正在删除',
          duration: 0,
        });
        var k = { username: localStorage.getItem('username'), token: localStorage.getItem('token'), data: data ,filePath: this.appPath[this.dirNum], fileName: this.focusing, mod: 'Remove', dir: this.dirState}
        const { data: res } = await this.$http.post('application/AppDirRD', k)
        if (res.code === 200) {
          this.$message.closeAll()
          this.$message({
            type: 'success',
            message: '删除成功',
            duration: 3000,
          });
          this.rmState = 0
          //刷新文件列表
          var k = this.dirData1
          this.dir_Reload1(k)
        }
      } else if(mod === 13) {
        // 单击文件夹
        this.dirState = 1
        this.focusing = data.name
      } else {
        this.$message.closeAll()
        this.$message({
          type: 'success',
          message: '类型错误',
          duration: 3000,
        });
      }
    },
    async dir_Reload1(data) {
      // 刷新文件列表
      // 1.刷新删除后的文件列表
      this.appName = data['appName']
      var a = []
      a[0] = data['appName'] + '_' + data['appID']
      if (data.state === '等待压缩' | data.state === '运行完成') {
        this.resultTrue = 1
      } else {
        this.resultTrue = 0
      }
      var b = { username: localStorage.getItem('username'), token: localStorage.getItem('token'), data: a }
      const { data: res } = await this.$http.post('application/AppDir', b)
      if (res.code === 200) {
        var j = 0
        for (var i in res.filelist) {
          var fileType = ''
          if (res.filelist[i].dir === 1) {
            fileType = '文件夹'
          } else {
            for (var k in res.filelist[i].name.split('.')) {
              fileType = res.filelist[i].name.split('.')[k]
            }
          }
          this.dirNum = 0
          this.dirList[this.dirNum][j] = {'ID': j, 'dir': res.filelist[i].dir, 'name': res.filelist[i].name, 'path': res.filelist[i].path, 'filelist': res.filelist[i].filelist, 'type': fileType}
          j = j + 1
        }
        // 2.模拟重新点击到当前文件夹， 未完成！！
        for(var c in this.dirData2) {
          // setTimeout(async() => {
          //   this.dir_Reload2(this.dirData2[c, res.filelist])
          // }, 1000)
        }
        this.fileSort()
        this.$message.closeAll()
      }
    },
    dir_Reload2 (data, data2) {
      // 点击文件夹 有问题！！！
      var j = 0
      this.dirNum = this.dirNum + 1
      this.appPath[this.dirNum] = data.path
      for (var i in data.filelist) {
        var fileType = ''
        if (data.filelist[i].dir === 1) {
          fileType = '文件夹'
        } else {
          for (var k in data.filelist[i].name.split('.')) {
            fileType = data.filelist[i].name.split('.')[k]
          }
        }
        this.dirList[this.dirNum][j] = {'ID': j, 'dir': data.filelist[i].dir, 'name': data.filelist[i].name, 'path': data.filelist[i].path, 'filelist': data.filelist[i].filelist, 'type': fileType}
        j = j + 1
        this.fileSort()
      }
    },
    fileSort() {
      var b = 0
      // 按a-z排序 排序还要修改，考虑在前端还是后端排序
      // var b = 0
      // for (var a in this.dirList[this.dirNum]) {
      //   b = 0
      //   while(b < this.dirList[this.dirNum].length - 1) {
      //     if (this.dirList[this.dirNum][b].name > this.dirList[this.dirNum][b + 1].name) {
      //       var t = this.dirList[this.dirNum][b]
      //       this.dirList[this.dirNum][b] = this.dirList[this.dirNum][b + 1]
      //       this.dirList[this.dirNum][b + 1] = t
      //     }
      //     b = b + 1
      //   }
      // }
      // 按照文件夹排序
      for (var a in this.dirList[this.dirNum]) {
        b = 0
        while(b < this.dirList[this.dirNum].length - 1) {
          if (this.dirList[this.dirNum][b].dir < this.dirList[this.dirNum][b + 1].dir) {
            var t = this.dirList[this.dirNum][b]
            this.dirList[this.dirNum][b] = this.dirList[this.dirNum][b + 1]
            this.dirList[this.dirNum][b + 1] = t
          }
          b = b + 1
        }
      }
    },
    async copyDir(data) {
      this.$message.closeAll()
      this.$message({
        type: 'success',
        message: '正在拷贝，请稍后!',
        duration: 0,
      });
      var k = { username: localStorage.getItem('username'), token: localStorage.getItem('token'), data: data['appName'] + '_' + data['appID'] }
      const { data: res } = await this.$http.post('application/copyAppDir', k)
      if (res.code === 200) {
        this.$message.closeAll()
        this.$message({
          type: 'success',
          message: '拷贝成功。'
        });
      }
    },
    test(data) {
      console.log('测试功能' + data)
    },
    upload () {
    },
    successDisplay (response, file, fileList) {
      this.$message.closeAll()
      this.uploadfilename = response.filename
    },
    onUploadProgress(event, file, fileList) {
      this.$message.closeAll()
      this.uploadpercent = event.percent.toFixed(1)
      this.uploadText = '正在上传:' + this.uploadfilename + '，上传进度: ' + this.uploadpercent + '%'
    },
    uploadError (err, file, fileList) {
      this.$message.closeAll()
      this.$message({
        type: 'error',
        message: '上传失败',
        duration: 3000,
      });
    },
  },
  props: {},
  components: {
    DataChild
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-scrollbar{
  height: calc(100% - 50px - 36px);
}
/deep/ .el-scrollbar__bar{
  right: 0rem;
  z-index: 99;
}
/deep/ .el-scrollbar__wrap{
  overflow: scroll;
  overflow-x:auto;
  overflow-y:auto;
  position: relative;
  z-index: 25;
}
/deep/ .el-scrollbar__bar.is-vertical {
  width: 2px;
  border-radius: 1px;
}
/deep/ .el-scrollbar__thumb{
  background-color: rgba(0, 0, 0, 0.1);
}
/deep/ .el-scrollbar__thumb:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
#modB {
  height: 100%;
  width: 100%;
}
#modB_1{
  margin: 0 calc((100% - 1352px)/2) 20px calc((100% - 1352px)/2);
  width: 100%;
  text-align: left;
  height: 41px;
  width: auto;
  .el-button{
    height: 41px;
    margin-left: 10px;
  }
  .el-input{
    float: right;
    width: 300px;
  }
}
#modB_2{
  width: 1352px;
  // height: calc(100% - 145px + 25px);
  height: calc(55px + 55px * 10 + 36px);
  margin: auto;
  background-color: #fff;
}
#modB_3{
  height: 20px;
}
.modB_4{
  height: 55px;
  line-height: 55px;
  font-size: 18px;
  text-align: center;
  float: left;
  > img {
    height: 20px;
    width: auto;
    margin: 20px 5px;
    float: left;
  }
  .modB_4_1{
    float: left;
  }
}
.modB_5{
  height: 55px;
  width: 100%;
}
#modB_6{
  width: 100%;
  height: 100%;
}
.modB_6_1{
  height: 55px;
  line-height: 55px;
  float: left;
  .el-button{
    background-color: rgba(0,0,0,0);
    border: 0;
    height: 100%;
    width: 65px;
    margin: 0 5px;
    padding: 0;
  }
}
.modB_6_2{
  height: 55px;
}
#modB_7{
  width: calc(100% - 180px);
  height: 100%;
  background-color: rgba(200,200,200,0.3);
  position: absolute;
  z-index: 99;
  top: 0;
  #modB_7_1{
    position: relative;
    width: 1352px;
    height: calc(100% - 27px - 27px - 41px);
    background-color: #fff;
    margin: auto;
    top: calc((100% - (100% - 27px - 27px - 41px))/2);
    border-radius: 10px;
  }
  #modB_7_2{
    margin: 0px 31px;
    .el-button, .upload-demo {
      margin-right: 10px;
      float: left;
      width: 92px;
      height: 36px;
      border-radius: 18px;
    }
    > div {
      float: right;
      line-height: 36px;
      font-size: 18px
    }
    .upload-demo {
      background-color: #2fa8e1;
      font-size: 14px;
      color: #fff;
    }
    .upload-demo:active, .upload-demo:hover {
      background-color: #2fa8e1;
      color: #fff;
    }
    #modB_7_2_1 {
      float: left;
      margin-left: 50px;
    }
  }
  #modB_7_3 {
    height: 42px;
    line-height: 42px;
    width: 100%;
    float: left;
    margin: 10px 31px;
    width: calc(100% - 62px);
    border-radius: 5px;
    border: 1px rgba(222,222,222,0.5) solid;
    > div>img {
      height: 18px;
      margin: 0 5px;
      margin-top: 12px
    }
  }
  .modB_7_5 {
    float: left;
    margin: 0 10px;
    white-space: nowrap;
    /* 2.超出的部分隐藏 */
    overflow: hidden;
    /* 3.文字用省略号替代超出的部分 */
    text-overflow: ellipsis;
  }
  #modB_7_4{
    float: left;
    width: calc(100% - 62px);
    margin: 10px 31px;
    height: calc(100% - 36px - 42px - 20px - 40px - 20px);
  }
  .modB_7_6 {
    height: 138px;
    width: 168px;
    float:left;
    margin: 24px 18px;
    > div>img {
      width:96px;
      height:87px;
      margin: 0 auto;
    }
    > div {
      font-size: 16px;
      line-height: calc(30px + 8px)
    }
  }
  #modB_7_4_1 {
    // white-space: nowrap;
    // overflow: hidden;
    // 自动换行
    word-break: break-all;
  }
}
</style>
