<template>
  <!-- <div :style="{ width: screenWidth+ 'px' , height: screeHeight+ 'px' }" id=template> -->
  <div style="width:100%,height:100%" id=appRunMain>
    <dataChild v-on:childData="childData" :mainName=mainName></dataChild>
    <div id=mod4>
      <div class=mod4_1 v-if="(this.tool_msg.enName.length > 0)" style="position: absolute; right:22%; top: 1rem; font-size: 0.5rem; color: rgba(64,158,255,0.5);" @click=DownloadInformation()>下载应用说明</div>
      <div class=mod4_1 style="font-size: 0.8rem;">{{this.tool_msg.cnName}}</div>
      <div class=mod4_1>{{this.tool_msg.enName}}</div>
      <div id=mod4_3>
        <el-button @click=displayBox v-if="(this.displayData === '1')"></el-button>
        <el-button @click=displayBox style="background-color: #FF9966;" v-if="(this.displayData === '2')"></el-button>
      </div>
    </div>
    <el-scrollbar id="MainScrollbar">
      <el-col id=mod3 :span="12">
        <div style="width:100%;">
          <div v-for="(item,key) in addlist" :key=key>
              <!-- 单行文本 -->
              <el-card :title=attribute.member[key].data.title :name="key" v-if="(item === '单行文本')" class=singleLine @click.native="handleChange(key)" shadow="hover" :style="{ width: String(widthStyle[attribute.member[key].data.widthStyle].width), height: String(widthStyle[attribute.member[key].data.widthStyle].height) }">
                <div class="mod3_1_1" style="width: 100%; padding: 0; height: 0">
                  <el-button class="iconfont icon-tubiao-duanhengxian littleButton2" @click="Disable(key)" title='禁用'></el-button>
                </div>
                <div style="height:1.5rem;line-height:1.5rem;font-size:0.6rem;text-align: center;">{{attribute.member[key].data.title}}</div>
                <div :style="{ height: String(widthStyle[attribute.member[key].data.widthStyle].insideHeight) }">
                  <el-input
                    v-model="textInitial[key]"
                    :placeholder="attribute.member[key].data.text"
                    @focus="text1(key)"
                    @blur="text2(key)"
                  ></el-input>
                </div>
                <div style="height:0.5rem"></div>
                <div class="mod3_1">
                  <el-button class="iconfont icon-xiazai" title='下载Demo' @click="downloadCard(key)" style="width: 33.3%;"></el-button>
                  <el-button class="iconfont icon-shezhi" title='重置' @click="reCard(key)" style="width: 33.3%;"></el-button>
                  <el-button class="iconfont icon-lifangti" title='Demo' @click="runDemo(key)" style="width: 33.3%;"></el-button>
                </div>
                <div class="mod3_2" v-if="(attribute.member[key].data.Disable === '0')" title="被禁用" @click="Disable(key)"></div>
              </el-card>
              <!-- 多行文本 -->
              <el-card :title=attribute.member[key].data.title :name="key" v-if="(item === '多行文本')" class=singleLine @click.native="handleChange(key)" shadow="hover" :style="{ width: String(widthStyle[attribute.member[key].data.widthStyle].width), height: String(widthStyle[attribute.member[key].data.widthStyle].height) }" id=modLine>
                <div class="mod3_1_1" style="width: 100%; padding: 0; height: 0">
                  <el-button class="iconfont icon-tubiao-duanhengxian littleButton2" @click="Disable(key)" title='禁用'></el-button>
                </div>
                <div style="height:1.5rem;line-height:1.5rem;font-size:0.6rem;text-align: center;">{{attribute.member[key].data.title}}</div>
                <el-scrollbar :style="{ height: String(widthStyle[attribute.member[key].data.widthStyle].insideHeight) }">
                  <el-input
                    type="textarea"
                    v-model="textInitial[key]"
                    :placeholder="attribute.member[key].data.text_1"
                    autosize
                    resize="none"
                    @focus="text1(key)"
                    @blur="text2(key)"
                  ></el-input>
                </el-scrollbar>
                <div style="height:0.5rem"></div>
                <div class="mod3_1">
                  <el-button class="iconfont icon-xiazai" title='下载Demo' @click="downloadCard(key)" style="width: 33.3%;"></el-button>
                  <el-button class="iconfont icon-shezhi" title='重置' @click="reCard(key)" style="width: 33.3%;"></el-button>
                  <el-button class="iconfont icon-lifangti" title='Demo' @click="runDemo(key)" style="width: 33.3%;"></el-button>
                </div>
                <div class="mod3_2" v-if="(attribute.member[key].data.Disable === '0')" title="被禁用" @click="Disable(key)"></div>
              </el-card>
              <!-- 单选 -->
              <el-card :title=attribute.member[key].data.title :name="key" v-if="(item === '单项选择')" class=singleLine @click.native="handleChange(key)" shadow="hover" :style="{ width: String(widthStyle[attribute.member[key].data.widthStyle].width), height: String(widthStyle[attribute.member[key].data.widthStyle].height) }">
                <div class="mod3_1_1" style="width: 100%; padding: 0; height: 0">
                  <el-button class="iconfont icon-tubiao-duanhengxian littleButton2" @click="Disable(key)" title='禁用'></el-button>
                </div>
                <div style="height:1.5rem;line-height:1.5rem;font-size:0.6rem;text-align: center;">{{attribute.member[key].data.title}}</div>
                <div :style="{ height: String(widthStyle[attribute.member[key].data.widthStyle].insideHeight) }">
                  <el-radio-group v-model="attribute.member[key].data.preferences">
                    <div v-for="(text, page) in Number(attribute.member[key].data.choice)" :key="page + key">
                      <el-radio
                        :label="page"
                        :style="{
                          width: widthStyle[attribute.member[key].data.widthStyle].choice.width * character(attribute.member[key].data.choiceName[page], 10) + character(attribute.member[key].data.choiceName[page], 10) - 1 + '%'
                        }"
                      >
                        {{attribute.member[key].data.choiceName[page]}}
                      </el-radio>
                    </div>
                  </el-radio-group>
                </div>
                <div style="height:0.5rem"></div>
                <div class="mod3_1">
                  <el-button class="iconfont icon-xiazai" title='下载Demo' @click="downloadCard(key)" style="width: 33.3%;"></el-button>
                  <el-button class="iconfont icon-shezhi" title='重置' @click="reCard(key)" style="width: 33.3%;"></el-button>
                  <el-button class="iconfont icon-lifangti" title='Demo' @click="runDemo(key)" style="width: 33.3%;"></el-button>
                </div>
                <div class="mod3_2" v-if="(attribute.member[key].data.Disable === '0')" title="被禁用" @click="Disable(key)"></div>
              </el-card>
              <!-- 多选 -->
              <el-card :title=attribute.member[key].data.title :name="key" v-if="(item === '多项选择')" class=singleLine @click.native="handleChange(key)" shadow="hover" :style="{ width: String(widthStyle[attribute.member[key].data.widthStyle].width), height: String(widthStyle[attribute.member[key].data.widthStyle].height) }">
                <div class="mod3_1_1" style="width: 100%; padding: 0; height: 0">
                  <el-button class="iconfont icon-tubiao-duanhengxian littleButton2" @click="Disable(key)" title='禁用'></el-button>
                </div>
                <div style="height:1.5rem;line-height:1.5rem;font-size:0.6rem;text-align: center;">{{attribute.member[key].data.title}}</div>
                <div :style="{ height: String(widthStyle[attribute.member[key].data.widthStyle].insideHeight) }">
                  <div style="height:auto;width:100%">
                    <el-checkbox-group
                      v-model="muChoicelist[key]"
                      :change="muCh(key)"
                    >
                      <el-checkbox
                        v-for="(text, page) in Number(attribute.member[key].data.choice)"
                        :label="attribute.member[key].data.choiceName[page]"
                        :key="page + key"
                        :style="{
                          width: widthStyle[attribute.member[key].data.widthStyle].choice.width * character(attribute.member[key].data.choiceName[page], 10) + character(attribute.member[key].data.choiceName[page], 10) - 1 + '%'
                        }"
                      >{{attribute.member[key].data.choiceName[page]}}</el-checkbox>
                    </el-checkbox-group>
                  </div>
                </div>
                <div style="height:0.5rem"></div>
                <div class="mod3_1">
                  <el-button class="iconfont icon-xiazai" title='下载Demo' @click="downloadCard(key)" style="width: 33.3%;"></el-button>
                  <el-button class="iconfont icon-shezhi" title='重置' @click="reCard(key)" style="width: 33.3%;"></el-button>
                  <el-button class="iconfont icon-lifangti" title='Demo' @click="runDemo(key)" style="width: 33.3%;"></el-button>
                </div>
                <div class="mod3_2" v-if="(attribute.member[key].data.Disable === '0')" title="被禁用" @click="Disable(key)"></div>
              </el-card>
              <el-card :title=attribute.member[key].data.title :name="key" v-if="(item === '搜索选项')" class=singleLine @click.native="handleChange(key)" shadow="hover" :style="{ width: String(widthStyle[attribute.member[key].data.widthStyle].width), height: String(widthStyle[attribute.member[key].data.widthStyle].height), 'background-color': DisableBGC[attribute.member[key].data.Disable] }">
                <div class="mod3_1_1" style="width: 100%; padding: 0; height: 0">
                  <el-button class="iconfont icon-tubiao-duanhengxian littleButton2" @click="Disable(key)" title='禁用'></el-button>
                </div>
                <div style="height:1.5rem;line-height:1.5rem;font-size:0.6rem;text-align: center;">{{attribute.member[key].data.title}}</div>
                <div :style="{ height: String(widthStyle[attribute.member[key].data.widthStyle].insideHeight) }">
                  <div style="height:auto;width:100%">
                    <div id=seachLeft>
                      <div id=seach_input>
                        <el-input
                          v-model="textInitial[key]"
                          :placeholder="attribute.member[key].data.search.text"
                          @focus="search1(key)"
                          @blur="search2(key)"
                        ></el-input>
                        <el-button @click="search3(key)">搜索</el-button>
                      </div>
                      <el-checkbox-group
                        v-model="muChoicelist[key]"
                        :change="muCh(key)"
                      >
                        <el-checkbox
                          v-for="(text, page) in Number(attribute.member[key].data.search.choice)"
                          :label="attribute.member[key].data.search.choiceName[page]"
                          :key="page + key"
                          :disabled="attribute.member[key].data.search.choiceName[page] === '... ...'"
                          :style="{
                            width: widthStyle[attribute.member[key].data.widthStyle].choice.width * character(attribute.member[key].data.search.choiceName[page], 7) + character(attribute.member[key].data.search.choiceName[page], 7) - 1 + '%'
                          }"
                        >{{attribute.member[key].data.search.choiceName[page]}}</el-checkbox>
                      </el-checkbox-group>
                    </div>
                    <div id=seachRight>
                      <div style="width: 0.8rem;height: 100%;float: left" id="seachicon">
                        <div style="width: 100%;height: 2rem;margin:auto 0;">
                          <el-button @click="seachicon1(key)" class="iconfont icon-arrow-right"></el-button>
                          <el-button @click="seachicon2(key)" class="iconfont icon-shanchu"></el-button>
                        </div>
                      </div>
                      <div style="margin-left: 1rem">
                        <!-- {{attribute.member[key].data.search.data}} -->
                        <div v-for="(item, key1) in attribute.member[key].data.search.data" :key=key1>
                          <div
                            :style="{
                              width: 25 * character(item, 9) - 0.2 + '%',
                              float: 'left',
                              'box-sizing': 'border-box',
                              'border': '1px solid #DCDFE6',
                              'box-shadow': '0rem 0rem 0.1rem rgba(0,0,0,0.15) inset',
                              margin: '0.1%',
                              padding: '0.1rem',
                            }"
                            @mouseover="DataOver(key, key1)"
                            @mouseleave="Dataleave(key, key1)"
                            @click="DataDelete(key, key1)"
                          >
                            {{item}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="height:0.5rem"></div>
                <div class="mod3_1">
                  <el-button class="iconfont icon-xiazai" title='下载Demo' @click="downloadCard(key)" style="width: 33.3%;"></el-button>
                  <el-button class="iconfont icon-shezhi" title='重置' @click="reCard(key)" style="width: 33.3%;"></el-button>
                  <el-button class="iconfont icon-lifangti" title='Demo' @click="runDemo(key)" style="width: 33.3%;"></el-button>
                </div>
                <!-- <div class="mod3_2" v-if="(attribute.member[key].data.Disable === '0')" title="被禁用" @click="Disable(key)"></div> -->
              </el-card>
              <!-- 上传 -->
              <el-card :title=attribute.member[key].data.title :name="key" v-if="(item === '多文件上传' || item === '文件上传' || item === '文件码上传' || item === '图片上传')" class=singleLine @click.native="handleChange(key)" id=upButtop2 shadow="hover" :style="{ width: String(widthStyle[attribute.member[key].data.widthStyle].width), height: String(widthStyle[attribute.member[key].data.widthStyle].height) }">
                <div class="mod3_1_1" style="width: 100%; padding: 0; height: 0">
                  <el-button class="iconfont icon-tubiao-duanhengxian littleButton2" @click="Disable(key)" title='禁用'></el-button>
                </div>
                <div style="height:1.5rem;line-height:1.5rem;font-size:0.6rem;text-align: center;">{{attribute.member[key].data.title}}</div>
                <div :style="{ height: String(widthStyle[attribute.member[key].data.widthStyle].insideHeight) }" id=upload_Display v-if="(item === '文件码上传')">
                  <el-input
                    type="textarea"
                    :rows="4"
                    :placeholder=attribute.member[key].data.uploadtext
                    resize="none"
                    v-model="attribute.member[key].data.Rsa">
                  </el-input>
                </div>
                <div :style="{ height: String(widthStyle[attribute.member[key].data.widthStyle].insideHeight) }" id=upload_Display v-if="(item === '多文件上传' || item === '文件上传' || item === '图片上传' )">
                  <div class="el-upload__text" v-if="(attribute.member[key].data.displayText === '' && typeof(attribute.member[key].data.displayText) === 'string')">{{attribute.member[key].data.uploadtext}}</div>
                  <div class="el-upload__text" v-if="(attribute.member[key].data.displayText !== '' && typeof(attribute.member[key].data.displayText) === 'string')">{{attribute.member[key].data.displayText}}</div>
                  <div class="el-upload__text upload_Display3" v-if="(attribute.member[key].data.displayText !== '' && typeof(attribute.member[key].data.displayText) === 'object')">
                    <div v-for="(item1, key1) in attribute.member[key].data.displayText" :key="key1" class=upload_Display4>
                      <div v-for="(item2, key2) in item1" :key="key2" class=upload_Display2>{{item2}}</div>
                    </div>
                  </div>
                </div>
                <div style="height:0.5rem"></div>
                <div id=upButtop2 class="mod3_1">
                  <!-- 192.168.111.105:21000/application/upload1/Dev/20210421172051/admin/A1 -->
                  <!-- 192.168.111.172:21000/application/upload1/App/20210421172051/admin/A1 -->
                  <el-upload
                    class="upload-demo"
                    :action="Url + '/application/upload1/App/' + $route.query.dir + '/' + username1 + '/'+ attribute.member[key].data.enTitle"
                    :on-change="upload(key)"
                    :show-file-list='false'
                    :multiple='false'
                    title='上传'
                    :on-success='(response, file, fileList) => successDisplay (response, file, fileList, key)'
                    :on-error='(err, file, fileList) => uploadError(err, file, fileList, key)'
                    :on-progress='(event, file, fileList) => onUploadProgress(event, file, fileList, key)'
                    v-if="(item === '文件上传' || item === '图片上传')"
                  >
                    <i class="iconfont icon-shujubao" v-if="(item === '文件上传')"></i>
                    <i class="iconfont icon-tupian01" v-if="(item === '图片上传')"></i>
                  </el-upload>
                  <el-upload
                    class="upload-demo"
                    :action="Url + '/application/upload2/App/' + $route.query.dir + '/' + username1 + '/' + attribute.member[key].data.dirName +'/'+ attribute.member[key].data.enTitle"
                    :on-change="upload"
                    :show-file-list='false'
                    :multiple='true'
                    title='上传'
                    :on-success='(response, file, fileList) => successDisplay (response, file, fileList, key)'
                    :on-error='(err, file, fileList) => uploadError(err, file, fileList, key)'
                    :on-progress='(event, file, fileList) => onUploadProgress(event, file, fileList, key)'
                    v-if="(item === '多文件上传')"
                  >
                    <i class="iconfont icon-excelwenjian" v-if="(item === '多文件上传')"></i>
                  </el-upload>
                  <div class="upload-demo" v-if="(item === '文件码上传')">
                    <i class="iconfont icon-shujuwenjian" :style="{ 'margin-left': 'calc(50% - 0.375rem)' , cursor: 'pointer' }" @click="Rsa(key)" title='导入文件'></i>
                  </div>
                  <el-button title='从云盘上传' @click="uploadForDisk1(key, item)">
                    <img src="../../assets/个人中心素材/文件夹1.png" alt="">
                  </el-button>
                  <el-button class="iconfont icon-xiazai" title='下载Demo' @click="downloadCard(key)"></el-button>
                  <el-button class="iconfont icon-shezhi" title='重置' @click="reCard(key)"></el-button>
                  <el-button class="iconfont icon-lifangti" title='Demo' @click="runDemo(key)"></el-button>
                </div>
                <div class="mod3_2" v-if="(attribute.member[key].data.Disable === '0')" title="被禁用" @click="Disable(key)"></div>
              </el-card>
              <el-card :title=attribute.member[key].data.title :name="key" v-if="(item === '下载')" class=singleLine @click.native="handleChange(key)" shadow="hover" :style="{ width: String(widthStyle[attribute.member[key].data.widthStyle].width), height: String(widthStyle[attribute.member[key].data.widthStyle].height) }">
                <div style="height:1.5rem;line-height:1.5rem;font-size:0.6rem;text-align: center;">{{attribute.member[key].data.title}}</div>
                <div :style="{ height: String(widthStyle[attribute.member[key].data.widthStyle].insideHeight) }">
                  <div style="height:1rem;"></div>
                  <div style="height:1.5rem;line-height:1rem;text-align:center;">
                    <!-- <a :href='attribute.member[key].data.download' target="view_window" id=downloadAdd style="color:rgba(80,173,221,1); text-decoration:none;font-size: 0.5rem;">点击下载</a> -->
                    <a :href='downloadUrl' target="view_window" id=downloadAdd style="color:rgba(80,173,221,1); text-decoration:none;font-size: 0.5rem;">点击下载</a>
                  </div>
                  <div style="height:1rem;"></div>
                </div>
                <div style="height:0.5rem"></div>
                <div class="mod3_1">
                  <el-button class="iconfont icon-xiazai" title='下载Demo' @click="downloadCard(key)" style="width: 33.3%;"></el-button>
                  <el-button class="iconfont icon-shezhi" title='重置' @click="reCard(key)" style="width: 33.3%;"></el-button>
                  <el-button class="iconfont icon-lifangti" title='Demo' @click="runDemo(key)" style="width: 33.3%;"></el-button>
                </div>
              </el-card>
              <el-card :title=attribute.member[key].data.title :name="key" v-if="(item === '显示框' && attribute.member[key].data.display === '2')" class=singleLine id=displayBox @click.native="handleChange(key)" shadow="hover" :style="{ width: String(widthStyle[attribute.member[key].data.widthStyle].display.width), height: String(widthStyle[attribute.member[key].data.widthStyle].display.height), 'min-height': String(widthStyle[attribute.member[key].data.widthStyle].display.minHeight) }">
                <!-- <el-scrollbar id="MainScrollbar"> -->
                  <div style="height:1.5rem;line-height:1.5rem;font-size:0.6rem;text-align: center;">{{attribute.member[key].data.title}}</div>
                  <div :style="{ height: String(widthStyle[attribute.member[key].data.widthStyle].display.insideHeight), 'min-height': String(widthStyle[attribute.member[key].data.widthStyle].display.insideMinHeight) }">
                    <div style="height:100%;width:100%;word-break:break-all;">
                      <div v-if="(attribute.member[key].data.resultImg !== 'UNdemo.png')">
                        {{attribute.member[key].data}}
                        <!-- <img :src="require('../../../assets/' + attribute.member[key].data.resultImg)" style="height:11rem;width:auto;float:left"> -->
                      </div>
                      <div style="height:100%;width:auto;float:left;word-break:break-all;">{{attribute.member[key].data.result}}</div>
                    </div>
                  </div>
                  <div style="height:0.5rem"></div>
                  <div class="mod3_1">
                    <el-button class="iconfont icon-xiazai" title='下载Demo' @click="downloadCard(key)" style="width: 33.3%;"></el-button>
                    <el-button class="iconfont icon-shezhi" title='重置' @click="reCard(key)" style="width: 33.3%;"></el-button>
                    <el-button class="iconfont icon-lifangti" title='Demo' @click="runDemo(key)" style="width: 33.3%;"></el-button>
                  </div>
                <!-- </el-scrollbar> -->
              </el-card>
          </div>
        </div>
      </el-col>
    </el-scrollbar>
    <div id=mod2>
      <el-button class=mod5_1 @click="runDemo2" v-if='this.runStateButton === 0'>Demo</el-button>
      <el-button class=mod5_1 @click="runDemo2" disabled style="background-color: #eee;" v-if='this.runStateButton === 1'>Demo</el-button>
      <el-button class=mod5_1 @click="run" v-if='this.runStateButton === 0'>运行</el-button>
      <el-button class=mod5_1 @click="run" disabled style="background-color: #eee;" v-if='this.runStateButton === 1'>运行</el-button>
      <el-button class=mod5_1 @click="Reset">重置</el-button>
      <el-button class=mod5_1 @click="close">返回</el-button>
    </div>
    <div id=mod5 v-if="(this.FolderDisplay === 1)">
      <PersonalDisk></PersonalDisk>
    </div>
  </div>
</template>

<script>
// $emit----v-on子传父
// 非父子组件进行传值公共js
import DataChild from '../Mate/data.vue'
import Data from '../Mate/data.js'
import PersonalDisk from '../children/云盘上传.vue'
export default {
  inject: ['reload'],
  // 引用this.reload()-----刷新
  data() {
    return {
      screenWidth: document.body.clientWidth,
      screeHeight: document.body.clientHeight,
      mainName: 'Interface',
      addlist: '',
      activeNames: [''],
      attribute: { member: {} },
      textInitial: {},
      attributeList: '',
      displayData: '3',
      Single: { title: '请修改标题', enTitle: 'English', widthStyle: '1', number: '', text: '' },
      multiLine: { title: '请修改标题', enTitle: 'English', widthStyle: '1', number: '', text: '' },
      singleChoice: { title: '请修改标题', enTitle: 'English', widthStyle: '1', number: '', preferences: '0', choice: 3, display: '0', choiceName: { 0: '选项1', 1: '选项2', 2: '选项3' } },
      // multipleChoice: { title: '请修改标题', enTitle: 'English', number: '', choice: 3, maxChoice: 3, choiceName: { 0: '选项1', 1: '选项2', 2: '选项3' } },
      multipleChoice: { title: '请修改标题', enTitle: 'English', widthStyle: '1', number: '', choice: 3, display: '0', choiceName: { 0: '选项1', 1: '选项2', 2: '选项3' } },
      searchChoice: { title: '请修改标题', enTitle: 'English', widthStyle: '1', number: '', search: { choice: '', choiceName: '', text: '', tips: '搜索提示字', data: { 0: '暂无数据选择' }, cache: '' }, choice: 3, display: '0', choiceName: { 0: '选项1', 1: '选项2', 2: '选项3' } },
      searchText: '',
      upload1: { title: '请修改标题', enTitle: 'English', widthStyle: '1', number: '', url: 'Demo', uploadtext: '只能上传xlsx, csv文件' },
      upload2: { title: '请修改标题', enTitle: 'English', widthStyle: '1', number: '', url: 'Demo', uploadtext: '只能上传txt文件' },
      upload3: { title: '请修改标题', enTitle: 'English', widthStyle: '1', number: '', url: 'Demo', uploadtext: '只能上传tar文件' },
      upload4: { title: '请修改标题', enTitle: 'English', widthStyle: '1', number: '', url: 'Demo', uploadtext: '只能上传png文件' },
      Download: { title: '请修改标题', enTitle: 'English', widthStyle: '1', number: '', download: '下载一个压缩包' },
      display: { title: '请修改标题', enTitle: 'English', widthStyle: '1', number: '', display: '1', result: '', resultImg: "demo.png" },
      muChoicelist: [''],
      fileList: [''],
      singleChoiceText: '',
      tool_msg: { cnName: '', enName: '' },
      uploadData: {},
      widthStyle: { 1: { width: '43%', height: '7.5rem', insideHeight: '4rem', display: { insideHeight: '12.3rem', width: '43%', height: '16.3rem', minHeight: 'auto', insideMinHeight: 'auto' }, choice: { width: 24 } }, 2: { width: '93%', height: 'auto', insideHeight: 'auto', display: { insideHeight: 'auto', width: '93%', height: 'auto', minHeight: '16.3rem', insideMinHeight: '12.3rem' }, choice: { width: 11.5 } } },
      searchData: { 0: '暂无数据选择' },
      cacheData: '',
      downloadUrl: '#',
      Url: '',
      runState: 0,
      runStateButton: 0,
      tool_id: '',
      DisableBGC: { 0: 'rgba(0, 0, 0, 0.1)', 1 :'rgba(0, 0, 0, 0)' },
      username1: '',
      queryTime: 0,
      FolderDisplay: 0,
    }
  },
  created() {
    setTimeout(async() => { 
      this.initialization()
      // this.Url = DataChild.httpUrl_2.substring(0,DataChild.httpUrl_2.lastIndexOf('/api'))
    }, 1000)
    Data.$off('Manual')
  },
  mounted: function () {
    // mounted一直监控？可改位置----目前此方法还不能正确运用
    // 用$on事件来接收参数
    Data.$on('addlist', (data) => {
      this.addlist = data
      // console.log(this.addlist)
      // console.log(this.attribute)
      for (var i in this.addlist) {
        if (typeof(this.attribute.member[i]) === 'undefined') {
          this.attribute.member[i] = { data: '', type: '' }
          this.attribute.member[i].type = this.addlist[i]
          // 单行文本属性
          if (this.addlist[i] === '单行文本') {
            this.attribute.member[i].data = JSON.parse(JSON.stringify(this.Single))
          } else if (this.addlist[i] === '多行文本') {
            // 多行文本属性
            this.attribute.member[i].data = JSON.parse(JSON.stringify(this.multiLine))
          } else if (this.addlist[i] === '单项选择') {
            // 单选属性
            this.attribute.member[i].data = JSON.parse(JSON.stringify(this.singleChoice))
          } else if (this.addlist[i] === '多项选择') {
            this.attribute.member[i].data = JSON.parse(JSON.stringify(this.multipleChoice))
          } else if (this.addlist[i] === '搜索选项') {
            this.attribute.member[i].data = JSON.parse(JSON.stringify(this.searchChoice))
          } else if (this.addlist[i] === '表格上传') {
            this.attribute.member[i].data = JSON.parse(JSON.stringify(this.upload1))
          } else if (this.addlist[i] === '文件上传') {
            this.attribute.member[i].data = JSON.parse(JSON.stringify(this.upload2))
          } else if (this.addlist[i] === '文件夹上传') {
            this.attribute.member[i].data = JSON.parse(JSON.stringify(this.upload3))
          } else if (this.addlist[i] === '图片上传') {
            this.attribute.member[i].data = JSON.parse(JSON.stringify(this.upload4))
          } else if (this.addlist[i] === '下载') {
            this.attribute.member[i].data = JSON.parse(JSON.stringify(this.Download))
          } else if (this.addlist[i] === '显示框') {
            this.attribute.member[i].data = JSON.parse(JSON.stringify(this.display))
            this.displayData = '1'
          } else {
            this.attribute.member[i].data = JSON.parse(JSON.stringify(this.Single))
          }
          this.attribute.member[i].data.number = Number(i) + 1
        }
      }
      // console.log(this.attribute)
      this.$forceUpdate()
    }),
    Data.$on('active', (data) => {
      // console.log(data)
      this.$forceUpdate()
    })
    // 初始化1
    Data.$on('DevIniData', (data) => {
      if (data.toolat !== null) {
        var a = JSON.parse(data.toolat)
        this.attribute.member = a
      }
      this.tool_msg = { cnName: data.cnName, enName: data.enName }
      // this.$forceUpdate()
    })
    Data.$on('Manual', (data) => {
      // 1是手动获取状态，2是中断当前应用，3是中断全部应用
      // 4是下载运行日志
      if (this.queryTime !== 0) {
        this.$message.closeAll()
        this.$message({
          message: '任务提交两分钟内禁用此功能。',
          type: 'success',
          showClose: true,
          duration: 5000,
        })
        return 0
      }
      var k = { tool_id: this.tool_id, username: this.username1, Filedir: 'App', dir: this.$route.query.dir, mod: 0 }
      if (data === 1) {
        k['mod'] = 1
        this.Run3(k)
      } else if (data === 2) {
        k['mod'] = 2
        this.Run4(k)
        Data.$emit('tipeState', '停止运行')
      } else if (data === 3) {
        k['mod'] = 3
        this.Run4(k)
        Data.$emit('tipeState', '停止运行')
      } else if (data === 4) {
        k['mod'] = 4
        this.downloadLogs(k)
      } else if (data === 5) {
        k['mod'] = 5
        this.Recompress(k)
      }
    })
    Data.$on('Disk1', (data) => {
      this.FolderDisplay = 0
    })
    Data.$on('Disk2', (data) => {
      // this.FolderDisplay = 0
      this.uploadForDisk2(data)
    })
  },
  methods: {
    // 初始化2
    initialization () {
      this.textInitial = { 0: '', 1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: '', 10: '', 11: '', 12: '', 13: '', 14: '', 15: '', 16: '', 17: '', 18: '', 19: '', 20: '', 21: '', 22: '', 23: '', 24: '', 25: '', 26: '', 27: '', 28: '', 29: '', 30: '', 31: '', 32: '', 33: '', 34: '', 35: '', 36: '', 37: '', 38: '', 39: '', 40: '', 41: '', 42: '', 43: '', 44: '', 45: '', 46: '', 47: '', 48: '', 49: '', 50: '', 51: '', 52: '', 53: '', 54: '', 55: '', 56: '', 57: '', 58: '', 59: '', 60: '', 61: '', 62: '', 63: '', 64: '', 65: '', 66: '', 67: '', 68: '', 69: '', 70: '' }
      for (var i = 71; i <= 100; i++) {
        this.textInitial[i] = ''
      }
      this.username1 = localStorage.getItem('username')
      for (i = 0; i <= 100; i++) {
        this.muChoicelist[i] = ['']
      }
      this.getTool_id()
      this.getURL()
      // console.log(this.Url)
    },
    async getURL() {
      var c = localStorage.getItem('Https')
      if (c === 'No.1') {
        this.Url = DataChild.httpUrl_1
      } else if (c === 'No.2'){
        this.Url = DataChild.httpUrl_2
      } else if (c === 'No.3'){
        this.Url = DataChild.httpUrl_3
      } else if (c === 'No.4'){
        this.Url = DataChild.httpUrl_4
      } else {
        console.clear()
      }
      this.getUrl = this.Url
      return c
    },
    async getTool_id() {
      const { data: res1 } = await this.$http.post('application/enName_ID', { tool_name: this.$route.query.dir.split('_')[0] })
      this.tool_id = res1.tool_id
    },
    // 接收子组件的值
    childData: function (data) {
    // console.log(data)
    },
    // 接收公共js的值
    demoData: function () {
      Data.$emit('demo', this.demo)
    },
    handleChange(key) {
      // if (typeof(this.attribute.member[key]) !== 'undefined') {
      //   console.log(this.attribute.member[key].data)
      //   Data.$emit('attribute', this.attribute.member[key].data)
      // }
    },
    text1 (key) {
      if (typeof(this.attribute.member[key].data.text) !== 'undefined') {
        this.textInitial[key] = this.attribute.member[key].data.text
      } else if (typeof(this.attribute.member[key].data.text_1) !== 'undefined') {
        this.textInitial[key] = this.attribute.member[key].data.text_1
      }
    },
    text2 (key) {
      if (typeof(this.attribute.member[key].data.text) !== 'undefined') {
        this.attribute.member[key].data.text = this.textInitial[key] 
      } else if (typeof(this.attribute.member[key].data.text_1) !== 'undefined') {
        this.attribute.member[key].data.text_1 = this.textInitial[key] 
      }
      this.textInitial[key] = ''
    },
    muCh (key) {
    },
    upload () {
    },
    test(key) {
      console.log('1')
    },
    littleDelete(key) {
      var k = 0
      var j = { 'add': {}, 'dis': {} }
      for (var i in this.attribute.member) {
        if (key === i) {
          // console.log(key, i)
        } else {
          j.add[k] = this.attribute.member[i]
          j.dis[k] = this.addlist[i]
          k = k + 1
        }
      }
      // console.log(j.dis)
      // console.log(j.add)
      Data.$emit('delete', j.dis)
      this.$forceUpdate()
      this.attribute.member = j.add
    },
    littleUpload() {
      console.log('1')
    },
    littleSubmit() {
      console.log('1')
    },
    displayBox() {
      for (var i in this.addlist) {
        if (this.addlist[i] === '显示框') {
          // console.log('第' + (Number(i) + 1) + '个是显示框')
          if (this.attribute.member[i].data.display === '1') {
            this.attribute.member[i].data.display = '2'
            this.displayData = '2'
          } else {
            this.attribute.member[i].data.display = '1'
            this.displayData = '1'
          }
          this.$forceUpdate()
          return 0
        }
      }
      // console.log('没有显示框')
    },
    search2(key) {
      // 运行以后显示的内容
      // console.log('要搜索的字符串长度为：' + this.textInitial[key].length)
      this.attribute.member[key].data.search.text = this.textInitial[key]
      this.$forceUpdate()
    },
    search1(key) {
      // 点击以后显示的内容
      this.searchText = this.attribute.member[key].data.search.text
      this.textInitial[key] = this.searchText
    },
    search3(key) {
      // console.log(this.attribute.member[key].data.search)
      if (this.textInitial[key].length === 0) {
        // this.attribute.member[key].data.search.choice = this.attribute.member[key].data.choice
        // this.attribute.member[key].data.search.choiceName = this.attribute.member[key].data.choiceName
        // this.attribute.member[key].data.search.text = this.textInitial[key]
      } else if (this.textInitial[key].length !== 0) {
        var i = this.textInitial[key].length
        var d = 0
        this.attribute.member[key].data.search.choiceName = {}
        for (var j in this.attribute.member[key].data.choiceName) {
          // console.log('字符串长度是：' + this.attribute.member[key].data.choiceName[j].length + '，要匹配的长度是' + this.textInitial[key].length)
          if (d > 14) {
            // console.log(d)
            this.attribute.member[key].data.search.choiceName[d] = '... ...'
            // console.log(this.attribute.member[key].data.search.choiceName)
            d = d + 1
            break
          }
          if (this.attribute.member[key].data.choiceName[j].length >= this.textInitial[key].length) {
            // console.log('开始匹配')
            var b = this.attribute.member[key].data.choiceName[j].length - this.textInitial[key].length
            // console.log('拆分成' + (b + 1) + '组')
            for (var c = 0; c <= b; c++) {
              var k = this.attribute.member[key].data.choiceName[j].slice(c, c + this.textInitial[key].length)
              if (k === this.textInitial[key]) {
                // console.log(d)
                // console.log(this.attribute.member[key].data.choiceName[j] + '满足要求')
                this.attribute.member[key].data.search.choiceName[d] = this.attribute.member[key].data.choiceName[j]
                d = d + 1
                break
              }
            }
          } else {
            // console.log('不进行匹配')
          }
        }
        this.attribute.member[key].data.search.choice = d
      }
      // console.log(this.attribute.member[key].data.search.choiceName, this.attribute.member[key].data.search.choice)
      this.$forceUpdate()
    },
    seachicon1 (key) {
      // console.log(key)
      // console.log(this.muChoicelist[key])
      var k = 0
      if (typeof(a) === 'undefined') {
        var a = {}
      }
      for (var i in this.muChoicelist[key]) {
        if (this.muChoicelist[key][i] !== '') {
          a[String(k)] = this.muChoicelist[key][i]
          k = k + 1
        }
      }
      // console.log(a)
      // 要上传的值的方法
      this.attribute.member[key].data.search.cache = a
      // 要显示的值的方法-------
      // for (i in a) {
      //   if (i === '0') {
      //     this.attribute.member[key].data.search.data =a[i]
      //   } else {
      //     this.attribute.member[key].data.search.data = this.attribute.member[key].data.search.data + ' , ' + a[i]
      //   }
      // }
      // ---------------------
      this.attribute.member[key].data.search.data = a
    },
    seachicon2 (key) {
      this.muChoicelist[key] = ['']
      this.seachicon1(key)
      this.attribute.member[key].data.search.data = { 0: '暂无数据选择' }
      this.$forceUpdate()
    },
    character(data1, data2) {
      if (typeof(data1) === 'undefined') return 1
      if (data1 === '暂无数据选择') return 4
      // if (typeof(data2) !== 'undefined') console.log(data2)
      var i = data1.replace(/[\u0391-\uFFE5]/g,"aa").length
      var j = Math.ceil(i/data2)
      if (j >= 4) return 4
      return j
    },
    DataOver(key, key1) {
      // console.log('鼠标移入')
      // console.log(this.attribute.member[key].data.search.data[key1])
      if (this.attribute.member[key].data.search.data[key1] === '暂无数据选择') return 0
      this.cacheData = this.attribute.member[key].data.search.data[key1]
      this.attribute.member[key].data.search.data[key1] = '点击删除'
    },
    Dataleave(key, key1) {
      // console.log('鼠标移出')
      // console.log(this.attribute.member[key].data.search.data[key1])
      if (this.attribute.member[key].data.search.data[key1] !== '点击删除') return 0
      if (this.attribute.member[key].data.search.data[key1] === '暂无数据选择') return 0
      this.attribute.member[key].data.search.data[key1] = this.cacheData 
    },
    DataDelete(key, key1) {
      // console.log(this.attribute.member[key].data.search.data[key1])
      if (this.attribute.member[key].data.search.data[key1] === '暂无数据选择') return 0
      // console.log(this.muChoicelist[key])
      var j = {}
      var k = 0
      var a = []
      for (var i in this.attribute.member[key].data.search.data) {
        if (this.attribute.member[key].data.search.data[i] !== '点击删除') {
          j[k] = this.attribute.member[key].data.search.data[i]
          k = k + 1
        }
      }
      k = 0
      // console.log(this.cacheData)
      for (var i in this.muChoicelist[key]) {
        if (this.muChoicelist[key][i] !== this.cacheData) {
          a[k] = this.muChoicelist[key][i]
          k = k + 1
        }
      }
      // console.log(j)
      // console.log(a)
      this.attribute.member[key].data.search.data = j
      this.muChoicelist[key] = a
      this.$forceUpdate()
    },
    async run() {
      this.runStateButton = 1
      this.$message.closeAll()
      this.$message({
        message: '正在进行预处理，请稍等',
        type: 'success',
        showClose: true,
        duration: 0,
      })
      if (this.runState === 0) {
        this.runState = 1
        var k = { tool_id: this.tool_id, username: this.username1, Filedir: 'App', dir: this.$route.query.dir }
        const { data: res1 } = await this.$http.post('/application/getDevState', k)
        if (res1.code === 101) {
          this.$message.closeAll()
          this.$message.success(res1.msg)
          Data.$emit('tipeState', '正在运行')
          setTimeout(async() => { 
            this.Run2(k)
          }, 120000)
          return 0
        } else if (res1.running.code === 1001) {
          this.$message.closeAll()
          this.$message.success(res1.msg)
          this.runState = 0
          this.runStateButton = 0
          return 0
        }
        this.queryTime = 1
        setTimeout(async() => { 
          this.queryTime = 0
        }, 120000)
        this.$message.closeAll()
        Data.$emit('download_file', '')
        Data.$emit('tipeState', '正在运行')
        var a = this.attribute.member
        var l = ''
        var k = { tool_id: this.tool_id, dir: this.$route.query.dir, username: this.username1, Filedir: 'App' }
        // ----------------------
        for (var b in a) {
          // console.log(a[b].data.enTitle, a[b].data.Demo)
          if (a[b].type === '单行文本' && a[b].data.Demo === 0) {
            l = { tool_id: this.$route.query.dir, username: this.username1, input_data: a[b].data.text, table_name: a[b].data.enTitle, Disable: a[b].data.Disable, Filedir: 'App' }
            const { data: res } = await this.$http.post('application/inputForTxt', l)
          }
          if (a[b].type === '多行文本' && a[b].data.Demo === 0) {
            l = { tool_id: this.$route.query.dir, username: this.username1, input_data: a[b].data.text_1, table_name: a[b].data.enTitle, Disable: a[b].data.Disable, Filedir: 'App' }
            const { data: res } = await this.$http.post('application/inputForTxt', l)
          }
          if ((a[b].type === '多项选择' || a[b].type === '搜索选项') && a[b].data.Demo === 0) {
            var c = []
            for (var d in this.muChoicelist[b]) {
              if (d !== '0') {
                c[Number(d) - 1] = this.muChoicelist[b][d]
              }
            }
            l = { tool_id: this.$route.query.dir, username: this.username1, input_data: c, table_name: a[b].data.enTitle, Disable: a[b].data.Disable, Filedir: 'App' }
            const { data: res } = await this.$http.post('application/selectForTxt', l)
          }
          if (a[b].type === '单项选择' && a[b].data.Demo === 0) {
            var c = []
            c[0] = a[b].data.choiceName[a[b].data.preferences]
            l = { tool_id: this.$route.query.dir, username: this.username1, input_data: c, table_name: a[b].data.enTitle, Disable: a[b].data.Disable, Filedir: 'App' }
            const { data: res } = await this.$http.post('application/selectForTxt', l)
          }
        }
        // return this.runState = 0
        // ----------------------
        Data.$emit('download_file', '')
        this.Run1(k)
        setTimeout(async() => { 
          this.Run2(k)
        }, 120000)
      }
    },
    async runDemo2 () {
      this.runStateButton = 1
      this.$message.closeAll()
      this.$message({
        message: '正在进行预处理，请稍等',
        type: 'success',
        showClose: true,
        duration: 0,
      })
      var k = { tool_id: this.tool_id, username: this.username1, Filedir: 'App', dir: this.$route.query.dir }
      const { data: res1 } = await this.$http.post('/application/getDevState', k)
      // console.log(res1)
      // return 0
      if (res1.code === 101) {
        this.$message.closeAll()
        this.$message.success(res1.msg)
        Data.$emit('tipeState', '正在运行')
        setTimeout(async() => { 
          this.Run2(k)
        }, 120000)
        return 0
      } else if (res1.running.code === 1001) {
        this.$message.closeAll()
        this.$message.success(res1.running.msg)
        this.runState = 0
        this.runStateButton = 0
        return 0
      }
      this.queryTime = 1
      setTimeout(async() => { 
        this.queryTime = 0
      }, 120000)
      const { data: res } = await this.$http.post('application/toolRunDemo', k)
      if (this.runState === 0 && res.code === 200) {
        this.$message.closeAll()
        this.runState = 1
        Data.$emit('tipeState', '正在运行')
        var a = this.attribute.member
        var l = ''
        var k = { tool_id: this.tool_id, username: this.username1, Filedir: 'App', dir: this.$route.query.dir }
        Data.$emit('download_file', '')
        this.Run1(k)
        setTimeout(async() => { 
          this.Run2(k)
        }, 120000)
      }
    },
    async Run1(k) {
      Data.$emit('tipeState', '正在运行')
      const { data: res } = await this.$http.post('application/toolRun', k)
      // Data.$emit('tipeState', '运行完成：')
      this.runState = 0
    },
    async Run2(k) {
      const { data: res1 } = await this.$http.post('/application/getDevState', k)
      // 测试功能
      // console.log(res1)
      if (res1.code === 101) {
        Data.$emit('tipeState', '正在运行')
        setTimeout(async() => { 
          this.Run2(k)
        }, 30000)
      } else if (res1.code === 102) {
        Data.$emit('tipeState', '运行出错')
        this.runState = 0
        this.runStateButton = 0
      } else if (res1.code === 400 || this.runState !== 0) {
        Data.$emit('tipeState', '不在运行中')
        this.runState = 0
        this.runStateButton = 0
      } else if (res1.code === '运行完成' || res1.code === 200) {
        setTimeout(async() => { 
          // 测试功能
          console.clear()
          const { data: res2 } = await this.$http.post('application/getFileDownload/App/' + this.$route.query.dir + '/' + this.username1 + '/' + res1.zip_name)
          if (res2.code === '101') {
            this.$message({
              message: '应用运行失败：' + res2.filename + '请联系管理员',
              type: 'error',
              duration: 5000,
              showClose: true,
            });
            Data.$emit('tipeState', '运行出错')
            return 0
          }
          if (res2.filename === 'undefined') {
            this.downloadUrl = '请压缩'
          } else {
            this.downloadUrl = this.Url + '/application/DownloadFileA/' + res2.filename + '/' + res2.filePath
          }
          k = { url: this.downloadUrl, id: this.$route.query.dir}
          Data.$emit('download_file', k)
          Data.$emit('tipeState', '运行完成：')
          this.runState = 0
          this.runStateButton = 0
        }, 500)
      } else {
        this.runState = 0
      }
    },
    async Run3(k) {
      this.$message.success('正在查询运行状态')
      const { data: res1 } = await this.$http.post('/application/getDevState', k)
      // console.log(res1)
      if (res1.code === 101) {
        this.$message.closeAll()
        this.$message.success(res1.msg)
        Data.$emit('tipeState', '正在运行')
      } else if (res1.code === 102) {
        this.$message.closeAll()
        this.$message.success(res1.msg)
        Data.$emit('tipeState', '运行出错')
        this.runState = 0
        this.runStateButton = 0
      } else if (res1.code === 400 || this.runState !== 0) {
        this.$message.closeAll()
        this.$message.success(res1.msg)
        Data.$emit('tipeState', '不在运行中')
        this.runState = 0
        this.runStateButton = 0
      } else if (res1.code === 'Submit') {
        this.$message.closeAll()
        this.$confirm('未获取到运行情况，长时间未获取到请尝试重新打开应用。可尝试点击右侧下载运行日志、压缩运行结果等按钮，或者点击下方重置按钮重新上传运行。', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
      } else if (res1.code === '运行完成' || res1.code === 200) {
        this.$message.closeAll()
        this.$message.success('运行完成')
        setTimeout(async() => { 
          console.clear()
          const { data: res2 } = await this.$http.post('application/getFileDownload/App/' + this.$route.query.dir + '/' + this.username1 + '/' + res1.zip_name)
          if (res2.code === '101') {
            this.$message({
              message: '应用运行失败：' + res2.filename + '请联系管理员',
              type: 'error',
              duration: 5000,
              showClose: true,
            });
            Data.$emit('tipeState', '运行出错')
            return 0
          }
          if (res2.filename === 'undefined') {
            this.downloadUrl = '请压缩'
          } else {
            this.downloadUrl = this.Url + '/application/DownloadFileA/' + res2.filename + '/' + res2.filePath
          }
          k = { url: this.downloadUrl, id: this.$route.query.dir}
          Data.$emit('download_file', k)
          Data.$emit('tipeState', '运行完成：')
          this.runState = 0
          this.runStateButton = 0
        }, 100)
      }
    },
    async Run4(k) {
      const { data: res1 } = await this.$http.post('/application/DevShutdown', k)
      if (res1.code === 200) {
        this.$message.closeAll()
        this.$message.success('结束运行')
        this.runState = 0
        this.runStateButton = 0
      }
    },
    successDisplay (response, file, fileList, key) {
      // console.log(response, file, fileList, Key)
      if (this.attribute.member[key].type === '表格上传' && response.code === 200) {
        var i = []
        for (var j in response.data) {
          if (j < 3) {
            i[j] = []
            for (var k in response.data[j]) {
              if (k < 3) {
                i[j][k] = response.data[j][k]
              } else {
                i[j][3] = ''
              }
            }
          } else {
            i[3] = []
            for (var k in response.data[j]) {
              if (k < 3) {
                i[3][k] = ''
              } else {
                i[j][3] = ''
              }
            }
          }
        }
        // 行补空
        for (j in i) {
          if (i[j].length < 4) {
            for (k = i[j].length; k < 4; k++) {
              i[j][k] = ''
            }
          }
        }
        // 列补空
        if (i.length < 4) {
          for (k = i.length; k < 4; k++) {
            i[k] = ['', '', '', '']
          }
        }
        i[3] = ['', '', '', '...']
        this.attribute.member[key].data.displayText = i
      } else if (response.code === 200) {
        this.attribute.member[key].data.displayText = '已上传：' + file.name
        // 多文件上传判断之后有需要再补充
      }
    },
    async downloadCard(key) {
      var filename = this.attribute.member[key].data.enTitle
      // const { data: res1 } = await this.$http.post('application/beforGetFile/App_Demo/' + this.tool_id + '/' + filename)
      const { data: res1 } = await this.$http.post('application/beforGetFile/Dev_Demo/' + this.tool_id + '/' + filename)
      const { data: res } = await this.$http.post('application/get_file/App_Demo/' + this.tool_id + '/' + localStorage.getItem('username') + '/' + res1.file)
      if (res.code === '101') {
        this.$message({
          message: '未找到文件' + filename + '请联系管理员',
          type: 'error',
          duration: 5000,
          showClose: true,
        });
        return 0
      }
      // 下载文件
      var url = this.Url + '/application/DownloadFileA/' + res1.file + '/' + res1.filePath
      window.open(url)
      this.$message.closeAll()
      this.$message({
        message: 'Demo开始下载',
        type: 'success',
        duration: 5000,
        showClose: true,
      });
    },
    reCard(key) {
      if (this.attribute.member[key].data.Demo != 0) this.attribute.member[key].data.title = this.attribute.member[key].data.Demo
      this.attribute.member[key].data.Demo = 0
      if (this.attribute.member[key].type.substring(2,4) === '上传') {
        this.attribute.member[key].data.displayText = '已重置，请上传新文件。' + '上传规则为：' + this.attribute.member[key].data.uploadtext
      }
    },
    uploadError (err, file, fileList, key) {
      this.attribute.member[key].data.displayText = '文件：' + file.name + '上传失败，请进行确认。' + '上传规则为：' + this.attribute.member[key].data.uploadtext
      setTimeout(() => {
        console.clear()
      }, 500);
    },
    onUploadProgress(event, file, fileList, key) {
      this.attribute.member[key].data.size1 = event.percent
      if (this.attribute.member[key].data.size2 === '') {
        this.attribute.member[key].data.size2 = this.attribute.member[key].data.title
      }
      var a = this.attribute.member[key].data.size1.toFixed(1)
      this.attribute.member[key].data.title = '已上传 : ' + a + ' %'
      if (this.attribute.member[key].data.size1 === 100) {
        this.attribute.member[key].data.title = this.attribute.member[key].data.size2
        this.attribute.member[key].data.size2 = ''
      }
      // console.log(this.attribute.member[key].data.size1)
    },
    async runDemo (key) {
      // if(typeof(this.attribute.member[key].data.type) !== 'undefined') {
      //   var a = this.attribute.member[key].data.enTitle + '.' + this.attribute.member[key].data.type
      // } else if (this.attribute.member[key].type === '单行文本' || this.attribute.member[key].type === '多行文本'){
      //   var a = this.attribute.member[key].data.enTitle + '.txt'
      // } else {
      //   var a = this.attribute.member[key].data.enTitle
      // }
      var a = this.attribute.member[key].data.enTitle
      var k = { tool_id: this.$route.query.ID, username: this.username1, file_name: a }
      // #这里要改!!!
      const { data: res } = await this.$http.post('application/copyDemo/App/' + this.$route.query.dir + '/' + k.file_name + '/' + k.username)
      var b = ''
      if (res.code === 202) {
        b = '操作失败,文件不存在！'
      } else {
        b = '操作成功，将使用Demo文件运行'
        this.attribute.member[key].data.Demo = 1
      }
      if (this.attribute.member[key].type.substring(2,4) === '上传') {
        this.attribute.member[key].data.displayText = this.attribute.member[key].data.uploadtext
      }
      this.attribute.member[key].data.Demo = this.attribute.member[key].data.title
      this.attribute.member[key].data.title = b
    },
    async Reset () {
      this.$confirm('重置将删除上传的文件以及运行的结果，是否继续', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(async () => {
        this.$message.closeAll()
        this.$message({
          message: '正在重置请稍后',
          type: 'success'
        });
        var k = { tool_id: this.tool_id, username: this.username1, toolDir: 'App', dirname: this.$route.query.dir }
        const { data: res } = await this.$http.post('application/appReload', k)
        if (res.code === 200) {
          this.reload()
          this.$message.closeAll()
          this.$confirm('重置完成，如有异常请点击中断应用', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'success'
          })
        } else {
          this.$message.closeAll()
          this.$message({
            message: '重置失败',
            type: 'warning'
          });
        }
      }).catch(() => {
        // k = { name: '4124', code: '1' }
        // this.$http.post('user/examine', k, { emulateJSON: true })
        this.$message.closeAll()
        this.$message({
          type: 'info',
          message: '已取消操作'
        })
        return 0
      });
    },
    async Rsa(key) {
      var k = { tool_id: this.tool_id, username: this.username1, toolDir: 'App', Rsa: this.attribute.member[key].data.Rsa, filename: this.attribute.member[key].data.enTitle, dirname: this.$route.query.dir }
      const { data: res } = await this.$http.post('application/upload3', k)
      // console.log(res)
      if (res.code === 200) {
        this.$message.closeAll()
        this.$message({
          message: '使用成功',
          type: 'success'
        });
      } else {
        this.$message.closeAll()
        this.$message({
          message: '发生错误',
          type: 'warning'
        });
      }
    },
    Disable(key) {
      if (this.attribute.member[key].data.Disable === '1') {
        this.attribute.member[key].data.Disable = '0'
      } else {
        this.attribute.member[key].data.Disable = '1'
      }
      // console.log(this.attribute.member[key].data.Disable)
    },
    close() {
      // this.$router.push({ path: '/AppSquare', query: { username: this.username1 } })
      this.$router.push({ path: '/BioinforCloud' })
    },
    async DownloadInformation() {
      var k = { id: this.$route.query.dir, username: localStorage.getItem('username'), token: localStorage.getItem('token') }
      const { data: res } = await this.$http.post('/application/DownloadInformation', k)
      // console.log(res)
      if (res.msg === '该应用暂无应用说明') {
        this.$message.closeAll()
        this.$message({
          type: 'error',
          message: '该应用暂无应用说明。'
        });
      } else {
        // console.log(res)
        var url = this.Url + '/application/DownloadFileA/' + res.filename + '/' + res.filePath
        window.open(url)
      }
    },
    async downloadLogs(data){
      const { data: res } = await this.$http.post('application/downloadLogs', data)
      if (res.code === 200) {
        if (res.runLogs !== 'NULL') {
          var url1 = this.Url + '/application/DownloadFileA/' + res.runLogs + '/' + res.runLogsPath
          window.open(url1)
        }
        if (res.runTxt !== 'NULL') {
          var url2 = this.Url + '/application/DownloadFileA/' + res.runTxt + '/' + res.runTxtPath
          window.open(url2)
        }
      } else {
        this.$message({
          type: 'error',
          message: '暂未找到运行日志。'
        });
      }
    },
    async Recompress(data){
      this.queryTime = 1
      setTimeout(async() => { 
        this.queryTime = 0
      }, 120000)
      const { data: res } = await this.$http.post('application/Recompress', data)
      if (res.code == 200) {
        k = { url: '', id: this.$route.query.dir}
        Data.$emit('download_file', k)
        Data.$emit('tipeState', '正在运行')
        var k = { tool_id: this.tool_id, username: this.username1, Filedir: 'App', dir: this.$route.query.dir }
        this.Run2(k)
      }else if (res.code == 101) {
        this.$message({
          type: 'error',
          message: res.msg
        });
      } else {
        this.$message({
          type: 'error',
          message: '执行手动压缩失败。'
        });
      }
    },
    async uploadForDisk1(data, item) {
      this.FolderDisplay = 1
      var a = {filename: this.attribute.member[data].data.enTitle, dirname: this.$route.query.dir}
      setTimeout(async() => { 
        Data.$emit('Disk3', {'data': a, 'num': data, 'item': item})
      }, 500)
    },
    async uploadForDisk2(data) {
      var a = data
      // var k = { username: localStorage.getItem('username'), token: localStorage.getItem('token'), data: data['path'], 'fileName': data['name'], 'DiskPath': data['disk']}
      var k = { username: localStorage.getItem('username'), token: localStorage.getItem('token'), 'DiskPath': data['path'], 'forPathDirname': data['forPathInfo']['dirname'], 'forPathFilename': data['forPathInfo']['filename'], dir: data['dir']}
      this.$message.closeAll()
      this.$message({
        type: 'success',
        message: '正在从云盘拷贝，请稍后',
        duration: 0,
      });
      const { data: res } = await this.$http.post('application/copyForPersonalDisk', k)
      if (res.code === 200) {
        this.$message.closeAll()
        this.$message({
          type: 'success',
          message: '拷贝完成。',
          duration: 3000,
        });
        this.attribute.member[a['key']].data.displayText = '已上传：' + a.name
        this.FolderDisplay = 0
      } else {
        this.$message.closeAll()
        this.$message({
          type: 'error',
          message: '拷贝异常！',
          duration: 3000,
        });
      }
    }
  },
  props: {
  },
  components: {
    DataChild,
    PersonalDisk,
  },
}
</script>

<style lang="less" scoped>
#appRunMain {
  box-sizing: border-box;
  padding: 0rem 0 1rem 0;
  height: 95%;
  width: 97%;
  margin-left: 3%;
  /deep/ .el-icon-arrow-right{
    display: none;
  }
  #MainScrollbar {
    height: calc(100% - 3rem);
    width: 100%;
    border-radius: 0.3rem;
    .el-scrollbar{
      height: 100%;
      width: 100%;
    }
    .el-scrollbar__bar{
    right: 0;
    z-index: 10;
    }
    /deep/ .el-scrollbar__wrap{
      overflow-x:hidden;
      // overflow-y:hidden;
    }
    .el-scrollbar__bar.is-vertical {
      width: 12px;
      border-radius: 6px;
    }
    .el-scrollbar__thumb{
      background-color: rgba(0,0,0,0);
    }
    .el-scrollbar__thumb:hover {
      background-color: rgba(0,0,0,0);
    }
  }
  #mod2 {
    width: 13rem;
    height: 1rem;
    margin: auto auto;
    position: relative;
    padding-top: 1%;
    bottom: 0;
    /deep/ .el-button:focus, .el-button:hover{
      color: #666;
      border: 0.1rem solid rgba(222,222,222,0.2);
      background-color: #fff;
    }
    .el-button {
      color: #666;
      font-size: 0.5rem;
      height: 1rem;
      width: 2rem;
      margin-left: 1rem;
      border-radius: 0.75rem;
      padding: 0;
      border: 0.1rem solid rgba(222,222,222,0.2);
      box-shadow: 0.1rem 0.1rem 0.1rem rgba(0,0,0,0.25);
    }
  }
  #mod4{
    width: 98%;
    height: 2rem;
    font-size: 0.7rem;
    border-bottom: 0.025rem solid rgba(0,0,0,0.13);
    box-sizing: border-box;
    margin: 0.5rem 2% 0 0;
    /deep/ .el-input__inner {
      font-size: 0.75rem;
      color: #000;
      text-align: center;
      padding: 0;
      border: none;
    }
    /deep/ .el-input {
      width: 20%;
      height: 1.5rem;
      box-shadow: 0 0 0.1rem rgba(0,0,0,0.25);
      margin: 0 0.1rem;
    }
    .mod4_1 {
      text-align: center;
    }
    .mod4_2 {
      width: 5rem;
      position: relative;
      top: -3rem;
    }
    #mod4_3 {
      float: right;
      height: 0.5rem;
      .el-button {
        position: relative;
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 0.25rem;
        background-color: #FFCCCC;
        padding: 0;
        border: none;
        top: -0.5rem;
      }
    }
  }
  #mod3 {
    width: 100%;
    height: 100%;
    /deep/ .el-input__inner::placeholder, /deep/ .el-textarea__inner::placeholder {
      color: #ccc;
    }
    /deep/ .el-input__inner{
      height: 1.5rem;
      padding: 0;
      padding-left: 1rem;
      line-height: 1.5rem;
    }
    /deep/ .el-input__prefix {
      left: 0.3rem;
      line-height: 1.6rem;
    }
    /deep/ .el-textarea__inner {
      min-height: 3rem!important;
      padding: 0.1rem;
      border-radius: 0.1rem;
      box-sizing: border-box;
    }
    .el-radio-group, .el-checkbox-group {
      width: 100%;
      display: inline-block;
      line-height: 1;
      vertical-align: middle;
      font-size: 0;
    }
    /deep/ .el-radio, /deep/ .el-checkbox {
      box-sizing: border-box;
      float: left;
      width: 2.4rem;
      height: 0.6rem;
      line-height: 0.6rem;
      border-radius: 1rem;
      font-size: 0.4rem;
      margin: 0 0.5% 0.5rem 0.5%;
    }
    /deep/ .el-radio__label, /deep/ .el-checkbox__label {
      font-size: 0.45rem;
      padding-left: 0.1rem;
    }
    /deep/ .el-radio__inner, /deep/ .el-checkbox__inner {
      width: 0.5rem;
      height: 0.5rem;
    }
    /deep/ .el-checkbox-button__inner {
      padding: 0;
      color: #000;
      font-size: 0.6rem;
      height: 0.8rem;
      line-height: 0.7rem;
      width: auto;
      padding: 0 0.3rem;
      border-radius: 0.4rem;
      margin: 0 0.4rem 0.4rem 0;
      border: none;
    }
    /deep/ .el-checkbox-button.is-checked .el-checkbox-button__inner {
      background-color: rgba(64,158,255,0.5);
    }
    /deep/ .el-card__body {
      padding: 0.2rem 0 0 0;
    }
    #upButtop1, #upButtop2 {
      /deep/ .el-button {
        padding: 0;
        height: 1rem;
        // width: 2.5rem;
        border-radius: 0.5rem;
        font-size: 0.5rem;
      }
      .el-upload__tip {
        font-size: 0.5rem;
        color: #000;
      }
    }
    /deep/ .el-checkbox__input.is-disabled .el-checkbox__inner {
      width: 0;
      height: 0;
      background-color: rgba(0,0,0,0);
      border: none;
    }
    /deep/ .el-checkbox__input.is-disabled + span.el-checkbox__label {
      color: #000;
      cursor: auto;
    }
    #upButtop2 {
      .littleButton {
        width: auto;
        margin: 0 5%;
        height: 1rem;
        padding: 0;
      }
      /deep/ .el-button:focus, .el-button:hover {
        color: rgba(0,0,0,0.5);
        background-color: rgba(0,0,0,0);
        border: none;
      }
      .el-button {
        color: rgba(0,0,0,0.5);
        border: none;
      }
      .iconfont {
        font-size: 0.75rem;
      }
      /deep/ .el-card__body {
        padding: 0;
        height: 100%;
      }
      .upload-demo {
        // width: 7.3rem;
        width: 20%;
        height: 1rem;
        margin: auto;
        float: left;
        /deep/ .iconfont {
          font-size: 0.75rem;
          height: 1rem;
          line-height: 1rem;
          // margin: 0.5rem 0 0 0;
        }
      }
      .el-upload-list {
        width: 7.3rem;
        height: 1rem;
        margin: auto;
      }
      /deep/ .el-upload-list__item {
        font-size: 0.4rem;
      }
      /deep/ .el-upload-list__item:first-child {
        margin: 0;
        margin-top: 0.2rem;
      }
      /deep/ .el-upload {
        width: 100%;
        height: 100%;
        color: rgba(0, 0, 0, 0.5);
      }
      /deep/ .el-upload-dragger {
        width: 100%;
        height: 100%;
      }
      /deep/ .el-upload-dragger .el-icon-upload {
        font-size: 2rem;
        height: 2.5rem;
        line-height: 2.5rem;
        margin: 0.5rem 0 0 0;
      }
      /deep/ .el-upload-dragger .el-upload__text {
        height: 1.5rem;
        line-height: 1.5rem;
        font-size: 0.45rem;
        -webkit-text-size-adjust:none;
      }
    }
    #upload_Display {
      border-radius: 0.2rem;
      padding: 0.2rem;
      box-sizing: border-box;
      box-shadow: 0 0 0.2rem rgba(0,0,0,0.15) inset;
      line-height: 1rem;
      font-size: 0.7rem;
      .upload_Display2 {
        box-sizing: border-box;
        float: left;
        font-size: 0.7rem;
        width: 25%;
        line-height: 0.85rem;
        height: 0.85rem;
        text-align: center;
        border: 1px solid #000;
        border-left: none;
        border-bottom: none;
      }
      .upload_Display3 {
        width: auto;
        // height: calc(3.4rem + 4px);
        height: auto;
        border-left: 1px solid #000;
        border-bottom: 1px solid #000;
      }
      .upload_Display4 {
        height: 0.85rem;
      }
      /deep/ .el-textarea__inner {
        border: 0px solid #000;
        padding: 0;
      }
    }
    #displayBox {
      position: absolute;
      right: 0;
      background: #eee;
      z-index: 50;
      #mod3_dis {
        background-color: rgba(0,0,0,0);
        .el-button {
          background-color: rgba(0,0,0,0);
        }
      }
    }
    #seach_input {
      height: 1rem;
      line-height: 0.6rem;
      width: 100%;
      margin-bottom: 0.4rem;
      /deep/ .el-input {
        height: 1rem;
        line-height: 1rem;
        float: left;
        width: 80%;
        font-size: 0.45rem;
      }
      /deep/ .el-input__inner {
        height: 1rem;
        padding-left: 0.5rem;
      }
      .el-button {
        width: 15%;
        height: 1rem;
        margin: 0 2.5%;
        padding: 0;
        color: rgba(0, 0, 0, 0.5);
        border-radius: 0.5rem;
      }
      .el-button:focus, .el-button:hover {
        color: rgba(0, 0, 0, 0.5);
        border: 1px solid #DCDFE6;
        background: #FFF;
      }
      .el-button:hover {
        box-shadow: 0rem 0rem 0.1rem rgba(0,0,0,0.15);
      }
    }
    #seachLeft, #seachRight {
      width: 60%;
      height: 100%;
      float: left;
      // background-color: #000;
      font-size: 0.45rem;
    }
    #seachRight {
      min-height: 4rem;
      box-sizing: border-box;
      margin-left: 2%;
      // height: 5rem;
      padding: 0.1rem;
      padding-left: 0.1rem;
      width: 38%;
      line-height: 1rem;
      border: 1px solid #ccc;
      box-shadow: 0rem 0rem 0.1rem rgba(0,0,0,0.15) inset;
      #seachicon {
        .el-button {
          color: #000;
          background-color: #fff;
          box-sizing: border-box;
          border: 1px solid #ccc;
          box-shadow: 0rem 0rem 0.1rem rgba(0,0,0,0.15);
          margin: 0 0 0.1rem 0;
          padding: 0;
          width: 0.8rem;
          height: 0.8rem;
          border-radius: 0.4rem;
        }
        .el-button:focus, .el-button:hover {
          color: #000;
          background-color: #fff;
        }
      }
    }
    .mod3_1_1 {
      box-sizing: border-box;
      width: 100%;
      height: 1rem;
      padding: 0 10%;
      position: relative;
      background-color: #fff;
      z-index: 40;
      float: left;
      .el-button {
        color: rgba(0,0,0,0.5);
        border: none;
        width: 33.3%;
        // margin: 0 auto;
        padding: 0;
      }
      .el-button + .el-button {
        margin: 0;
      }
      .iconfont {
        font-size: 0.75rem;
      }
      /deep/ .el-card__body {
        padding: 0;
        height: 100%;
      }
      .littleButton1 {
        position: absolute;
        top: 0rem;
        left: -0.75rem;
        width: auto;
        margin: 0 2.5%;
        height: 1rem;
        padding: 0;
        // float: right;
      }
      .littleButton2 {
        position: absolute;
        top: 0rem;
        right: -0.5rem;
        width: auto;
        margin: 0 2.5%;
        height: 1rem;
        padding: 0;
        // float: right;
      }
      /deep/ .el-button:focus, .el-button:hover {
        color: rgba(0,0,0,0.5);
        background-color: rgba(0,0,0,0);
        border: none;
      }
    }
    .mod3_2 {
      position: relative;
      width: calc(100% + 2rem);
      height: 8rem;
      left: -1rem;
      top: -6.3rem;
      background-color: rgba(0, 0, 0, 0.1);
      z-index: 50;
      border-radius: 1rem;
    }
  }
  .singleLine{
    box-sizing: border-box;
    padding: 0 1rem;
    border-radius: 1rem;
    background-color: #fff;
    width: 43%;
    margin: 0.3rem 5% 0 2%;
    margin-bottom: 1rem;
    float: left;
  }
  #modLine {
    /deep/ .el-scrollbar__wrap {
      overflow-x: scroll;
    }
  }
  /deep/ .el-card.is-hover-shadow:focus, .el-card.is-hover-shadow:hover {
    box-shadow: 0 0 0.3rem 0 rgba(0,0,0,0.2);
  }
  .mod3_1 {
      box-sizing: border-box;
      width: 100%;
      height: 1rem;
      line-height: 1rem;
      padding: 0 10%;
      position: relative;
      background-color: #fff;
      z-index: 40;
      float: left;
      .el-button {
        color: rgba(0,0,0,0.5);
        border: none;
        width: 20%;
        // margin: 0 auto;
        padding: 0;
      }
      .el-button + .el-button {
        margin: 0;
      }
      .iconfont {
        font-size: 0.75rem;
      }
      /deep/ .el-card__body {
        padding: 0;
        height: 100%;
      }
      .littleButton {
        position: absolute;
        top: -6rem;
        left: -1rem;
        width: auto;
        margin: 0 2.5%;
        height: 1rem;
        padding: 0;
        float: right;
      }
      /deep/ .el-button:focus, .el-button:hover {
        color: rgba(0,0,0,0.5);
        background-color: rgba(0,0,0,0);
        border: none;
      }
  }
  .mod3_1 {
    // height: auto;
    position: relative;
    bottom: 0rem;
  }
  #mod5 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 99;
  }
}
</style>
